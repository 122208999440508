import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { result } from 'lodash';

import SongItemLayout from '../SongItemLayout';
import * as actions from '../../actions';
import { selectHost, selectBlacklist, selectPlaylist } from '../../selectors';
import { MODES } from '../../constants';
import { t } from '../../services/i18n';

const Track = ({ index, uri, name, artist, allowed, current, votes, paid, listened, instant }) => {
  const { updating: updatingBlacklist } = useSelector(selectBlacklist);
  const { deletingTrack, filterData } = useSelector(selectPlaylist);
  const { data } = useSelector(selectHost);
  const settings = result(data, 'host.settings', {});
  const disabled = updatingBlacklist === uri || deletingTrack === uri;

  const dispatch = useDispatch();
  const allowSong = useCallback(() => {
    dispatch(
      actions.allowSong({
        uri,
        status: !allowed,
      }),
    );
  }, [uri, allowed]);

  const deleteSong = useCallback(() => {
    dispatch(
      actions.deleteSong({
        uri,
      }),
    );
  }, [uri]);

  const getCollapsibleActions = () => [
    {
      title: t('action_delete_song'),
      icon: 'delete',
      onPress: deleteSong,
      color: 'primary',
    },
    {
      title: allowed ? t('action_forbid_song') : t('action_allow_song'),
      icon: allowed ? 'block' : 'done',
      onPress: allowSong,
      color: 'secondary',
    },
  ];

  const disabledByFilter = useMemo(
    () =>
      filterData && filterData.currentMatchIndex > -1 && !filterData.matchIndexes.includes(index),
    [index, filterData],
  );

  return (
    <SongItemLayout
      disabled={!!disabledByFilter || !!disabled}
      listened={listened}
      highlighted={current}
      number={index + 1}
      name={name}
      artist={artist}
      includeVotes={(settings.mode === MODES.PLAYLIST || instant) && !current}
      votes={votes}
      paid={paid}
      uri={uri}
      actions={getCollapsibleActions()}
    />
  );
};

export default Track;
