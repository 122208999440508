import 'react-native-gesture-handler';
import NetInfo from '@react-native-community/netinfo';
import React, { useMemo, useEffect, useState } from 'react';
import { Linking, LogBox, Platform } from 'react-native';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { Provider as PaperProvider, DefaultTheme } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import {
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
} from '@expo-google-fonts/poppins';
import { Roboto_500Medium } from '@expo-google-fonts/roboto';

import Header from './components/Header';
import Home from './screens/Home';
import HostHome from './screens/host/Home';
import StreamLogIn from './screens/host/StreamLogIn';
import SelectPlan from './screens/chechout/SelectPlan';
import Login from './screens/Login';
import CurrentHost from './screens/guest/CurrentHost';
import Scanner from './screens/guest/Scanner';
import Loading from './components/Loading';
import Modals from './components/Modals';
import NetworkStatus from './components/NetworkStatus';
import ServerConfigProvider from './components/ServerConfigProvider';
import Credit from './screens/guest/Credit';
import Account from './screens/guest/Account';
import CheckCredit from './screens/guest/CheckCredit';
import UseCredit from './screens/guest/UseCredit';
import PlaylistsImport from './screens/host/PlaylistsImport';
import SearchTracks from './screens/SearchTracks';
import Blacklist from './screens/host/Blacklist';
import config from './config';
import * as analytics from './services/analytics';
import { navigationRef } from './services/navigation';
import { store } from './store';
import { Stack } from './navigation';
import { selectHost, selectUserAuthorization } from './selectors';
import { getClosestHosts, setNetwork } from './actions';
import { handleInitialDeepLink } from './services/linking';
import { waitUntil } from './services/helpers';
import { initStorage } from './services/storage';
import { t } from './services/i18n';
import { usePrefetchImages } from './hooks/usePrefetchImages';
import NotificationWebView from './components/NotificationWebView';

import { stopReportingRuntimeErrors } from 'react-error-overlay';

if (process.env.NODE_ENV === "development" && Platform.OS === 'web') {
  stopReportingRuntimeErrors();
}

if (LogBox) {
  LogBox.ignoreAllLogs(true);
}

console.log('Config', config);
console.log('Env', process.env);

const screens = [
  /* General */
  {
    name: 'Home',
    component: Home,
  },
  /* Guest */
  {
    name: 'CurrentHost',
    component: CurrentHost,
  },
  {
    name: 'Scanner',
    component: Scanner,
  },
  {
    name: 'Account',
    component: Account,
  },
  {
    name: 'Credit',
    component: Credit,
  },
  {
    name: 'CheckCredit',
    component: CheckCredit,
  },
  {
    name: 'UseCredit',
    component: UseCredit,
  },
  /* Host */
  {
    name: 'HostHome',
    component: HostHome,
  },
  {
    name: 'StreamLogIn',
    component: StreamLogIn,
  },
  {
    name: 'PlaylistsImport',
    component: PlaylistsImport,
  },
  {
    name: 'SearchTracks',
    component: SearchTracks,
  },
  {
    name: 'Blacklist',
    component: Blacklist,
  },
  /* Checkout */
  {
    name: 'SelectPlan',
    component: SelectPlan,
  },
];

const Icon = props => <MaterialIcons {...props} />;

const App = () => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const { loading: loadingImages } = usePrefetchImages();
  const { loading: loadingHost } = useSelector(selectHost);
  const userAuthorization = useSelector(selectUserAuthorization);
  const [fontsLoaded, fontsError] = useFonts({
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
    Roboto: Roboto_500Medium,
  });

  if (fontsError) {
    console.log('fontsLoaded error:', fontsError);
  }

  useEffect(() => {
    // get hosts, needed here?
    dispatch(getClosestHosts());

    // analytics
    analytics.appOpened();

    // storage
    initStorage(dispatch).then(() => {
      setInitialized(true);
    });

    // deep link
    const onUrlChange = ({ url }) => {
      handleInitialDeepLink(navigationRef.current, url);
    };
    waitUntil(
      () => {
        handleInitialDeepLink(navigationRef.current);
      },
      () => navigationRef.current,
    );

    // network
    const unsubscribeNetwork = NetInfo.addEventListener(state => {
      dispatch(
        setNetwork({
          type: state.type,
          connected: state.isConnected,
        }),
      );
    });

    Linking.addEventListener('url', onUrlChange);

    return () => {
      Linking.removeEventListener('url', onUrlChange);
      unsubscribeNetwork();
    };
  }, []);

  if (!initialized || loadingHost || loadingImages || !fontsLoaded) {
    return <Loading />;
  }

  return (
    <>
      {Platform.OS === 'web' && (
        <style dangerouslySetInnerHTML={{
          __html: `
            svg {
              height: 100%;
              max-width: 100%;
            }
            [data-testid="modal"] {
              position: fixed !important;
            }
            video[playsinline=true][muted=true] {
              width: 100%;
              height: 100%;
            }
            div[style*=background][style*=rgb\\(242] {
              max-height: 100%;
            }
          `
        }} />
      )}
      <Modals />
      <NetworkStatus />
      <NotificationWebView />
      <NavigationContainer ref={navigationRef}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
          }}
        >
          {!userAuthorization ? (
            <Stack.Screen name="Login" component={Login} />
          ) : (
            screens.map(({ name, component: Component }) => (
              <Stack.Screen
                key={name}
                name={name}
                component={Component}
                options={{ title: t('screen_title_default') }}
              />
            ))
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
};

export default () => {
  const settings = useMemo(() => ({ icon: Icon }));

  return (
    <Provider store={store}>
      <PaperProvider settings={settings} theme={DefaultTheme}>
        <ServerConfigProvider>
          <Header />
          <App />
        </ServerConfigProvider>
      </PaperProvider>
    </Provider>
  );
};
