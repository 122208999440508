import React from 'react';
import { result } from 'lodash';
import { useSelector } from 'react-redux';
import { Platform, StyleSheet } from 'react-native';
import moment from 'moment';

import ContentWrapper from '../../components/ContentWrapper';
import RefreshView from '../../components/RefreshView';
import NoSubscription from '../../components/host/NoSubscription';
import ActiveSubscription from '../../components/host/ActiveSubscription';
import CanceledSubscription from '../../components/host/CanceledSubscription';
import SkeletonWrapper from '../../components/skeleton/SkeletonWrapper';
import skeleton from '../../helpers/skeleton';
import theme from '../../theme';
import * as analytics from '../../services/analytics';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import { selectHost } from '../../selectors';
import { useSubscription } from '../../hooks/useSubscription';
import WebSubscription from '../../components/host/WebSubscription';

const Subscription = () => {
  const {
    data: { host },
    loading,
  } = useSelector(selectHost);
  const { subscription, loading: loadingSubscription, reload } = useSubscription();
  console.log('aaaa', JSON.stringify(subscription));
  
  const isActive = result(subscription, 'entitlements.active.premium.isActive', false);
  const expiredString = result(subscription, 'entitlements.active.premium.expirationDate', null);
  const isExpired = expiredString ? moment(expiredString).isBefore(moment()) : false;
  const name = result(host, 'settings.name', result(host, 'name', ''));

  useScreenFocus(() => {
    analytics.tab('subscription');
  });

  const getSubscriptionContent = () => {
    if (Platform.OS === 'web') {
      return <WebSubscription />;
    }
    if (loading || loadingSubscription) {
      return <SkeletonWrapper {...skeleton.subscription} repeat={1} />;
    }

    if (isActive) {
      if (isExpired) {
        return (
          <CanceledSubscription
            reload={reload}
            subscription={subscription}
          />
        );
      }
      return (
        <ActiveSubscription
          reload={reload}
          subscription={subscription}
        />
      );
    }

    return (
      <NoSubscription
        reload={reload}
        name={name}
      />
    );
  };

  return (
    <ContentWrapper rootStyle={styles.container}>
      <RefreshView
        refreshing={loading || loadingSubscription}
        onRefresh={reload}
        backgroundColor={theme.black.bg1}
      >
        {getSubscriptionContent()}
      </RefreshView>
    </ContentWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: theme.spacing(1),
    justifyContent: 'center',
    backgroundColor: theme.black.bg1,
  },
});

export default Subscription;
