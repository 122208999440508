import React from 'react';
import { useDispatch } from 'react-redux';

import Audio from '../../Audio';
import SongItemLayout from '../../SongItemLayout';
import { sendVote, askToUseCredit } from '../../../actions';
import { t } from '../../../services/i18n';

const Option = ({
  hostId,
  position,
  uri,
  name,
  artist,
  votes,
  disabled,
  highlighted,
  elected,
  listened,
  paid,
  preview,
  handlePaidPress,
}) => {
  const dispatch = useDispatch();
  
  const handleVote = () => {
    if (paid) {
      handlePaidPress(paid);
    } else {
      dispatch(sendVote({ hostId, uri }));
    }
  };

  const handlePaid = () => {
    if (paid) {
      handlePaidPress(paid);
    } else {
      dispatch(askToUseCredit({ hostId, uri }));
    }
  };

  const getCollapsibleActions = () => {
    if (!listened) {
      return [
        {
          title: t('action_vote'),
          onPress: handleVote,
          icon: { icon: 'thumb-up' },
          color: 'primary',
        },
        {
          title: t('action_play_now'),
          color: 'tertiary',
          icon: { icon: 'coin', external: true },
          onPress: handlePaid,
        },
      ];
    }
  };

  const getContent = () => (
    <SongItemLayout
      listened={listened}
      highlighted={highlighted}
      elected={elected || paid}
      number={position}
      name={name}
      artist={artist}
      includeVotes={!highlighted}
      votes={votes}
      uri={uri}
      disabled={!!disabled}
      paid={paid}
      preview={preview}
      actions={getCollapsibleActions()}
      actionExtra={<Audio uri={preview} />}
    />
  );

  return getContent();
};

export default Option;
