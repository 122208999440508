import React from 'react';
import { useDispatch } from 'react-redux';

import OnboardingLayout from '../../../components/OnboardingLayout';
import { t } from '../../../services/i18n';
import { setOnboarded } from '../../../actions';

const Screen2 = () => {
  const dispatch = useDispatch();

  return (
    <OnboardingLayout
      source={require('../../../assets/hostonboarding3.png')}
      text={t('text_host_onboarding_3')}
      step={2}
      steps={3}
      buttonText={t('action_host_onboarding_3')}
      onNext={() => {
        dispatch(
          setOnboarded({
            host: true,
          }),
        );
      }}
    />
  );
};

export default Screen2;
