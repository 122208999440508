import Constants from 'expo-constants';
import parse from 'url-parse';
import { getInitialURL, makeUrl } from 'expo-linking';
import { Platform } from 'react-native';
import { store } from '../store';
import { joinHost } from '../actions';

const patterns = [
  { url: 'app/join/:id', dispatch: { action: joinHost, params: { from: 'deeplink' } }},
  { url: 'join/:id', dispatch: { action: joinHost, params: { from: 'deeplink' } } }
  // { url: 'join/:id', screens: ['Home', 'CurrentHost'] }
];

export const getUrlPrefixes = () => [
  `${Constants.manifest.scheme}://`,
  makeUrl('/'),
  `${makeUrl()}/`,
  'http://expo.loudy.app/',
  'https://expo.loudy.app/',
  'http://loudy.app/',
  'https://loudy.app/',
  'https://loudy.app.link/',
];

export const isValidDeepLink = url =>
  getUrlPrefixes().reduce((acc, prefix) => acc || url.startsWith(prefix), false);

export const parseUrl = url => {
  let result = url || '';
  for (let prefix of getUrlPrefixes()) {
    if (result.startsWith(prefix)) {
      result = result.substr(prefix.length);
    }
  }
  const withoutPrefix = result.replace('/--', '');
  const parsed = parse(withoutPrefix, true);
  return {
    path: parsed.pathname[0] === '/' ? parsed.pathname.substr(1) : parsed.pathname,
    query: parsed.query,
  };
};

export const parseDeepLink = url => {
  const { path: urlPath, query } = parseUrl(url.replace('://open', ''));
  const urlParts = urlPath.split('/');
  for (let pattern of patterns) {
    const pattenrParts = pattern.url.split('/');
    let valid = false;
    const result = {
      screens: pattern.screens,
      dispatch: pattern.dispatch,
      query,
    };
    if (urlParts.length === pattenrParts.length) {
      valid = true;
      for (let i = 0; i < urlParts.length; i++) {
        if (urlParts[i] === pattenrParts[i]) {
          continue;
        }
        if (pattenrParts[i].startsWith(':')) {
          result[pattenrParts[i].substr(1)] = urlParts[i];
          continue;
        }
        valid = false;
        break;
      }
    }
    if (valid) {
      return result;
    }
  }
  return null;
};

export const handleInitialDeepLink = async (navigation, newUrl) => {
  const url = newUrl || (await getInitialURL());
  // const url = 'loudy://app/join/franco@loudy.app://open';

  if (Platform.OS === 'web') {
    const { query } = parseUrl(url);
    if (query.payment_id) {
      //@todo: find a better way to do this, maybe with react navigation ?
      window.history.pushState({}, document.title, '/');
      navigation.navigate('CheckCredit', { paymentId: query.payment_id });
    }
  }

  const parsedUrl = parseDeepLink(url);
  if (parsedUrl) {
    const { screens, dispatch, ...other } = parsedUrl;
    console.log('aaaa deeplink id', other);
    if (dispatch) {
      const actionToDispatch = dispatch.action({
        ...dispatch.params,
        ...other,
      });
      console.log('aaaa dispatching deeplink action', actionToDispatch);
      store.dispatch(actionToDispatch);
    }
    if (screens) {
      for (let screenName of screens) {
        console.log('aaaa navigating from deeplink with params', screenName, other);
        navigation.navigate(screenName, other);
      }
    }
  }
};
