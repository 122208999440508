import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, Image, TouchableOpacity, View } from 'react-native';
import { size } from 'lodash';

import Loading from '../Loading';
import Text from '../Text';
import theme from '../../theme';
import { setPlaylist } from '../../actions';
import { selectPlaylist } from '../../selectors';
import { t } from '../../services/i18n';
import { openModal } from '../../services/modal';

const Playlist = ({ id, name, image, countTracks, recommendation, smallPlaylist }) => {
  const { loadingPlaylist, tracklist } = useSelector(selectPlaylist);
  const dispatch = useDispatch();

  const validate = validation => {
    switch (validation) {
      case 'currentPlaylist':
        if (size(tracklist)) {
          openModal('clearPlaylistWarning', {
            title: t('status_warning'),
            content: t('text_clear_playlist'),
            buttons: [
              {
                text: t('action_ok'),
                onPress: ({ close }) => {
                  validate('smallPlaylist');
                  close();
                },
              },
              {
                text: t('action_cancel'),
                onPress: ({ close }) => close(),
                variant: 'secondary',
              },
            ],
          });
        } else {
          validate('smallPlaylist');
        }
        break;
      case 'smallPlaylist':
        if (smallPlaylist) {
          openModal('importSmallPlaylistWarning', {
            title: t('status_warning'),
            content:
              countTracks === 1
                ? t('text_import_small_playlist_singular', {
                    name,
                    recommendation,
                  })
                : t('text_import_small_playlist_plural', {
                    name,
                    number: countTracks,
                    recommendation,
                  }),
            buttons: [
              {
                text: t('action_import'),
                onPress: ({ close }) => {
                  validate();
                  close();
                },
              },
              {
                text: t('action_cancel'),
                onPress: ({ close }) => close(),
                variant: 'secondary',
              },
            ],
          });
        } else {
          validate();
        }
        break;
      default:
        dispatch(setPlaylist({ id, name }));
        break;
    }
  };

  const loading = loadingPlaylist === id;

  return (
    <TouchableOpacity onPress={() => validate('currentPlaylist')} disabled={!!loadingPlaylist}>
      <View style={[styles.root, loadingPlaylist && styles.disabled]}>
        {image && <Image source={{ uri: image.url }} style={styles.image} />}
        <View style={styles.text}>
          <Text style={styles.name}>{name}</Text>
          {countTracks > 0 && (
            <Text>
              {countTracks === 1
                ? t('text_count_tracks_singular')
                : t('text_count_tracks_plural', {
                    number: countTracks,
                  })}
            </Text>
          )}
        </View>

        {loading && (
          <View style={styles.loadingContainer}>
            <Loading style={styles.loading} size="small" />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    backgroundColor: theme.color.blackLight,
    marginBottom: 10,
    borderRadius: 10,
  },
  disabled: {
    opacity: 0.5,
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 5,
  },
  text: {
    marginLeft: 10,
    flex: 1,
  },
  name: {
    fontSize: 18,
    flex: 1,
    textAlignVertical: 'center',
  },
  loadingContainer: {
    marginLeft: 'auto',
  },
  loading: {
    backgroundColor: 'transparent',
  },
});

export default Playlist;
