import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, FlatList, StyleSheet } from 'react-native';
import { result, size } from 'lodash';

import Loading from '../Loading';
import SearchLibraryTrackItem from '../SearchLibraryTrackItem';
import { selectAddingSongStatus, selectSearchLibrary } from '../../selectors';
import { addSong, search, clean } from '../../actions';
import { t } from '../../services/i18n';
import { isCloseToBottom } from '../../helpers/scrollView';

const SearchLibraryTracks = ({ hostId }) => {
  const dispatch = useDispatch();
  const addingSong = useSelector(selectAddingSongStatus);
  const { results, loading, newSearch, limit, next, offset, lastQuery } = useSelector(
    selectSearchLibrary('tracks'),
  );

  useEffect(() => {
    return () => dispatch(clean.tracks());
  }, []);

  const handleAddTrack = uri => {
    dispatch(addSong({ uri, hostId }));
  };

  const handleOnScroll = nativeEvent => {
    if (isCloseToBottom(nativeEvent) && next) {
      const from = offset + limit;
      dispatch(
        search.tracks({
          query: lastQuery,
          hostId,
          from,
        }),
      );
    }
  };

  const getActions = data => {
    const available = result(data, 'item.available');
    if (available) {
      const uri = result(data, 'item.uri');
      return [
        {
          title: t('action_add_and_vote'),
          color: 'primary',
          icon: { icon: 'add-song', external: true },
          onPress: () => handleAddTrack(uri),
          disabled: addingSong,
        },
      ];
    }
  };

  return (
    <>
      <View style={styles.root}>
        {loading && newSearch ? (
          <Loading style={styles.loading} />
        ) : (
          size(results) > 0 && (
            <FlatList
              onScroll={({ nativeEvent }) => handleOnScroll(nativeEvent)}
              data={results}
              renderItem={item => <SearchLibraryTrackItem {...item} actions={getActions(item)} />}
              keyExtractor={item => item.uri}
            />
          )
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  loading: {
    marginTop: 30,
  },
});

export default SearchLibraryTracks;
