import * as constants from '../constants';

const initialState = {
  loadingCheckout: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_MP_LOADING_CHECKOUT: {
      return {
        loadingCheckout: payload,
      };
    }
    default:
      return state;
  }
};
