import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Linking, View, StyleSheet, ImageBackground } from 'react-native';
import { ProgressBar } from 'react-native-paper';

import ContentWrapper from './ContentWrapper';
import IconButton from './IconButton';
import Text from './Text';
import theme from '../theme';
import { useProgress } from '../hooks/progress';
import { t } from '../services/i18n';

const Player = ({
  durationMs,
  progressMs,
  name,
  image,
  artist,
  isPlaying,
  volume,
  onPlayPause,
  onVolume,
  onNext,
  currentDevice,
  isPremium,
  loading,
}) => {
  const [progress, duration] = useProgress(isPlaying, progressMs, durationMs);
  const source = image ? { uri: image } : null;

  const isHost = Boolean(onPlayPause);

  const handleOpenSpotify = () => {
    Linking.openURL('spotify:').catch(e =>
      Linking.openURL('market://details?id=com.spotify.music'),
    );
  };

  const handleAction = callback => {
    if (isPremium && currentDevice) {
      callback();
    } else {
      handleOpenSpotify();
    }
  };

  const renderHostSection = () => {
    if (!isPremium) {
      return (
        <TouchableOpacity onPress={handleOpenSpotify}>
          <Text style={styles.text} numberOfLines={1}>
            {t('text_no_premium_account')}
          </Text>
          <Text style={styles.text} numberOfLines={1}>
            {t('text_no_premium_account_instructions')}
          </Text>
        </TouchableOpacity>
      );
    }
    if (!currentDevice) {
      return (
        <TouchableOpacity onPress={handleOpenSpotify}>
          <Text style={styles.text} numberOfLines={1}>
            {t('text_no_song_playing')}
          </Text>
          <Text style={styles.text} numberOfLines={1}>
            {t('text_no_song_playing_instructions')}
          </Text>
        </TouchableOpacity>
      );
    }
    return renderTrackSection();
  };

  const renderTrackSection = () => {
    if (name || artist) {
      return (
        <>
          <Text style={[styles.text, styles.name]} numberOfLines={1}>
            {name}
          </Text>
          <Text style={styles.text} numberOfLines={1}>
            {artist}
          </Text>
        </>
      );
    }
    return null;
  };

  let progressValue = duration ? progress / duration : 0;
  if (Number.isNaN(progressValue)) {
    progressValue = 0;
  }

  return (
    <ImageBackground style={styles.root} source={source} imageStyle={styles.playerOpacity}>
      <ContentWrapper style={styles.content}>
        <View style={styles.contentLeft}>
          {isHost ? renderHostSection() : renderTrackSection()}
          {Boolean(duration && name) && (
            <ProgressBar
              style={styles.progressBar}
              animatedValue={progressValue}
              color={theme.color.red}
            />
          )}
        </View>
        <View style={styles.contentRight}>
          {onVolume && (
            <IconButton
              color="white"
              size={26}
              icon={volume !== 0 ? 'volume-up' : 'volume-mute'}
              onPress={() => handleAction(onVolume)}
              disabled={!!loading}
            />
          )}
          {onNext && (
            <IconButton
              color="white"
              size={28}
              icon="skip-next"
              onPress={() => handleAction(onNext)}
              disabled={!!loading}
            />
          )}
          {onPlayPause && (
            <IconButton
              color="white"
              size={30}
              icon={isPlaying ? 'pause-circle-filled' : 'play-circle-fill'}
              onPress={() => handleAction(onPlayPause)}
              disabled={!!loading}
            />
          )}
        </View>
      </ContentWrapper>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: 10,
    minHeight: 72,
    backgroundColor: '#242424',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
  contentLeft: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentRight: {
    flexDirection: 'row',
    alignItems: 'center',
    transform: [{ translateX: 14 }],
  },
  text: {
    color: '#FFF',
  },
  name: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  progressBar: {
    marginTop: 5,
  },
  playerOpacity: {
    opacity: 0.3,
  },
});

export default Player;
