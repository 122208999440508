import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Platform, StyleSheet } from 'react-native';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { result } from 'lodash';

import Guests from './Guests';
import Icon from '../../components/Icon';
import Layout from '../../components/Layout';
import Playlist from './Playlist';
import Settings from './Settings';
import Subscription from './Subscription';
import theme from '../../theme';
import useScreenLogger from '../../hooks/useScreenLogger';
import { saveLocation } from '../../actions';
import { t } from '../../services/i18n';
import { selectHost } from '../../selectors';

const Tab = createMaterialBottomTabNavigator();

const Home = () => {
  const dispatch = useDispatch();
  useScreenLogger('HOST HOME');
  const { data: currentHost } = useSelector(selectHost);

  useEffect(() => {
    dispatch(saveLocation());
  }, []);

  if (!currentHost) {
    return null;
  }

  const name = result(currentHost, 'host.settings.name', result(currentHost, 'host.name', ''));

  const findIconColor = (color, focused) => {
    if (focused) {
      return 'black';
    }
    if (color === theme.color.pastel) {
      return 'pastel';
    }
    return 'white';
  };

  return (
    <Layout title={name} includeHostMode>
      <Tab.Navigator
        barStyle={styles.bar}
        backBehavior="initialRoute"
        labeled
        secon
        screenOptions={{
          tabBarActiveBackgroundColor: 'black',
        }}
        shifting={false}
        activeColor="white"
        inactiveColor={theme.color.pastel}
        compact={Platform.OS === 'web'}
      >
        <Tab.Screen
          name="Playlist"
          component={Playlist}  
          options={{
            tabBarActiveBackgroundColor: 'black',
            title: t('screen_title_default'),
            tabBarLabel: t('screen_title_playlist'),
            tabBarColor: 'black',
            tabBarIcon: ({ color, focused, ...props }) => (
              <Icon
                icon="playlist"
                size={20}
                color={findIconColor(color, focused)}
                external
                {...props}
              />
            ),
          }}
        />
        <Tab.Screen
          name="Guests"
          component={Guests}
          options={{
            title: t('screen_title_default'),
            tabBarLabel: t('screen_title_guests'),
            tabBarIcon: ({ color, focused, ...props }) => (
              <Icon
                icon="people"
                size={25}
                color={findIconColor(color, focused)}
                style={styles.tabBarIcon}
                {...props}
              />
            ),
          }}
        />
        <Tab.Screen
          name="Settings"
          component={Settings}
          options={{
            title: t('screen_title_default'),
            tabBarLabel: t('screen_title_settings'),
            tabBarIcon: ({ color, focused, ...props }) => (
              <Icon
                icon="settings"
                size={25}
                color={findIconColor(color, focused)}
                style={styles.tabBarIcon}
                {...props}
              />
            ),
          }}
        />
        <Tab.Screen
          name="Subscription"
          component={Subscription}
          options={{
            title: t('screen_title_default'),
            tabBarLabel: t('screen_title_subscription'),
            tabBarIcon: ({ color, focused, ...props }) => (
              <Icon
                icon="credit-card"
                size={25}
                color={findIconColor(color, focused)}
                style={styles.tabBarIcon}
                {...props}
              />
            ),
            tabBarColor: 'white',
          }}
        />
      </Tab.Navigator>
    </Layout>
  );
};

const styles = StyleSheet.create({
  bar: {
    backgroundColor: theme.color.red,
  },
  tabBarIcon: {
    marginTop: -2,
  },
});

export default Home;
