import React from 'react';
import { StyleSheet } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import Screen0 from './Screen0';
import Screen1 from './Screen1';
import Screen2 from './Screen2';
import * as analytics from '../../../services/analytics';
import { useScreenFocus } from '../../../hooks/useScreenFocus';

const Tab = createMaterialTopTabNavigator();

const GuestOnboarding = () => {
  useScreenFocus(() => {
    analytics.screen('guestOnboarding');
  });

  return (
    <Tab.Navigator backBehavior="order" tabBarOptions={{ style: styles.bar }}>
      <Tab.Screen name="Screen0" component={Screen0} />
      <Tab.Screen name="Screen1" component={Screen1} />
      <Tab.Screen name="Screen2" component={Screen2} />
    </Tab.Navigator>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  bar: {
    display: 'none',
  },
});

export default GuestOnboarding;
