import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';

import Spinner from '../Spinner';
import { IconButton } from 'react-native-paper';

const RefreshControl = ({ onRefresh, refreshing, style }) => {
  return (
    <View style={[styles.root, style]}>
      {refreshing ? (
        <Spinner size={15} color="white" style={styles.spinner} />
      ) : (
        <IconButton
          color="white"
          size={18}
          icon="refresh"
          onPress={onRefresh}
          style={styles.icon}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 30,
    width: '100%',
  },
  spinner: {
    marginRight: 5,
  },
  icon: {
    margin: 0,
  },
});

export default RefreshControl;
