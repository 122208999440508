import { result, size } from 'lodash';

import { t } from '../services/i18n';

export const mapHttpError = error => {
  const status = result(error, 'response.status');
  if (status === 401) {
    return null;
  }
  const key = result(error, 'response.data.message', 'error_message_default');
  return t(key);
};

export const getHttpResponse = {
  action: error => getHttpResponseData(error).action,
  message: error => getHttpResponseData(error).message,
  data: (error, nodes) =>
    result(getHttpResponseData(error), `data${size(nodes) ? '.' + nodes.join('.') : ''}`),
};

export const getHttpResponseData = error => result(error, 'response.data', {});

export const base64toFile = (base64, filename) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const formatSongTime = time => {
  return `${Math.floor(time / 60)}:${String(time % 60).padStart(2, '0')}`;
};
