import api from './api';

export default class {
  static getPlaylists() {
    return api.get('/host/playlist');
  }

  static importPlaylist(payload) {
    return api.post('/host/playlist', payload);
  }

  static getTrackList() {
    return api.get('/host/playlist/tracks');
  }

  static cleanTrackList() {
    return api.delete('/host/playlist/tracks');
  }

  static addSongs(uris) {
    return api.post(`/host/playlist/tracks`, {
      uris,
    });
  }

  static deleteSong(uri) {
    return api.delete(`/host/playlist/tracks/${uri}`);
  }
}
