import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { View, StyleSheet } from 'react-native';

import TextInput from './TextInput';
import IconButton from './IconButton';
import theme from '../theme';
import { t } from '../services/i18n';

const FilterBox = ({ onFilter }) => {
  const [text, setText] = useState('');
  const inputRef = useRef();

  const debounced = useMemo(() => {
    const handleOnChange = value => {
      const words = value
        .toLowerCase()
        .trim()
        .split(' ')
        .filter(w => w);

      onFilter(words);
    };
    return debounce(handleOnChange, 200);
  }, []);

  const onChange = useCallback(value => {
    setText(value);
  });

  useEffect(() => {
    debounced(text);
  }, [text]);

  return (
    <View style={styles.root}>
      <TextInput
        placeholder={t('field_filter_songs')}
        placeholderTextColor={theme.color.grayLight}
        onChange={onChange}
        value={text}
        ref={inputRef}
        suffix={
          <IconButton
            style={styles.icon}
            icon={text.length ? 'close' : 'search'}
            color={theme.color.grayLight}
            onPress={() => {
              text.length ? setText('') : inputRef.current?.focus();
            }}
          />
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: 55,
    marginVertical: 10,
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

export default FilterBox;
