import * as constants from '../constants';

const initialState = {
  loading: true,
  loadingCheckout: null,
  products: [],
  subscriptions: [],
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_IAP_PRODUCTS: {
      return {
        ...state,
        loading: false,
        products: payload,
      };
    }
    case constants.SET_IAP_SUBSCRIPTIONS: {
      return {
        ...state,
        loading: false,
        subscriptions: payload,
      };
    }
    case constants.SET_IAP_LOADING_CHECKOUT: {
      return {
        ...state,
        loadingCheckout: payload,
      };
    }
    case constants.BUY_CREDIT_REQUEST:
    case constants.BUY_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        loadingCheckout: payload,
      };
    }
    case constants.BUY_CREDIT_SUCCESS:
    case constants.BUY_CREDIT_FAILURE:
    case constants.BUY_SUBSCRIPTION_SUCCESS: 
    case constants.BUY_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        loadingCheckout: false,
      };
    }
    default:
      return state;
  }
};
