import * as constants from '../constants';

const initialState = {
  loading: false,
  data: null,
  error: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.API_SERVER_CONFIG_REQUEST: {
      return {
        loading: true,
        data: null,
        error: false,
      };
    }
    case constants.API_SERVER_CONFIG_SUCCESS: {
      return {
        loading: false,
        data: payload,
        error: false,
      };
    }
    case constants.API_SERVER_CONFIG_FAILURE: {
      return {
        loading: false,
        data: null,
        error: true,
      };
    }
    default:
      return state;
  }
};
