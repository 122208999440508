import {
  call,
  takeLatest,
  takeEvery,
  put,
  fork,
  all,
  take,
  takeLeading,
  select,
} from 'redux-saga/effects';
import { result } from 'lodash';

import handleMiddleware from '../middleware';
import GuestService from '../../services/guest';
import * as constants from '../../constants';
import { success, error } from '../../helpers/toast';
import { t } from '../../services/i18n';
import { getNavigation } from '../../services/navigation';
import { getHttpResponse, mapHttpError } from '../../helpers/format';
import { getHostStatus, updateHostData } from '../../actions';
import { checkNextSongWorker } from '../track';
import { selectVotingStatus } from '../../selectors';

function* getHostStatusWorker({ payload }) {
  try {
    const { hostId, firstTime, action } = payload;
    console.log('Getting host status', firstTime, hostId);
    yield put({ type: constants.API_GET_HOST_STATUS_REQUEST, payload: firstTime });
    const { data } = yield call(GuestService.getHostStatus, hostId);

    console.log('aaaaa1', 'data.currentTrack', data.currentTrack);
    yield fork(checkNextSongWorker, 'guest', data, function* () {
      yield fork(getHostStatusWorker, { payload: { hostId } });
    });

    yield put({ type: constants.API_GET_CURRENT_TRACK_SUCCESS, payload: data.currentTrack });
    yield put({ type: constants.API_GET_HOST_STATUS_SUCCESS, payload: data });

    if (action) {
      yield put({ type: action });
    }
  } catch (error) {
    console.log('aaaaa2', 'error', error);
    yield put({ type: constants.API_GET_CURRENT_TRACK_FAILURE, payload: error });
    yield put({ type: constants.API_GET_HOST_STATUS_FAILURE, payload: error });
  }
}

function* sendVoteWorker({ payload }) {
  try {
    yield put({ type: constants.API_SEND_VOTE_REQUEST });
    const { data } = yield call(GuestService.sendVote, payload);
    yield put({ type: constants.API_SEND_VOTE_SUCCESS, payload: data });
  } catch (e) {
    if (result(e, 'response.status') === 400) {
      const message = result(e, 'response.data.message', '');
      if (message) {
        error(t(message));
      }
    }
    yield put({
      type: constants.API_SEND_VOTE_FAILURE,
      payload: mapHttpError(e),
    });
  }
}

function* addSongWorker({ payload }) {
  try {
    yield put({ type: constants.API_ADD_SONG_REQUEST });
    const { data } = yield call(GuestService.addSong, payload);
    if (data.message) {
      success(t(data.message));
    }
    yield put({ type: constants.API_ADD_SONG_SUCCESS, payload });
    const { hostId } = payload;
    yield put(getHostStatus({ hostId }));
    getNavigation().navigate('CurrentHost');
  } catch (error) {
    yield put({
      type: constants.API_ADD_SONG_FAILURE,
      payload: mapHttpError(error),
    });
    const action = getHttpResponse.action(error);
    const hostData = getHttpResponse.data(error, ['host']);
    if (hostData) {
      yield put(updateHostData(hostData));
    }

    if (action === 'go_back') {
      yield put({ type: constants.API_GET_HOST_STATUS });
      getNavigation().navigate('CurrentHost');
    }
  }
}

function* sendCreditWorker({ payload }) {
  try {
    const result = yield call(GuestService.sendCredit, payload);
    success(t('success_message_credit_sent'));
    return result;
  } catch (e) {
    if (result(e, 'response.status') === 409) {
      error(t('error_message_credit_not_sent_already_paid'));
    } else if (result(e, 'response.data.message')) {
      error(t(result(e, 'response.data.message')));
    } else {
      error(t('error_message_credit_not_sent'));
    }
    throw e;
  }
}

function* updateVotingStatusWorker({ payload }) {
  const { hostId, status } = payload;
  const votingStatus = yield select(selectVotingStatus);
  if (status !== votingStatus) {
    if (!status) {
      yield put(getHostStatus({ hostId, action: constants.FINISH_VOTING_STATUS }));
      yield take(constants.FINISH_VOTING_STATUS);
    }
    yield put({ type: constants.SET_VOTING_STATUS, payload: status });
  }
}

export default function* watchAll() {
  yield all([
    takeEvery(constants.API_GET_HOST_STATUS, getHostStatusWorker),
    takeLatest(constants.API_SEND_VOTE, sendVoteWorker),
    takeEvery(constants.API_ADD_SONG, addSongWorker),
    takeEvery(constants.API_SEND_CREDIT, handleMiddleware, sendCreditWorker),
    takeLeading(constants.UPDATE_VOTING_STATUS, updateVotingStatusWorker),
  ]);
}
