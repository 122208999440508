import * as constants from '../constants';
import { saveData } from '../services/storage';

const initialState = {
  main: false,
  host: false,
  guest: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_ONBOARDED: {
      const newOnboarded = {
        ...state,
        ...payload,
      };
      saveData('onboarded', newOnboarded);
      return newOnboarded;
    }
    case constants.STORAGE_LOAD: {
      if (payload.onboarded) {
        return payload.onboarded;
      }
      return state;
    }
    default:
      return state;
  }
};
