import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { result } from 'lodash';
import { View, StyleSheet } from 'react-native';

import BackgroundWrapper from '../../components/BackgroundWrapper';
import Loading from '../../components/Loading';
import ContentWrapper from '../../components/ContentWrapper';
import theme from '../../theme';
import { mpCheckCreditStatus } from '../../actions';
import { t } from '../../services/i18n';

export default function CheckCredit({ route }) {
  const dispatch = useDispatch();
  const paymentId = result(route, 'params.paymentId');

  useEffect(() => {
    dispatch(mpCheckCreditStatus({ paymentId, start: true }));
  }, []);

  return (
    <View style={styles.root}>
      <BackgroundWrapper>
        <ContentWrapper style={styles.container}>
          <View style={styles.loadingContainer}>
            <Loading
              title={t('text_checking_credits')}
              style={styles.loading}
              titleStyle={styles.text}
            />
          </View>
        </ContentWrapper>
      </BackgroundWrapper>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    backgroundColor: theme.secondaryButton.bg1,
    opacity: 0.8,
    padding: theme.spacing(0.9),
    borderRadius: 10,
  },
  text: {
    marginLeft: 0,
    marginTop: theme.spacing(0.5),
  },
  loading: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
  },
});
