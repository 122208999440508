import { find, map } from 'lodash';

import * as constants from '../../constants';

const initialState = [];

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.MODAL_OPEN: {
      return [...state, payload];
    }
    case constants.MODAL_CLOSE: {
      return state.filter(m => m.id !== payload.id);
    }
    case constants.MODAL_UPDATE: {
      const modals = [...state];
      const modal = find(modals, m => m.id === payload.id);
      if (modal) {
        if (payload.buttons) {
          modal.buttons = map(modal.buttons, button => {
            const newButton = find(payload.buttons, b => b.id === button.id);
            return {
              ...button,
              ...newButton,
            };
          });
        }
      }
      return modals;
    }
    case constants.CLEAN_USER: {
      return initialState;
    }
    default:
      return state;
  }
};
