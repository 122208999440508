import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

import Option from './Option';
import { selectWinnerTrack } from '../../../selectors';
import { hideConfetti, showConfetti } from '../../../actions';

const InstantOptions = ({ hostId, options, sendingVote, votingStatus, handlePaidPress }) => {
  const [expanded, setExpanded] = useState(null);
  const [elected, setElected] = useState(false);
  const dispatch = useDispatch();
  const winnerTrack = useSelector(selectWinnerTrack);
  const splitIndex = Math.ceil(options.length / 2);
  const leftSideOptions = options.slice(0, splitIndex);
  const rightSideOptions = options.slice(splitIndex);
  const styles = options.length === 2 ? stylesVertical : stylesHorizontal;

  const handlePress = (paid, uri) => {
    if (paid) {
      handlePaidPress(paid);
    } else {
      if (expanded === uri) {
        setExpanded(null);
      } else {
        setExpanded(uri);
      }
    }
  };

  useEffect(() => {
    if (!votingStatus && winnerTrack) {
      if (winnerTrack !== elected) {
        setElected(winnerTrack);
        const option = options.find(o => o.uri === winnerTrack);
        if (option.votes > 0) {
          dispatch(
            showConfetti({
              x: '50%',
              y: 0,
            }),
          );
        }
      }
    } else {
      setElected(null);
      dispatch(hideConfetti());
    }
  }, [votingStatus, winnerTrack]);

  const canVote = !sendingVote && votingStatus;

  return (
    <View style={styles.root}>
      <View style={styles.leftColumn}>
        {map(leftSideOptions, option => (
          <Option
            key={option.uri}
            hostId={hostId}
            {...option}
            expanded={canVote && expanded === option.uri}
            onPress={() => handlePress(option.paid, option.uri)}
            elected={option.uri === elected}
            handlePaidPress={handlePaidPress}
            disabled={!canVote && option.uri !== elected}
          />
        ))}
      </View>
      <View style={styles.rightColumn}>
        {map(rightSideOptions, option => (
          <Option
            key={option.uri}
            hostId={hostId}
            expanded={canVote && expanded === option.uri}
            onPress={() => handlePress(option.paid, option.uri)}
            elected={option.uri === elected}
            handlePaidPress={handlePaidPress}
            disabled={!canVote && option.uri !== elected}
            {...option}
          />
        ))}
      </View>
    </View>
  );
};

const stylesVertical = StyleSheet.create({
  root: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
  },
  leftColumn: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  rightColumn: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
});

const stylesHorizontal = StyleSheet.create({
  root: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  leftColumn: {
    flex: 1,
    paddingRight: 10,
    flexDirection: 'column',
    height: '100%',
  },
  rightColumn: {
    flex: 1,
    paddingLeft: 10,
    flexDirection: 'column',
    height: '100%',
  },
});

export default InstantOptions;
