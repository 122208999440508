import { Platform, Share } from 'react-native';
import * as Sharing from 'expo-sharing';
import { t } from './i18n';
import { error as toastError } from '../helpers/toast';

export const shareFile = async uri => {
  if (!(await Sharing.isAvailableAsync())) {
    toastError(t('error_message_sharing_unavailable'));
    return;
  }

  Platform.OS === 'ios' ? Share.share({ url: uri }) : Sharing.shareAsync(uri);
};
