import * as constants from '../../constants';

const initialState = {
  loading: false,
  hosts: [],
  error: null,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.API_GET_CLOSEST_HOSTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.API_GET_CLOSEST_HOSTS_LOW_ACCURACY: {
      return {
        ...state,
        lowAccuracy: payload,
      };
    }
    case constants.API_GET_CLOSEST_HOSTS_SUCCESS: {
      return {
        ...state,
        hosts: payload,
        loading: false,
      };
    }
    case constants.API_GET_CLOSEST_HOSTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case constants.CLEAN_USER: {
      return initialState;
    }
    default:
      return state;
  }
};
