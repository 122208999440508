import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';

import IconButton from './IconButton';
import Button from './Button';
import Text from './Text';

const TitleWithOptions = ({ title, actions, titleVariant }) => {
  return (
    <View style={[styles.root, !title ? styles.end : null]}>
      {title ? (
        <Text style={styles.title} variant={titleVariant}>
          {title}
        </Text>
      ) : null}
      {actions.map((action, index) =>
        !action.title ? (
          <IconButton key={index} size={24} {...action} color="white" />
        ) : (
          <Button
            key={index}
            {...action}
            icon={{
              icon: action.icon,
              external: true,
              size: 24,
              color: 'white',
            }}
            style={styles.button}
            color="quaternary"
            variant="tight"
          />
        ),
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  end: {
    justifyContent: 'flex-end',
  },
  button: {
    paddingVertical: 2,
    marginBottom: 0,
  },
  title: {
    flex: 1,
    marginBottom: 0,
    lineHeight: 18,
  },
});

export default TitleWithOptions;
