import { result } from 'lodash';

export const selectNetwork = state => state.network;
export const selectServerConfig = state => state.serverConfig;
export const selectServerConfigData = state => state.serverConfig.data;
export const selectUser = state => state.user.data;
export const selectUserInformation = state => state.userInformation;
export const selectUserAuthorization = state => state.user.userAuthorization;
export const selectHost = state => state.host;
export const selectPlayer = state => state.player;
export const selectModal = state => state.modal;
export const selectPlaylist = state => state.playlist;
export const selectGuests = state => state.guests;
export const selectOnboarded = state => state.onboarded;
export const selectSearchLibrary = type => state => state.searchLibrary[type];
export const selectBlacklist = state => state.blacklist;
export const selectCollapseStatus = state => state.collapsible.expand;
export const selectMerch = state => state.merch;
export const selectInAppPurchase = state => state.iap;
export const selectMercadopago = state => state.mercadopago;
export const selectNotificationWebView = state => state.notification.webView;

// Guest selectors
export const selectHosts = state => state.guest.hosts;
export const selectLowAccuracy = state => state.guest.lowAccuracy;
export const loadingHosts = state => state.guest.loading;
export const selectedHost = state => state.selectedHost.data;
export const selectedHostJoining = state => state.selectedHost.loading;
export const selectCurrentTrack = state => state.selectedHost.currentTrack;
export const selectVotingStatus = state => state.selectedHost.votingStatus;
export const selectOptions = state => state.selectedHost.options;
export const selectWinnerTrack = state => state.selectedHost.winnerTrack;
export const selectSendingVoteStatus = state => state.selectedHost.sendingVote;
export const selectAddingSongStatus = state => state.selectedHost.addingSong;
export const selectLoadingTracks = state => state.selectedHost.loadingTracks;

// Very specific selectors
export const selectGuestAvailableCredit = state => {
  const userInformation = selectUserInformation(state);
  return result(userInformation, 'data.credit', 0);
};
export const selectPaidSong = state => {
  const options = selectOptions(state);
  return options.find(option => option.paid);
};
export const selectConfettiStatus = state => state.confetti;
