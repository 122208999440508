import api from './api';

export default class {
  static getTrackInfo() {
    return api.get('/host/player/track');
  }

  static play(deviceId, playlistId) {
    return api.put('/host/player/play', {
      deviceId,
      playlistId,
    });
  }

  static pause() {
    return api.put('/host/player/pause');
  }

  static next() {
    return api.put('/host/player/next');
  }

  static setVolume(volume) {
    return api.put('/host/player/volume', {
      volume,
    });
  }

  static getStatus() {
    return api.get('/host/player/status');
  }
}
