import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';

import theme from '../theme';

const ContentWrapper = ({ children, style, rootStyle }) => {
  return (
    <View style={[styles.root, rootStyle]}>
      <View style={[styles.inner, style]}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  inner: {
    flex: 1,
    width: '100%',
    maxWidth: Platform.OS === 'web' ? 1000 : undefined,
    paddingHorizontal: theme.spacing(1),
    marginHorizontal: 'auto',
  },
});

export default ContentWrapper;
