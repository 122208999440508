import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LayoutAnimation, UIManager, Platform, FlatList, View, StyleSheet } from 'react-native';
import { size } from 'lodash';

import SearchBox from './SearchBox';
import WebRefreshControl from './web/RefreshControl';
import RefreshControl from './RefreshControl';
import { SPRING } from '../services/animation';

if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const AnimatedList = ({
  title,
  subTitle,
  otherActions,
  data: originalData,
  showBar = false,
  onRefresh,
  refreshing,
  ...props
}) => {
  const ref = useRef();
  const [data, setData] = useState(originalData);

  const setDataWithAnimation = newData => {
    LayoutAnimation.configureNext(SPRING);
    setData(newData);
  };

  useEffect(() => {
    setDataWithAnimation(originalData);
  }, [originalData]);

  useEffect(() => {
    if (Platform.OS === 'web' && props.contentOffset && props.contentOffset.y) {
      ref.current.scrollToOffset({ offset: props.contentOffset.y, animated: false });
    }
  }, []);

  const handleFilter = useCallback(index => {
    if (index > -1) {
      ref.current.scrollToIndex({ index });
    }
  }, []);

  const handleScrollFailed = error => {
    const offset = error.averageItemLength * error.index;
    ref.current.scrollToOffset({ offset });
    setTimeout(() => ref.current.scrollToIndex({ index: error.index }), 100);
  };

  const bar =
    size(originalData) > 0 ? (
      <SearchBox
        title={title}
        otherActions={otherActions}
        onFilter={handleFilter}
        data={originalData}
      />
    ) : null;

  return (
    <View style={styles.root}>
      {Platform.OS === 'web' && onRefresh && (
        <WebRefreshControl onRefresh={onRefresh} refreshing={refreshing} />
      )}
      <FlatList
        {...props}
        data={data}
        ref={ref}
        onScrollToIndexFailed={handleScrollFailed}
        refreshControl={
          Platform.OS !== 'web' &&
          onRefresh && <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
        }
      />
      {showBar && bar}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});

export default AnimatedList;
