import React from 'react';
import { result } from 'lodash';
import { useState } from 'react';
import { StyleSheet, View, Animated } from 'react-native';

import theme from '../../theme';

const Skeleton = ({ style, backgroundColor, circleSize, widthAnim }) => {
  const [width, setWidth] = useState(0);

  const translateX = () => {
    return widthAnim.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, width],
    });
  };

  return (
    <View
      onLayout={e => setWidth(e.nativeEvent.layout.width)}
      style={[
        styles.root,
        style,
        { backgroundColor },
        circleSize
          ? { width: circleSize, height: circleSize, borderRadius: circleSize }
          : undefined,
      ]}
    >
      <Animated.View
        style={[styles.animation, { transform: [{ translateX: translateX() }] }]}
      ></Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  animation: {
    width: '20%',
    height: '100%',
    backgroundColor: theme.color.white,
    opacity: 0.05,
  },
});

export default Skeleton;
