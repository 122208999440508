import { all, takeEvery, call, put, fork } from 'redux-saga/effects';
import { result } from 'lodash';

import handleMiddleware from '../middleware';
import PlayerService from '../../services/player';
import * as constants from '../../constants';
import { getTrackList } from '../../actions';
import { checkNextSongWorker } from '../track';

function* getTrackInfoWorker() {
  return yield call(PlayerService.getTrackInfo);
}

function* playWorker() {
  return yield call(PlayerService.play);
}

function* pauseWorker() {
  return yield call(PlayerService.pause);
}

function* nextWorker() {
  const result = yield call(PlayerService.next);
  yield call(getPlayerStatusWorker);
  yield put(getTrackList());
  return result;
}

function* setVolumeWorker({ payload }) {
  return yield call(PlayerService.setVolume, payload.volume);
}

function* getPlayerStatusWorker(worker) {
  try {
    const firstTime = result(worker, 'payload');
    console.log('Getting host player status', firstTime);
    const response = yield call(PlayerService.getStatus);
    const player = result(response, 'data.player');
    yield fork(checkNextSongWorker, 'host', player, getPlayerStatusWorker);
    yield put({ type: constants.API_GET_PLAYER_STATUS_SUCCESS, payload: player });
    if (firstTime) {
      const [device] = result(response, 'data.devices', []);
      const playlistId = result(response, 'data.playlistId');
      const isPlaying = result(player, 'isPlaying');
      if (device && playlistId && !isPlaying) {
        yield call(PlayerService.play, device.id, playlistId);
        yield call(getPlayerStatusWorker);
      }
    }
  } catch (error) {
    console.log(error);
    const reason = result(error, 'response.data.reason');
    yield put({ type: constants.API_GET_PLAYER_STATUS_FAILURE, payload: reason });
  }
}

export default function* watchPlayer() {
  yield all([
    takeEvery(constants.API_GET_TRACK_INFO, handleMiddleware, getTrackInfoWorker),
    takeEvery(constants.API_PLAY_MUSIC, handleMiddleware, playWorker),
    takeEvery(constants.API_PAUSE_MUSIC, handleMiddleware, pauseWorker),
    takeEvery(constants.API_NEXT_SONG, handleMiddleware, nextWorker),
    takeEvery(constants.API_SET_VOLUME, handleMiddleware, setVolumeWorker),
    takeEvery(constants.API_GET_PLAYER_STATUS, getPlayerStatusWorker),
  ]);
}
