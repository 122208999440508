import React from 'react';
import { StyleSheet, View, TouchableOpacity, Linking, Platform } from 'react-native';
import { result } from 'lodash';

import Text from '../../components/Text';
import theme from '../../theme';
import { t } from '../../services/i18n';
import { formatDate } from '../../services/format';
import { useServerConfig } from '../../hooks/useServerConfig';

const CanceledSubscription = ({ subscription }) => {
  const [freeMaxUsersAllowed] = useServerConfig('subscription.freeMaxUsersAllowed');
  const expiredString = result(subscription, 'entitlements.active.premium.expirationDate', null);

  const handleOpenUrl = () => {
    if (Platform.OS == 'web') {
      window.open(subscription.managementURL, '_blank');
    } else {
      Linking.openURL(subscription.managementURL);
    }
  }

  return (
    <View style={styles.container}>
      <Text variant="title" centered>
        {t('text_subscription_canceled_title')}
      </Text>
      <View style={styles.inner}>
        <Text spacingBelow>
          {t('text_subscription_canceled_instructions', {
            maxGuests: freeMaxUsersAllowed,
            endDate: formatDate(expiredString),
          })}
        </Text>
        <TouchableOpacity style={styles.button} onPress={handleOpenUrl}>
          <Text style={styles.textButton}>{t('text_subscription_admin')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  textButton: {
    color: theme.color.red,
  },
  container: {
    flex: 1,
    backgroundColor: theme.black.bg1,
  },
  inner: {
    flex: 1,
  },
});

export default CanceledSubscription;
