import { all, takeEvery, call, takeLatest, put } from 'redux-saga/effects';

import handleMiddleware from '../middleware';
import PlaylistService from '../../services/playlist';
import * as constants from '../../constants';
import { getNavigation } from '../../services/navigation';
import { mapHttpError } from '../../helpers/format';
import { getHostId } from '.';

function* getPlaylistsWorker() {
  return yield call(PlaylistService.getPlaylists);
}

function* importPlaylistWorker({ payload }) {
  try {
    yield put({ type: constants.API_IMPORT_PLAYLIST_REQUEST, payload: payload.id });
    const response = yield call(PlaylistService.importPlaylist, {
      id: payload.id,
      name: payload.name,
    });
    yield put({ type: constants.API_IMPORT_PLAYLIST_SUCCESS, payload: response.data });
    yield put({ type: constants.API_GET_TRACKLIST });
    getNavigation().navigate('HostHome');
  } catch (error) {
    yield put({
      type: constants.API_IMPORT_PLAYLIST_FAILURE,
      payload: mapHttpError(error),
    });
  }
}

function* getTrackListWorker({ payload: showLoading }) {
  try {
    if (showLoading) {
      yield put({ type: constants.API_GET_TRACKLIST_REQUEST });
    }
    const { data } = yield call(PlaylistService.getTrackList);
    yield put({ type: constants.API_GET_TRACKLIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: constants.API_GET_TRACKLIST_FAILURE,
      payload: mapHttpError(error),
    });
  }
}

function* cleanTrackListWorker() {
  try {
    yield put({ type: constants.API_CLEAN_TRACKLIST_REQUEST });
    const { data } = yield call(PlaylistService.cleanTrackList);
    yield put({ type: constants.API_CLEAN_TRACKLIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: constants.API_CLEAN_TRACKLIST_FAILURE,
      payload: mapHttpError(error),
    });
  }
}

function* addSongsWorker({ payload }) {
  try {
    yield put({ type: constants.API_ADD_SONGS_REQUEST });
    yield call(PlaylistService.addSongs, payload);
    const hostId = yield call(getHostId);
    yield put({
      type: constants.API_ADD_SONGS_SUCCESS,
      payload: { hostId, uris: payload, from: 'host' },
    });
    yield put({ type: constants.API_GET_TRACKLIST });
  } catch (error) {
    yield put({
      type: constants.API_ADD_SONGS_FAILURE,
      payload: mapHttpError(error),
    });
  }
}

function* deleteSongWorker({ payload }) {
  try {
    yield put({ type: constants.API_DELETE_SONG_REQUEST, payload: payload.uri });
    const response = yield call(PlaylistService.deleteSong, payload.uri);
    const hostId = yield call(getHostId);
    yield put({ type: constants.API_DELETE_SONG_SUCCESS, payload: { hostId, uri: response.data } });
    yield put({ type: constants.API_GET_TRACKLIST });
  } catch (error) {
    yield put({
      type: constants.API_DELETE_SONG_FAILURE,
      payload: mapHttpError(error),
    });
  }
}

export default function* watchAll() {
  yield all([
    takeEvery(constants.API_GET_PLAYLISTS, handleMiddleware, getPlaylistsWorker),
    takeLatest(constants.API_IMPORT_PLAYLIST, importPlaylistWorker),
    takeEvery(constants.API_GET_TRACKLIST, getTrackListWorker),
    takeEvery(constants.API_CLEAN_TRACKLIST, cleanTrackListWorker),
    takeEvery(constants.API_ADD_SONGS, addSongsWorker),
    takeLatest(constants.API_DELETE_SONG, deleteSongWorker),
  ]);
}
