import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, Platform, View } from 'react-native';
import { size } from 'lodash';

import Layout from '../../components/Layout';
import BannedTrack from '../../components/host/BannedTrack';
import TextIcon from '../../components/TextIcon';
import Loading from '../../components/Loading';
import AnimatedList from '../../components/AnimatedList';
import ContentWrapper from '../../components/ContentWrapper';
import * as analytics from '../../services/analytics';
import { allowSong, getBlacklist } from '../../actions';
import { selectBlacklist } from '../../selectors';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import { t } from '../../services/i18n';
import { useLoadingStatus } from '../../hooks/useLoadingStatus';
import SkeletonWrapper from '../../components/skeleton/SkeletonWrapper';
import skeleton from '../../helpers/skeleton';

const Blacklist = () => {
  const dispatch = useDispatch();
  const { data: blacklist, loading, updating: updatingBlacklist } = useSelector(selectBlacklist);
  const loadingStatus = useLoadingStatus(loading);

  useScreenFocus(() => {
    analytics.screen('blacklist');
  });

  useEffect(() => {
    handleGetBlacklist();
  }, []);

  const handleGetBlacklist = () => {
    dispatch(getBlacklist());
  };

  const handleAllowSong = uri => {
    dispatch(
      allowSong({
        uri,
        status: true,
      }),
    );
  };

  return (
    <Layout
      title={t('screen_title_blacklist')}
      hint="hint_help_blacklist_screen"
    >
      <View style={styles.root}>
        {loadingStatus.initialLoading ? (
          <SkeletonWrapper {...skeleton.playlist} repeat={10} />
        ) : size(blacklist) > 0 ? (
          <ContentWrapper style={styles.inner}>
            <AnimatedList
              data={blacklist}
              onRefresh={handleGetBlacklist}
              refreshing={loading}
              renderItem={item => (
                <BannedTrack
                  {...item}
                  onAllowSong={handleAllowSong}
                  updatingBlacklist={updatingBlacklist}
                />
              )}
              keyExtractor={item => item.uri}
            />
          </ContentWrapper>
        ) : (
          <TextIcon text={t('text_empty_blacklist')} icon="disc-full" />
        )}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  inner: {
    paddingTop: Platform.OS === 'web' ? 0 : 10,
    flex: 1,
  },
});

export default Blacklist;
