const development = {
  SPOTIFY_CLIENT_ID: 'd6b26a79c3f84d86b172596bd04f1327',
  SPOTIFY_USER_ID: '94990zhsgpj3g59wc98krngc7',
  ANDROID_GOOGLE_CLIENT_ID: '59091977536-b0keke6vaof0djd43ao9845o4rpisrp1.apps.googleusercontent.com',
  WEB_GOOGLE_CLIENT_ID: '59091977536-giddjh1hjjp05pug5433n9ch6mmbbpsa.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyCCdwXLlMSVzFu0lwYpOsqhbQUW9eMuAJ8',
  SERVER_PUBLIC_URL: 'https://api.loudy.app',
  ACCOUNT_PUBLIC_URL: 'https://account.loudy.app',
  AWS_BUCKET_URL: 'https://loudyapp.s3.us-west-2.amazonaws.com',
  REVENUE_CAT_API_KEY_GOOGLE: 'goog_wwYGFzQfQAuASPYkPikVDuUXNUh',
  REVENUE_CAT_API_KEY_APPLE: 'appl_wrsrpusevSBvjOcCJjvliIAEfub',
};

const staging = {
  SPOTIFY_CLIENT_ID: '021e6808fdde428c852566a8c3fba526',
  SPOTIFY_USER_ID: '94990zhsgpj3g59wc98krngc7',
  ANDROID_GOOGLE_CLIENT_ID: '59091977536-kmjehvln1rift24g6kqmp5nerssq51po.apps.googleusercontent.com',
  WEB_GOOGLE_CLIENT_ID: '59091977536-fjpev1juo2mpvd6hv51l61p1p3aip5la.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyCCdwXLlMSVzFu0lwYpOsqhbQUW9eMuAJ8',
  SERVER_PUBLIC_URL: 'https://loudy-server-staging.herokuapp.com',
  ACCOUNT_PUBLIC_URL: 'https://loudy-account-staging.herokuapp.com',
  AWS_BUCKET_URL: 'https://loudyappstaging.s3.us-west-2.amazonaws.com',
  REVENUE_CAT_API_KEY_GOOGLE: 'goog_wwYGFzQfQAuASPYkPikVDuUXNUh',
  REVENUE_CAT_API_KEY_APPLE: 'appl_wrsrpusevSBvjOcCJjvliIAEfub',
};

const production = {
  SPOTIFY_CLIENT_ID: 'd6b26a79c3f84d86b172596bd04f1327',
  SPOTIFY_USER_ID: '94990zhsgpj3g59wc98krngc7',
  ANDROID_GOOGLE_CLIENT_ID: '59091977536-b0keke6vaof0djd43ao9845o4rpisrp1.apps.googleusercontent.com',
  WEB_GOOGLE_CLIENT_ID: '59091977536-giddjh1hjjp05pug5433n9ch6mmbbpsa.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyCCdwXLlMSVzFu0lwYpOsqhbQUW9eMuAJ8',
  SERVER_PUBLIC_URL: 'https://api.loudy.app',
  ACCOUNT_PUBLIC_URL: 'https://account.loudy.app',
  AWS_BUCKET_URL: 'https://loudyapp.s3.us-west-2.amazonaws.com',
  REVENUE_CAT_API_KEY_GOOGLE: 'goog_wwYGFzQfQAuASPYkPikVDuUXNUh',
  REVENUE_CAT_API_KEY_APPLE: 'appl_wrsrpusevSBvjOcCJjvliIAEfub',
};

const loadConfig = (name) => {
  if (process && process.env && process.env[name]) {
    return process.env[name];
  }
  const envConfig = development;
  return envConfig[name];
};

export default {
  spotify: {
    clientId: loadConfig('SPOTIFY_CLIENT_ID'),
    scopes: [
      'user-read-private',
      'user-read-email',
      'user-modify-playback-state',
      'user-read-playback-state',
      'user-read-recently-played',
      'playlist-modify-private',
      'playlist-modify-public',
      'playlist-read-collaborative',
      'playlist-read-private',
      'ugc-image-upload',
    ],
    authorizationEndpoint: 'https://accounts.spotify.com/authorize',
    userId: loadConfig('SPOTIFY_USER_ID'),
  },
  google: {
    androidClientId: loadConfig('ANDROID_GOOGLE_CLIENT_ID'),
    webClientId: loadConfig('WEB_GOOGLE_CLIENT_ID'),
    apiKey: loadConfig('GOOGLE_API_KEY'),
  },
  server: {
    endpoint: loadConfig('SERVER_PUBLIC_URL'),
  },
  account: {
    url: loadConfig('ACCOUNT_PUBLIC_URL'),
  },
  aws: {
    bucketUrl: loadConfig('AWS_BUCKET_URL'),
  },
  revenueCat: {
    google: loadConfig('REVENUE_CAT_API_KEY_GOOGLE'),
    apple: loadConfig('REVENUE_CAT_API_KEY_APPLE'),
  },
};
