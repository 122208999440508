import React from 'react';
import { StyleSheet, View, Linking, Platform } from 'react-native';
import { useDispatch } from 'react-redux';

import { downloadMerch } from '../../actions';
import Text from '../Text';
import Button from '../Button';
import Space from '../Space';
import { t } from '../../services/i18n';
import theme from '../../theme';

const ActiveSubscription = ({ subscription }) => {
  const dispatch = useDispatch();

  const handleOpenUrl = () => {
    if (Platform.OS == 'web') {
      window.open(subscription.managementURL, '_blank');
    } else {
      Linking.openURL(subscription.managementURL);
    }
  }

  const handleDownloadMerch = () => {
    dispatch(downloadMerch());
  };

  return (
    <View style={styles.container}>
      <Text variant="title" centered>
        {t('text_subscription_title')}
      </Text>
      <View style={styles.inner}>
        
        {subscription?.managementURL ? (
          <>
            <Text centered spacingBelow>{t('text_subscription_instructions_1')}</Text>
            <Text centered spacingBelow>{t('text_subscription_instructions_2')}</Text>
            <Button
              style={styles.button}
              title={t('text_subscription_admin')}
              onPress={handleOpenUrl}
              color="secondary" />
          </>
        ) : (
          <>
            <Text centered spacingBelow>{t('text_subscription_instructions_1')}</Text>
            <Text centered spacingBelow>{t('text_subscription_instructions_2b')}</Text>
            <Space />
          </>
        )}
        <Text centered variant="title">
          {t('text_subscription_merch')}
        </Text>
        <Button title={t('action_download_merch')} onPress={handleDownloadMerch} color="tertiary" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  textButton: {
    color: theme.color.red,
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  container: {
    flex: 1,
    backgroundColor: theme.black.bg1,
  },
  inner: {
    flex: 1,
  },
});

export default ActiveSubscription;
