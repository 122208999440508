import * as constants from '../constants';

const initialState = {
  connected: false,
  type: null,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_NETWORK: {
      return payload;
    }
    default:
      return state;
  }
};
