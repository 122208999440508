import * as ExpoImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

const resizeImage = async (image, base64 = true) => {
  try {
    const resizedImage = await ImageManipulator.manipulateAsync(
      image.uri,
      [
        {
          resize: { width: 300, height: 300 },
        },
      ],
      {
        compress: 1,
        format: ImageManipulator.SaveFormat.PNG,
        base64,
      },
    );
    return resizedImage;
  } catch (error) {
    console.log('Error resizing image', error);
  }
  return image;
};

export const resizeWebImage = async (uri, { x, y, width, height }) => {
  try {
    const resizedImage = await ImageManipulator.manipulateAsync(
      uri,
      [
        {
          crop: {
            originX: x,
            originY: y,
            width,
            height,
          },
        },
      ],
      {
        compress: 1,
        format: ImageManipulator.SaveFormat.PNG,
        base64: true,
      },
    );
    return resizedImage.base64;
  } catch (error) {
    console.log('Error resizing image', error);
  }
};

export const getImageFromCamera = async () => {
  try {
    const result = await ExpoImagePicker.launchCameraAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.5,
      base64: true,
    });

    if (!result.cancelled) {
      const resizedImage = await resizeImage(result);
      return {
        uri: resizedImage.uri,
        base64: resizedImage.base64,
      };
    }
  } catch (error) {
    console.log('Error getting image from camera', error);
  }
  return null;
};

export const getImageFromLibrary = async () => {
  try {
    const result = await ExpoImagePicker.launchImageLibraryAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (!result.cancelled) {
      const resizedImage = await resizeImage(result);
      return {
        uri: resizedImage.uri,
        base64: resizedImage,
      };
    }
  } catch (error) {
    console.log('Error getting image from library', error);
  }
  return null;
};

export const getImageFromComputer = async () => {
  try {
    const result = await ExpoImagePicker.launchImageLibraryAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (!result.cancelled) {
      return result.uri;
    }
  } catch (error) {
    console.log('Error getting image from computer', error);
  }
  return null;
};
