import React from 'react';
import { Dimensions, Platform, View, StyleSheet } from 'react-native';
import { LineChart as CKLineChart } from 'expo-chart-kit';

import Text from '../Text';
import theme from '../../theme';

const screenWidth = Math.min(Dimensions.get('window').width, Platform.OS === 'web' ? 600 : 10000);

const LineChart = ({ title, labels, values }) => {
  if (!labels || !values) {
    return null;
  }

  const width = screenWidth - theme.spacing(2);

  return (
    <View style={styles.root}>
      {title && (
        <Text variant="subtitle" style={styles.text}>
          {title}
        </Text>
      )}
      <View style={styles.container}>
        <CKLineChart
          data={{
            labels,
            datasets: [
              {
                data: values,
              },
            ],
          }}
          width={width}
          height={width * 0.8}
          chartConfig={{
            backgroundColor: theme.color.black,
            backgroundGradientFrom: theme.color.blackLight,
            backgroundGradientTo: theme.color.blackLight,
            decimalPlaces: 0,
            color: (opacity = 1) => theme.color.white,
          }}
          style={styles.graph}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    marginBottom: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(0.5),
  },
  container: {
    backgroundColor: theme.color.blackLight,
    borderRadius: 10,
  },
  graph: {
    paddingTop: theme.spacing(1.5),
    transform: [{ translateX: -25 }],
  },
});

export default LineChart;
