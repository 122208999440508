import { Platform } from 'react-native';

const palette = {
  red: '#E5005E',
  blue: '#0CA9DA',
  black: '#000000',
  blackLight: '#373934',
  grayDark: '#4B4B4B',
  grayLight: '#A5A5A5',
  white: '#FFFFFF',
  spotify: '#1ECF5E',
  youtube: '#C4302B',
  itunes: '#EA4CC0',
  pastel: '#F8E5C6',
  success: '#25b84c',
  darkBlue: '#244e9c',
  info: '#258cb8',
  error: '#bd1a1a',
  warning: '#cfc811',
  green: '#1db954',
  transparent: 'transparent',
};

const black = {
  text: palette.white,
  bg1: palette.black,
};

const white = {
  text: palette.black,
  bg1: palette.white,
};

const primaryButton = {
  text: palette.white,
  bg1: palette.red,
};

const secondaryButton = {
  text: palette.white,
  bg1: palette.grayDark,
};

const tertiaryButton = {
  text: palette.white,
  bg1: palette.blue,
};

const spotifyButton = {
  text: palette.white,
  bg1: palette.spotify,
};

const youtubeButton = {
  text: palette.white,
  bg1: palette.youtube,
};

const itunesButton = {
  text: palette.white,
  bg1: palette.itunes,
};

const primaryFontFamily = 'Roboto';

const getFontSizeAndLineHeight = size => ({
  fontSize: size,
  lineHeight: size * 1.5,
});

const spacing = amount => amount * 20;

const font = {
  body: {
    fontFamily: primaryFontFamily,
    ...getFontSizeAndLineHeight(14),
  },
  title: {
    fontFamily: primaryFontFamily,
    fontSize: 22,
    lineHeight: Platform.OS === 'ios' ? 30 : 26,
    marginBottom: spacing(1),
  },
  subTitle: {
    fontFamily: primaryFontFamily,
    fontSize: 18,
  },
  button: {
    fontFamily: primaryFontFamily,
    ...getFontSizeAndLineHeight(16),
  },
  small: {
    fontFamily: primaryFontFamily,
    fontSize: 14,
  },
  input: {
    fontFamily: primaryFontFamily,
    ...getFontSizeAndLineHeight(16),
  },
  primaryText: {
    color: palette.white,
  },
};

export default {
  black,
  white,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  spotifyButton,
  youtubeButton,
  itunesButton,
  font,
  spacing,
  color: palette,
  confetti: [
    palette.blackLight,
    palette.red,
    palette.blue,
    palette.darkBlue,
    palette.pastel,
  ],
};
