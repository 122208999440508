import { result } from 'lodash';
import { call, takeEvery, all, put, take, select, delay } from 'redux-saga/effects';
import { makeUrl } from 'expo-linking';

import GuestService from '../services/guest';
import * as constants from '../constants';
import * as iap from '../services/iap';
import * as mercadopago from '../services/mercadopago';
import { getNavigation } from '../services/navigation';
import { t } from '../services/i18n';
import { selectInAppPurchase, selectMercadopago, selectServerConfigData } from '../selectors';
import { mpCheckCreditStatus } from '../actions';
import { error, success, warning } from '../helpers/toast';

const images = {
  credit: [
    {
      image: require('../assets/credit0.png'),
      width: 40,
      height: 25,
    },
    {
      image: require('../assets/credit1.png'),
      width: 40,
      height: 25,
    },
    {
      image: require('../assets/credit2.png'),
      width: 40,
      height: 30,
    },
  ],
};

const getImageForIndex = (index) => {
  if (index < images.credit.length) {
    return images.credit[index];
  }
  return images.credit[images.credit.length - 1];
};

function* sendCreditInAppPurchase(payload) {
  const { platform, product, purchase } = payload;
  try {
    yield put({ type: constants.BUY_CREDIT_REQUEST, payload: product.identifier });
    const { data } = yield call(GuestService.sendCreditInAppPurchase, platform, purchase);
    if (data) {
      success(t('success_message_add_credit'));
      yield put({ type: constants.BUY_CREDIT_SUCCESS, payload: data.guest, meta: data });
    } else {
      error(t('error_message_add_credit'));
      yield put({ type: constants.BUY_CREDIT_FAILURE });
    }
  } catch (e) {
    console.log('error sending credit in app purchase', e);
    error(t('error_message_add_credit'));
    yield put({ type: constants.BUY_CREDIT_FAILURE });
  }
  return 'Credit';
}

function* buildParams(type) {
  let options, selector;
  if (type === 'mercadopago') {
    options = yield call(buildMercadoPagoParams);
    selector = selectMercadopago;
  } else {
    options = yield call(buildParamsInAppPurchase);
    selector = selectInAppPurchase;
  }

  return {
    title: t('screen_title_credit_select_plan'),
    description: t('text_credit_description'),
    instructions: t('text_credit_instructions'),
    options,
    selector,
  };
}

function* buildParamsInAppPurchase() {
  const { products: items } = yield select(selectInAppPurchase);
  return items.map((item, index) => ({
    identifier: item.product.identifier,
    title: `${item.product.description} - ${item.product.priceString}`,
    price: item.product.price,
    credits: parseInt(item.product.description.split(' ')[0]),
    action: sendCreditInAppPurchase,
    icon: getImageForIndex(index),
    onPress: () => iap.purchase(item.product),
  }));
}

function* buildMercadoPagoParams() {
  const config = yield select(selectServerConfigData);

  return config.credit.plans.map((plan, index) => {
    const title = t('text_credit_plan_name', {
      credits: plan.credits,
      price: plan.priceArs || plan.price,
    });
    return {
      title,
      price: plan.priceArs,
      priceId: plan.webId,
      icon: getImageForIndex(index),
      onPress: () =>
        mercadopago
          .purchase({
            planId: plan.webId,
            title: t('text_credit_plan_name_short', plan),
            goBackUrl: makeUrl(),
          })
          .then(url => (window.location.href = url)),
    };
  });
}

function* handleInAppPurchaseCheckoutProductWorker() {
  const params = yield call(buildParams, 'iap');
  const navigation = getNavigation();
  navigation.navigate('SelectPlan', params);
  const checkoutResult = yield take(constants.IAP_FINISH_CHECKOUT_PRODUCT);
  const option = params.options.find(
    op => op.identifier === checkoutResult.payload.product.identifier,
  );
  console.log('purchased option', option);
  const route = yield call(option.action, checkoutResult.payload);
  navigation.navigate(route);
}

function* handleInAppPurchaseCheckoutSubscriptionWorker({ payload: { identifier, cb } }) {
  yield put({ type: constants.BUY_SUBSCRIPTION_REQUEST, identifier });
  const { subscriptions } = yield select(selectInAppPurchase);
  const subscription = subscriptions.find(s => s.product.identifier === identifier);
  try {
    console.log('Subscribe request', subscription.product);
    const result = yield call(iap.subscribe, subscription.product);
    console.log('Subscribe result', result);
    if (result) {
      success(t('success_message_subscribe'));
      yield put({ type: constants.BUY_SUBSCRIPTION_SUCCESS, payload: result });
    } else {
      error(t('error_message_subscribe'));
      yield put({ type: constants.BUY_SUBSCRIPTION_FAILURE });
    }
  } catch(e) {
    error(t('error_message_subscribe'));
    yield put({ type: constants.BUY_SUBSCRIPTION_FAILURE, payload: e });
  }
}

function* handleMPCheckoutWorker() {
  const params = yield call(buildParams, 'mercadopago');
  const navigation = getNavigation();
  navigation.navigate('SelectPlan', params);
}

let waiting = 0;

function* handleMPCheckCreditStatus({ payload: { paymentId, start } }) {
  try {
    if (start) {
      yield delay(2000);
      yield put({ type: constants.API_MP_CHECK_CREDIT_STATUS_REQUEST });
      waiting = 0;
    }
    const { data } = yield call(mercadopago.checkPayment, paymentId);
    const exists = result(data, 'exists');
    const delayFinished = waiting >= 30;

    if (exists || delayFinished) {
      yield put({ type: constants.API_MP_CHECK_CREDIT_STATUS_SUCCESS, payload: delayFinished });
      if (delayFinished) {
        warning(t('warning_message_add_credit_delayed'));
      }
      getNavigation().navigate('Credit', { handleBack: () => getNavigation().navigate('Home') });
    } else {
      waiting += 5;
      yield delay(5000);
      yield put(mpCheckCreditStatus({ paymentId }));
    }
  } catch (e) {
    error(t('error_message_add_credit'));
    getNavigation().navigate('Credit', { handleBack: () => getNavigation().navigate('Home') });
    yield put({ type: constants.API_MP_CHECK_CREDIT_STATUS_FAILURE });
  }
}

export default function* watchAll() {
  yield all([takeEvery(constants.IAP_START_CHECKOUT_PRODUCT, handleInAppPurchaseCheckoutProductWorker)]);
  yield all([takeEvery(constants.IAP_START_CHECKOUT_SUBSCRIPTION, handleInAppPurchaseCheckoutSubscriptionWorker)]);
  yield all([takeEvery(constants.MP_START_CHECKOUT, handleMPCheckoutWorker)]);
  yield all([takeEvery(constants.API_MP_CHECK_CREDIT_STATUS, handleMPCheckCreditStatus)]);
}
