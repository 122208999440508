import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import Cropper from 'react-easy-crop';

import Button from '../Button';
import { getImageFromComputer, resizeWebImage } from '../../services/image';
import { t } from '../../services/i18n';

const ImagePickerWeb = ({ setImage, handleCropActive }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    handleCropActive(!!imageSrc);
  }, [imageSrc]);

  const onCropComplete = useCallback((aaa, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handlePickImage = async () => {
    const result = await getImageFromComputer();
    if (result) {
      setImageSrc(result);
    }
  };

  const handleCrop = async () => {
    const uri = await resizeWebImage(imageSrc, croppedAreaPixels);
    setImageSrc(null);
    setImage({ uri });
  };

  return imageSrc ? (
    <View style={styles.root}>
      <Cropper
        image={imageSrc}
        crop={crop}
        aspect={1}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        style={{
          containerStyle: {
            width: '100%',
            height: '100%',
            position: 'relative',
            marginBottom: 10,
          },
        }}
      />
      <Button title={t('action_crop_image')} onPress={handleCrop} />
    </View>
  ) : (
    <Button title={t('action_image_picker_from_computer')} onPress={handlePickImage} />
  );
};

const styles = StyleSheet.create({
  root: {
    height: 300,
  },
});

export default ImagePickerWeb;
