import * as constants from '../constants';

const initialState = {
  loading: true,
  loadingCheckout: null,
  data: [],
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.BUY_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        loadingCheckout: payload,
      };
    }
    case constants.BUY_SUBSCRIPTION_SUCCESS: 
    case constants.BUY_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        loadingCheckout: false,
      };
    }
    default:
      return state;
  }
};
