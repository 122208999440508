import React from 'react';
import { StyleSheet } from 'react-native';

import Buttons from '../Buttons';
import { getImageFromCamera, getImageFromLibrary } from '../../services/image';
import { t } from '../../services/i18n';

const ImagePicker = ({ setImage }) => {
  const handlePickImage = {
    0: async () => {
      const result = await getImageFromCamera();
      if (result) {
        setImage(result);
      }
    },
    1: async () => {
      const result = await getImageFromLibrary();
      if (result) {
        setImage(result);
      }
    },
  };

  const buttons = [t('action_image_picker_from_camera'), t('action_image_picker_from_phone')];

  const handleButtonByIndex = index => {
    handlePickImage[index]();
  };

  return <Buttons style={styles.root} onPress={handleButtonByIndex} buttons={buttons} />;
};

const styles = StyleSheet.create({
  root: {
    marginBottom: 10,
  },
});

export default ImagePicker;
