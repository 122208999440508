import { Platform } from 'react-native';
import Purchases, { LOG_LEVEL } from 'react-native-purchases';

import { store } from '../store';
import { inAppPurchaseFinishCheckoutProduct, setInAppPurchaseLoadingCheckout, setInAppPurchaseProducts, setInAppPurchaseSubscriptions } from '../actions';
import localConfig from '../config';
import { error as toastError } from '../helpers/toast';
import { t } from './i18n';

export const start = async (userId) => {
  if (Platform.OS === 'web') {
    return;
  }

  const apiKey = Platform.select({
    ios: localConfig.revenueCat.apple,
    android: localConfig.revenueCat.google,
  });

  try {
    Purchases.setLogLevel(LOG_LEVEL.VERBOSE);
    Purchases.configure({ apiKey, appUserID: userId });
    
    const offerings = await Purchases.getOfferings();
    
    store.dispatch(setInAppPurchaseProducts(offerings.all.credit.availablePackages));
    store.dispatch(setInAppPurchaseSubscriptions(offerings.all.subscription.availablePackages));
  } catch (e) {
    console.log('error loading offerings', e);
  }
};

export const purchase = async (product) => {
  if (Platform.OS === 'web') {
    return;
  }

  console.log('Buying', product);
  // { introPrice: null, currencyCode: 'USD', priceString: 'US$ 0,99',
  // subscriptionPeriod: null, productCategory: 'NON_SUBSCRIPTION', price: 0.99,
  // title: '10 Créditos (Loudy)', productType: 'CONSUMABLE', discounts: null,
  // description: '10 Créditos', identifier: 'credit_option_1' }
  store.dispatch(setInAppPurchaseLoadingCheckout(product.identifier));
  
  try {
    const purchase = await Purchases.purchaseProduct(
      product.identifier,
      null,
      Purchases.PURCHASE_TYPE.INAPP);
    
    console.log('Purchase result', purchase);
    // {"customerInfo":{"nonSubscriptionTransactions":[{"productId":"credit_option_2","productIdentifier":"credit_option_2","purchaseDate":"2023-03-16T23:52:09.000Z","revenueCatId":"43d32a6d8f","transactionIdentifier":"43d32a6d8f"},{"productId":"credit_option_1","productIdentifier":"credit_option_1","purchaseDate":"2023-03-16T23:52:53.000Z","revenueCatId":"83819fd7ce","transactionIdentifier":"83819fd7ce"},{"productId":"credit_option_1","productIdentifier":"credit_option_1","purchaseDate":"2023-03-17T01:04:36.000Z","revenueCatId":"9ca97f9c54","transactionIdentifier":"9ca97f9c54"}],"originalPurchaseDate":null,"allPurchaseDatesMillis":{},"managementURL":null,"allPurchaseDates":{"credit_option_2":"2023-03-16T23:52:09.000Z","credit_option_1":"2023-03-17T01:04:36.000Z"},"originalPurchaseDateMillis":null,"latestExpirationDate":null,"allExpirationDatesMillis":{},"originalAppUserId":"$RCAnonymousID:84339e437695441383af633490c26532","allExpirationDates":{},"firstSeen":"2023-03-16T23:48:02.000Z","allPurchasedProductIdentifiers":["credit_option_2","credit_option_1"],"requestDate":"2023-03-17T01:04:47.000Z","latestExpirationDateMillis":null,"originalApplicationVersion":null,"activeSubscriptions":[],"entitlements":{"active":{},"all":{}}},"productIdentifier":"credit_option_1"}
    const isValid = product.identifier === purchase.productIdentifier;

    if (!isValid) {
      throw new Error('Custom validation did not pass: product.identifier === purchase.productIdentifier');
    }

    const payload = {
      platform: Platform.OS,
      purchase,
      product,
      credits: product.credits,
    };

    store.dispatch(inAppPurchaseFinishCheckoutProduct(payload));
    store.dispatch(setInAppPurchaseLoadingCheckout(false));
  } catch (e) {
    console.log('failed to purchase', e);
    store.dispatch(setInAppPurchaseLoadingCheckout(false));
    toastError(t('error_message_no_purchase'));
  }
};

export const subscribe = async (subscription) => {
  if (Platform.OS === 'web') {
    return;
  }

  console.log('Subscribing', subscription);
  // { introPrice
  store.dispatch(setInAppPurchaseLoadingCheckout(subscription.identifier));
  
  try {
    const purchase = await Purchases.purchaseProduct(
      subscription.identifier,
      null,
      Purchases.PURCHASE_TYPE.SUBS);
    
    console.log('Subscription result', purchase);
    // {"customerInfo
    const isValid = subscription.identifier === purchase.productIdentifier;

    if (!isValid) {
      throw new Error('Custom validation did not pass: subscription.identifier === purchase.productIdentifier');
    }

    const payload = {
      platform: Platform.OS,
      purchase,
      subscription,
    };

    store.dispatch(setInAppPurchaseLoadingCheckout(false));

    return payload;
  } catch (e) {
    console.log('failed to purchase', e);
    store.dispatch(setInAppPurchaseLoadingCheckout(false));
    toastError(t('error_message_no_purchase'));
    return null;
  }
};

export const getSubscription = async (withDelay) => {
  if (withDelay) {
    // small wait cause it takes 1 second to reflect the change
    await new Promise((res) => setTimeout(res, 2000));
  }

  if (Platform.OS === 'web') {
    return null;
  }

  // return { entitlements: { all: { premium: { identifier: 'premium', isActive: true, expirationDate: '2024-03-16T23:52:09.000Z' } }, active: { premium: { identifier: 'premium', isActive: true, expirationDate: '2024-03-16T23:52:09.000Z' } } }, managementURL: 'http://test.com' };

  try {
    const customerInfo = await Purchases.getCustomerInfo();

    return customerInfo;
  } catch (e) {
    console.log('failed to get subscription', e);
    return null
  }
};
