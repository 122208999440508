import React from 'react';
import { TouchableOpacity, View, StyleSheet, LayoutAnimation } from 'react-native';
import { result } from 'lodash';

import Text from './Text';
import theme from '../theme';
import { EASE } from '../services/animation';

const Toggle = ({ label, onChange, value = false, style, error, disabled, showText }) => {
  const rootStyles = value ? styles.active : result(style, 'inactive') || styles.inactive;
  const contentStyles = value ? styles.contentActive : styles.contentInactive;

  const handleChange = () => {
    LayoutAnimation.configureNext(EASE);
    onChange(!value);
  };

  return (
    <View style={[styles.root, result(style, 'root')]}>
      <View style={styles.inner}>
        {label && (
          <Text variant="formLabel" style={styles.label}>
            {label}
          </Text>
        )}
        <TouchableOpacity
          style={[styles.toggle, rootStyles]}
          onPress={handleChange}
          disabled={!!disabled}
        >
          {showText && value && <Text style={[styles.text, styles.on]}>on</Text>}
          <View style={[styles.content, contentStyles]} />
          {showText && !value && <Text style={[styles.text, styles.off]}>off</Text>}
        </TouchableOpacity>
      </View>
      {error && (
        <Text variant="formError" marginBottom={0} customStyles={styles.error}>
          {error}
        </Text>
      )}
    </View>
  );
};

Toggle.defaultProps = {
  showText: true,
};

const styles = StyleSheet.create({
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    flex: 1,
  },
  toggle: {
    width: 48,
    height: 26,
    padding: 4,
    borderRadius: 12,
  },
  content: {
    width: '50%',
    height: '100%',
    borderRadius: 10,
  },
  active: {
    backgroundColor: theme.color.white,
  },
  inactive: {
    backgroundColor: theme.color.black,
  },
  contentActive: {
    backgroundColor: theme.color.red,
    marginLeft: 'auto',
  },
  contentInactive: {
    backgroundColor: theme.color.grayLight,
    marginRight: 'auto',
  },
  error: {
    marginTop: theme.spacing(0.5),
  },
  text: {
    position: 'absolute',
    fontSize: 11,
    lineHeight: 19,
    fontWeight: 'bold',
  },
  on: {
    color: theme.color.black,
    left: 6,
    top: 3,
  },
  off: {
    color: theme.color.white,
    top: 3,
    left: 27,
  },
});

export default Toggle;
