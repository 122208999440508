import * as Location from 'expo-location';
import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { result } from 'lodash';

import watchAnalytics from './analytics';
import watchBlacklist from './blacklist';
import watchGuest from './guest';
import watchPlayer from './player';
import watchPlaylist from './playlist';
import watchMerch from './merch';
import handleMiddleware from '../middleware';
import LoginService from '../../services/login';
import HostService from '../../services/host';
import * as constants from '../../constants';
import { selectHost } from '../../selectors';
import { hideHostModeNotification, showHostModeNotification } from '../../services/notification';
import { getCurrentLocation } from '../../services/location';

function* loginWithSpotifyWorker({ payload }) {
  return yield call(LoginService.loginWithSpotify, payload);
}

function* saveCurrentLocationWorker() {
  const location = yield call(getCurrentLocation);
  if (location) {
    const { latitude: lat, longitude: lng } = location;
    return yield call(HostService.saveLocation, {
      lat,
      lng,
    });
  }
}

function* saveSettingsWorker({ payload }) {
  return yield call(HostService.saveSettings, payload);
}

function* updateHostModeWorker({ payload }) {
  try {
    yield put({ type: constants.API_UPDATE_HOST_MODE_REQUEST, payload });
    const { data } = yield call(HostService.updateMode, payload);
    yield put({ type: constants.API_UPDATE_HOST_MODE_SUCCESS, payload: data });
    yield call(payload ? showHostModeNotification: hideHostModeNotification);
  } catch (error) {
    yield put({
      type: constants.API_UPDATE_HOST_MODE_FAILURE,
      payload: !payload,
    });
  }
}

function* getHostWorker() {
  try {
    yield put({ type: constants.API_GET_HOST_REQUEST });
    const { data } = yield call(HostService.get);
    yield put({ type: constants.API_GET_HOST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: constants.API_GET_HOST_FAILURE });
  }
}

export function* getHostId() {
  const { data } = yield select(selectHost);
  return result(data, 'host.id');
}


export default function* watchAll() {
  yield all([
    takeEvery(constants.API_LOGIN_WITH_SPOTIFY, handleMiddleware, loginWithSpotifyWorker),
    takeEvery(constants.API_SAVE_CURRENT_LOCATION, handleMiddleware, saveCurrentLocationWorker),
    takeEvery(constants.API_SAVE_SETTINGS, handleMiddleware, saveSettingsWorker),
    takeEvery(constants.API_UPDATE_HOST_MODE, updateHostModeWorker),
    takeEvery(constants.API_GET_HOST, getHostWorker),
    watchAnalytics(),
    watchBlacklist(),
    watchGuest(),
    watchPlayer(),
    watchPlaylist(),
    watchMerch(),
  ]);
}
