import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, ScrollView, View, LayoutAnimation } from 'react-native';
import { find, filter, result, debounce } from 'lodash';
import { useNavigation } from '@react-navigation/native';

import HostService from '../../services/host';
import ImagePicker from '../ImagePicker';
import CoverageRadius from './CoverageRadius';
import ContentWrapper from '../ContentWrapper';
import Toggle from '../Toggle';
import TextInput from '../TextInput';
import Text from '../Text';
import Button from '../Button';
import theme from '../../theme';
import Options from '../Options';
import FormSection from './FormSection';
import QrCode from './QrCode';
import * as validation from '../../helpers/validation';
import { downloadMerch, logoutStream, logoutUser, saveSettings } from '../../actions';
import { selectHost } from '../../selectors';
import { t } from '../../services/i18n';
import { LOCATION_MODES, MODES } from '../../constants';
import { EASE } from '../../services/animation';
import { useSubscription } from '../../hooks/useSubscription';
import MapPicker from '../MapPicker';
import { getCurrentLocation } from '../../services/location';

const modes = {
  [MODES.INSTANT]: t('text_mode_instant'),
  [MODES.PLAYLIST]: t('text_mode_playlist'),
};

const locationModes = {
  [LOCATION_MODES.CURRENT]: t('text_location_mode_current'),
  [LOCATION_MODES.FIXED]: t('text_location_mode_fixed'),
};

const SettingsForm = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const { data } = useSelector(selectHost);
  const email = result(data, 'host.id');
  const spotifyId = result(data, 'host.spotifyId');
  const settings = result(data, 'host.settings', {});
  const [allowAdd, setAllowAdd] = useState(settings.allowAdd);
  const [allowAllGenres, setAllowAllGenres] = useState(settings.allowAllGenres);
  const [maxDuration, setMaxDuration] = useState(String(settings.maxDuration));
  const [allowOnlyJoinQr, setAllowOnlyJoinQr] = useState(settings.allowOnlyJoinQr);
  const [allowExplicitContent, setAllowExplicitContent] = useState(settings.allowExplicitContent);
  const [amountOfSongs, setAmountOfSongs] = useState(settings.amountOfSongs);
  const [displayPicture, setDisplayPicture] = useState(
    HostService.getHostImageUrl(settings.displayPicture, true),
  );
  const [initialLocation, setInitialLocation] = useState({});
  const [mode, setMode] = useState(settings.mode);
  const [name, setName] = useState(settings.name);
  const [notifications, setNotifications] = useState(settings.notifications);
  const [location, setLocation] = useState(settings.location || { current: true, lat: 0, lng: 0 });
  const [radius, setRadius] = useState(settings.radius);
  const [modeToggle, setModeToggle] = useState(settings.mode === MODES.INSTANT);
  const [errors, setErrors] = useState([]);
  const { subscription } = useSubscription();
  
  const isActive = result(subscription, 'entitlements.active.premium.isActive', false);

  useEffect(() => {
    if (!location.lat) {
      getCurrentLocation().then((loc) => {
        setInitialLocation(loc);
      });
    }
  }, []);

  useEffect(() => {
    LayoutAnimation.configureNext(EASE);
    setMode(modeToggle ? MODES.INSTANT : MODES.PLAYLIST);
  }, [modeToggle]);

  const validationRules = {
    name: value => [
      {
        validate: () => validation.required(value),
        failure: () => {
          setName('');
          addError('name', t('error_message_required_field'));
        },
      },
    ],
    maxDuration: value => [
      {
        validate: () => validation.max(value, 15) && validation.min(value, 6),
        failure: () => {
          setName('');
          addError('name', t('error_message_maximum_duration_field'));
        },
      },
    ],
  };

  const handleSave = useMemo(() => {
    const handleOnSave = (attribute, value) => {
      cleanError(attribute);
      const rules = validationRules[attribute];
      if (rules) {
        for (const { validate, failure } of rules(value)) {
          if (!validate()) {
            failure();
            return;
          }
        }
      }
      dispatch(saveSettings({ [attribute]: value }));
    };
    return debounce(handleOnSave, 500);
  }, [settings]);

  const addError = (attribute, message) => {
    setErrors([
      ...errors,
      {
        attribute,
        message,
      },
    ]);
  };

  const cleanError = attribute => {
    setErrors(filter([...errors], error => error.attribute !== attribute));
  };

  const getError = attribute => {
    const error = find(errors, error => error.attribute === attribute);
    if (error) {
      return error.message;
    }
  };

  const handleGoToBlacklist = () => {
    navigation.navigate('Blacklist');
  };

  const handleStreamingLogout = () => {
    dispatch(logoutStream());
    navigation.navigate('StreamLogIn');
  };

  const handleAccountLogout = () => {
    dispatch(logoutUser());
  };

  const handleDownloadMerch = () => {
    dispatch(downloadMerch());
  };

  return (
    <View style={styles.root}>
      <ScrollView style={styles.scroll}>
        <ContentWrapper style={styles.inner}>
          <Text variant="title">{t('screen_title_settings')}</Text>
          <FormSection title={t('field_name')} hint={t('hint_name')}>
            <TextInput
              value={name}
              onChange={value => {
                setName(value);
                handleSave('name', value);
              }}
              error={getError('name')}
            />
          </FormSection>

          <FormSection title={t('field_logo')} hint={t('hint_logo')}>
            <ImagePicker
              image={displayPicture}
              setImage={value => {
                setDisplayPicture(value);
                handleSave('displayPicture', value);
              }}
            />
          </FormSection>

          <FormSection title={t('field_location')} hint={t('hint_location')}>
            <Options
              initialValue={location.current ? LOCATION_MODES.CURRENT : LOCATION_MODES.FIXED}
              onChange={value => {
                const newLocation = {
                  current: value === LOCATION_MODES.CURRENT,
                  lat: location.lat || initialLocation.latitude,
                  lng: location.lng || initialLocation.longitude,
                };
                setLocation(newLocation);
                handleSave('location', newLocation);
              }}
              options={[
                { label: locationModes[LOCATION_MODES.CURRENT], value: LOCATION_MODES.CURRENT },
                { label: locationModes[LOCATION_MODES.FIXED], value: LOCATION_MODES.FIXED },
              ]}
            />
            {!location.current ? (
              <MapPicker height={300} lat={location.lat || initialLocation.latitude} lng={location.lng || initialLocation.longitude} onChange={(loc) => {
                const newLocation = {
                  current: false,
                  lat: loc.lat,
                  lng: loc.lng,
                };
                setLocation(newLocation);
                handleSave('location', newLocation);
              }} />
            ) : null}
          </FormSection>

          <FormSection title={t('field_coverage_radius')} hint={t('hint_coverage_radius')}>
            <CoverageRadius
              value={radius}
              buttons={[20, 40, 60, 80, 100]}
              handlePress={value => {
                setRadius(value);
                handleSave('radius', value);
              }}
            />
          </FormSection>

          <FormSection title={t('field_mode')} hint={t('hint_modes')}>
            <Options
              initialValue={modeToggle ? MODES.INSTANT : MODES.PLAYLIST}
              onChange={value => {
                setModeToggle(value === MODES.INSTANT);
                handleSave('mode', value);
              }}
              options={[
                { label: modes[MODES.INSTANT], value: MODES.INSTANT },
                { label: modes[MODES.PLAYLIST], value: MODES.PLAYLIST },
              ]}
            />
          </FormSection>

          {mode === MODES.PLAYLIST ? (
            <FormSection title={t('field_add_songs')} hint={t('hint_allow_add')}>
              <Toggle
                value={allowAdd}
                onChange={value => {
                  setAllowAdd(value);
                  handleSave('allowAdd', value);
                }}
                label={t('field_allow_add')}
              />
              {allowAdd && (
                <>
                  <Toggle
                    style={{ root: styles.spacing }}
                    value={allowExplicitContent}
                    onChange={value => {
                      setAllowExplicitContent(value);
                      handleSave('allowExplicitContent', value);
                    }}
                    label={t('field_explicit')}
                  />
                  <Toggle
                    style={{ root: styles.spacing }}
                    value={allowAllGenres}
                    onChange={value => {
                      setAllowAllGenres(value);
                      handleSave('allowAllGenres', value);
                    }}
                    showText={false}
                    label={
                      allowAllGenres
                        ? t('field_allow_add_all_genres')
                        : t('field_allow_add_playlist_genres')
                    }
                  />
                  <TextInput
                    style={{ root: styles.spacing }}
                    label={t('field_max_duration')}
                    value={maxDuration}
                    type="numeric"
                    onChange={value => {
                      const duration = Number(value);
                      if (!isNaN(duration)) {
                        setMaxDuration(duration);
                        handleSave('maxDuration', duration);
                      }
                    }}
                    error={getError('name')}
                  />
                </>
              )}
            </FormSection>
          ) : (
            <FormSection title={t('field_candidates')} hint={t('hint_candidates')}>
              <Options
                initialValue={amountOfSongs}
                onChange={value => {
                  setAmountOfSongs(value);
                  handleSave('amountOfSongs', value);
                }}
                options={[2, 4, 6, 8].map(amount => ({
                  label: t('label_amount_of_songs', { amount: String(amount) }),
                  value: String(amount),
                }))}
              />
            </FormSection>
          )}

          <FormSection title={t('field_notifications')}>
            <Toggle
              value={notifications}
              onChange={value => {
                setNotifications(value);
                handleSave('notifications', value);
              }}
              label={t('field_allow_receive_notifications')}
            />
          </FormSection>

          <FormSection title={t('field_black_list')}>
            <Button title={t('action_see_blacklist')} onPress={handleGoToBlacklist} />
          </FormSection>

          <FormSection title={t('field_host_qr_code')} hint={t('hint_host_qr_code')}>
            <Toggle
              value={allowOnlyJoinQr}
              onChange={value => {
                setAllowOnlyJoinQr(value);
                handleSave('allowOnlyJoinQr', value);
              }}
              label={t('field_only_allow_join_from_qr')}
              style={{ root: styles.spacingBelow }}
            />
            <QrCode hostId={email} />
            {isActive ? (
              <Button title={t('action_download_merch')} onPress={handleDownloadMerch} />
            ) : false}
          </FormSection>

          <FormSection title={t('field_streaming_session')} hint={t('hint_streaming_session')}>
            {spotifyId && (
              <TextInput value={spotifyId} readOnly style={{ root: { marginBottom: 10 } }} />
            )}
            <Button title={t('action_streaming_logout')} onPress={handleStreamingLogout} />
          </FormSection>

          <FormSection title={t('field_account_session')} hint={t('hint_account_session')}>
            {email && <TextInput value={email} readOnly style={{ root: { marginBottom: 10 } }} />}
            <Button title={t('action_account_logout')} onPress={handleAccountLogout} />
          </FormSection>
        </ContentWrapper>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.black.bg1,
    flex: 1,
  },
  inner: {
    paddingTop: theme.spacing(1),
  },
  spacing: {
    marginTop: theme.spacing(0.75),
  },
  spacingBelow: {
    marginBottom: theme.spacing(0.75),
  },
});

export default SettingsForm;
