import React from 'react';
import { View, TouchableOpacity, StyleSheet, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { selectInAppPurchase } from '../../selectors';
import Loading from '../Loading';
import Icon from '../Icon';
import { t } from '../../services/i18n';
import theme from '../../theme';
import { inAppPurchaseStartCheckoutSubscription } from '../../actions';

const HandlingSubscription = ({ reload }) => {
  const { loading, subscriptions } = useSelector(selectInAppPurchase);
  const dispatch = useDispatch();

  if (loading) {
    return <Loading />;
  }

  const monthly = subscriptions.find(s => s.identifier === '$rc_monthly');
  const yearly = subscriptions.find(s => s.identifier === '$rc_annual');
  
  const handleClick = (identifier) => {
    dispatch(inAppPurchaseStartCheckoutSubscription({ identifier, cb: reload }));
  };
  
  return (
    <View style={styles.root}>
      <View style={styles.bullet}>
        <Icon icon="check-box" color="spotify" size={30} />
        <Text style={styles.bulletText}>{t('text_subscription_bullet_1')}</Text>
      </View>
      <View style={styles.bullet}>
        <Icon icon="check-box" color="spotify" size={30} />
        <Text style={styles.bulletText}>{t('text_subscription_merch')}</Text>
      </View>
      {monthly ? (
        <TouchableOpacity style={[styles.button, styles.monthly]} onPress={() => handleClick(monthly.product.identifier)}>
          <Text style={styles.buttonSubtitle}>{t('text_subscription_active_title')}</Text>
          <Text style={styles.buttonTitle}>{t('text_subscription_active_monthly')}</Text>
          <View style={styles.buttonPriceContainer}>
            <Text style={styles.buttonPrice}>{monthly.product.priceString}</Text>
            <Text style={styles.buttonPriceBy}>{t('text_subscription_active_per_month')}</Text>
          </View>
        </TouchableOpacity>
      ) : null}
      {yearly ? (
        <TouchableOpacity style={[styles.button, styles.yearly]} onPress={() => handleClick(yearly.product.identifier)}>
          <Text style={styles.buttonSubtitle}>{t('text_subscription_active_title')}</Text>
          <Text style={styles.buttonTitle}>{t('text_subscription_active_yearly')}</Text>
          {monthly ? <Text style={styles.buttonOldPrice}>{monthly.product.priceString}</Text> : null}
          <View style={styles.buttonPriceContainer}>
            <Text style={styles.buttonPrice}>{yearly.product.priceString}</Text>
            <Text style={styles.buttonPriceBy}>{t('text_subscription_active_per_year')}</Text>
          </View>
        </TouchableOpacity>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  bullet: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  bulletText: {
    color: theme.color.white,
    fontSize: 18,
    marginLeft: 2,
  },
  button: {
    marginBottom: theme.spacing(1),
    borderRadius: 10,
    padding: theme.spacing(0.5),
    alignItems: 'center',
  },
  monthly: {
    backgroundColor: theme.color.blue,
  },
  yearly: {
    backgroundColor: theme.color.blue,
  },
  buttonTitle: {
    color: theme.color.white,
    fontSize: 18,
  },
  buttonSubtitle: {
    color: theme.color.white,
    fontSize: 22,
  },
  buttonOldPrice: {
    textDecorationLine: 'line-through',
    color: theme.color.white,
    opacity: 0.8,
  },
  buttonPriceContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  buttonPrice: {
    color: theme.color.white,
    fontSize: 22,
    marginRight: 5,
  },
  buttonPriceBy: {
    color: theme.color.white,
    fontSize: 14,
  },
});

export default HandlingSubscription;
