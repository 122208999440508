import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { size } from 'lodash';

import ContentWrapper from '../../components/ContentWrapper';
import Confetti from '../../components/Confetti';
import InstantOptions from '../../components/guest/InstantOptions/InstantOptions';
import Layout from '../../components/Layout';
import Player from '../../components/Player';
import PlaylistOptions from '../../components/guest/PlaylistOptions/PlaylistOptions';
import TextIcon from '../../components/TextIcon';
import SkeletonWrapper from '../../components/skeleton/SkeletonWrapper';
import skeleton from '../../helpers/skeleton';
import * as analytics from '../../services/analytics';
import * as refreshService from '../../services/refresh';
import { closeHost, getHostStatus } from '../../actions';
import { MODES, REFRESH_SERVICES } from '../../constants';
import {
  selectCurrentTrack,
  selectedHost,
  selectSendingVoteStatus,
  selectOptions,
  selectVotingStatus,
  selectLoadingTracks,
  selectUser,
} from '../../selectors';
import { t } from '../../services/i18n';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import { openModal } from '../../services/modal';

const CurrentHost = ({ navigation }) => {
  const dispatch = useDispatch();
  const [hostLoaded, setHostLoaded] = useState(false);
  const currentTrack = useSelector(selectCurrentTrack);
  const options = useSelector(selectOptions);
  const sendingVote = useSelector(selectSendingVoteStatus);
  const host = useSelector(selectedHost) || {};
  const votingStatus = useSelector(selectVotingStatus);
  const loadingTracks = useSelector(selectLoadingTracks);
  const user = useSelector(selectUser);
  const { id: hostId, name, mode, amountOfSongs, allowExplicitContent, allowAdd } = host;

  useScreenFocus(() => {
    analytics.screen('currentHost');
    refresh();
  }, hostLoaded);

  useEffect(() => {
    if (hostId) {
      refresh(true);
      setHostLoaded(true);
      refreshService.start(REFRESH_SERVICES.GET_HOST_TRACK, refresh, 15000);
      return () => {
        refreshService.stop(REFRESH_SERVICES.GET_HOST_TRACK);
        dispatch(closeHost());
      };
    }
  }, [hostId]);

  const refresh = firstTime => {
    dispatch(getHostStatus({ firstTime, hostId }));
  };

  const onHandleSearch = () => {
    navigation.navigate('SearchTracks', { hostId, allowExplicitContent });
  };

  const addAction = allowAdd ? { icon: 'add', onPress: onHandleSearch } : null;

  const handlePaidPress = paid => {
    const modalProps =
      paid !== user?.email
        ? {
            title: t('text_dialog_paid_explanation_title'),
            content: t('text_dialog_paid_explanation_instructions'),
          }
        : {
            title: t('text_dialog_paid_explanation_title_2'),
            content: t('text_dialog_paid_explanation_instructions_2'),
          };
    openModal('paidSong', modalProps);
  };

  return (
    <Layout title={name} includeCredit>
      {loadingTracks ? (
        <>
          <SkeletonWrapper {...skeleton.guestPlayer} repeat={1} />
          {mode === MODES.INSTANT ? (
            <SkeletonWrapper
              {...skeleton.instant}
              repeat={amountOfSongs === 2 ? amountOfSongs : amountOfSongs / 2}
              columns={amountOfSongs === 2 ? 1 : 2}
            />
          ) : (
            <SkeletonWrapper {...skeleton.playlist} repeat={10} />
          )}
        </>
      ) : currentTrack ? (
        <>
          <Confetti />
          <Player {...currentTrack} />
          {mode === MODES.INSTANT ? (
            <ContentWrapper style={styles.content}>
              {size(options) ? (
                <InstantOptions
                  hostId={hostId}
                  options={options}
                  sendingVote={sendingVote}
                  votingStatus={votingStatus}
                  handlePaidPress={handlePaidPress}
                />
              ) : null}
            </ContentWrapper>
          ) : (
            <ContentWrapper style={styles.content}>
              {size(options) ? (
                <PlaylistOptions
                  hostId={hostId}
                  options={options}
                  sendingVote={sendingVote}
                  votingStatus={votingStatus}
                  addAction={addAction}
                  handlePaidPress={handlePaidPress}
                />
              ) : null}
            </ContentWrapper>
          )}
        </>
      ) : (
        <TextIcon text={t('text_host_not_playing')} icon="error-outline" />
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: 10,
  },
  text: {
    textAlign: 'center',
    fontSize: 18,
  },
  content: {
    margin: 10,
    flex: 1,
  },
});

export default CurrentHost;
