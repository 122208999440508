import React, { useState } from 'react';
import { Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { result } from 'lodash';

import ScannerMobile from '../../components/mobile/Scanner';
import ScannerWeb from '../../components/web/Scanner';
import * as analytics from '../../services/analytics';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import { t } from '../../services/i18n';
import { isValidDeepLink, parseDeepLink } from '../../services/linking';
import { joinHost } from '../../actions';
import { openModal } from '../../services/modal';

export default function Scanner({ navigation, route }) {
  const [scanned, setScanned] = useState(false);
  const dispatch = useDispatch();
  const preselectedHost = result(route, 'params.preselectedHost');

  useScreenFocus(() => {
    analytics.screen('scanner');
  });

  const handleInvalidReading = () => {
    openModal('invalidQR', {
      title: t('error_message_invalid_qr'),
      icon: {
        name: 'qrcode',
      },
      buttons: [
        {
          text: t('action_ok'),
          onPress: ({ close }) => {
            setScanned(false);
            close();
          },
        },
      ],
    });
  };

  const handleJoinAnotherHost = hostId => {
    openModal('joinAnotherHost', {
      title: t('warning_message_another_host'),
      icon: {
        name: 'qrcode',
      },
      buttons: [
        {
          text: t('action_yes'),
          onPress: ({ close }) => {
            join(hostId);
            close();
          },
        },
        {
          text: t('action_no'),
          onPress: ({ close }) => {
            close();
            setScanned(false);
          },
        },
      ],
    });
  };

  const join = id => {
    setScanned(false);
    navigation.goBack();
    dispatch(
      joinHost({
        id,
        from: 'scanner',
      }),
    );
  };

  const handleBarCodeScanned = data => {
    setScanned(true);
    if (isValidDeepLink(data)) {
      const parsed = parseDeepLink(data);
      if (parsed) {
        const hostId = result(parsed, 'id');
        if (preselectedHost && hostId !== preselectedHost) {
          handleJoinAnotherHost(hostId);
        } else {
          join(hostId);
        }
      } else {
        handleInvalidReading();
      }
    } else {
      handleInvalidReading();
    }
  };

  const handleCancel = () => {
    navigation.goBack();
  };

  const ScannerComponent = Platform.OS === 'web' ? ScannerWeb : ScannerMobile;

  return (
    <ScannerComponent
      scanned={scanned}
      handleBarCodeScanned={handleBarCodeScanned}
      handleCancel={handleCancel}
    />
  );
}
