import React from 'react';
import { View, StyleSheet } from 'react-native';
import { map } from 'lodash';

import Playlist from './Playlist';

const PlaylistsWrapper = ({ playlists }) => {
  return (
    <View style={styles.root}>
      {map(playlists, playlist => (
        <Playlist key={playlist.id} {...playlist} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {},
});

export default PlaylistsWrapper;
