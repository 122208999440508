import React, { useRef, useEffect } from 'react';
import { Text, StyleSheet, ImageBackground, TouchableOpacity, Animated, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesome5 } from '@expo/vector-icons';

import Button from '../../Button';
import theme from '../../../theme';
import { askToUseCredit, sendVote } from '../../../actions';
import { t } from '../../../services/i18n';
import { selectCurrentTrack } from '../../../selectors';

const MAX = 45;

const Option = ({
  hostId,
  elected,
  uri,
  name,
  artist,
  image,
  votes,
  paid,
  expanded,
  onPress,
  handlePaidPress,
  disabled,
}) => {
  const dispatch = useDispatch();
  const currentTrack = useSelector(selectCurrentTrack);
  const expandedAnim = useRef(new Animated.Value(MAX)).current;

  useEffect(() => {
    Animated.timing(expandedAnim, {
      toValue: expanded ? 0 : MAX,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [expandedAnim, expanded]);

  const handlePress = () => {
    if (paid) {
      handlePaidPress(paid);
    } else {
      dispatch(sendVote({ hostId, uri }));
    }
    onPress();
  };

  const handleLongPress = () => {
    if (paid) {
      handlePaidPress(paid);
    } else {
      dispatch(askToUseCredit({ hostId, uri }));
    }
    onPress();
  };

  const source = { uri: image };

  const songName = expanded ? name + '\n' + artist : name;
  
  return (
    <TouchableOpacity onPress={onPress} style={styles.root} disabled={disabled}>
      <ImageBackground source={source} style={[styles.image, disabled ? styles.imageDisabled : null]}>
        <View style={styles.votes}>
          {paid ? (
            <FontAwesome5 style={styles.textVotes} name="dollar-sign" size={12} />
          ) : (
            <Text style={styles.textVotes}>{votes || '0'}</Text>
          )}
        </View>
        <Animated.View
          style={[
            styles.animContainer,
            elected || (paid && uri !== currentTrack.uri) ? styles.elected : null,
            { top: expandedAnim },
          ]}
        >
          <Text style={styles.text}>{songName}</Text>
          <View style={styles.buttons}>
            <Button
              title={t('action_play')}
              color="tertiary"
              variant="tight"
              style={styles.button}
              onPress={handleLongPress}
            />
            <Button
              title={t('action_vote')}
              color="primary"
              variant="tight"
              style={styles.button}
              onPress={handlePress}
            />
          </View>
        </Animated.View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
    marginVertical: 10,
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 10,
  },
  text: {
    color: '#FFF',
    padding: 10,
  },
  votes: {
    backgroundColor: theme.color.red,
    minWidth: 25,
    height: 25,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 10,
    right: 10,
    paddingHorizontal: 5,
    elevation: 1,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
  },
  textVotes: {
    color: 'white',
    textAlign: 'center',
    textAlignVertical: 'center',
    lineHeight: 18,
    fontWeight: 'bold',
  },
  image: {
    flex: 1,
    alignSelf: 'stretch',
    alignContent: 'center',
    justifyContent: 'flex-end',
    height: '100%',
  },
  imageDisabled: {
    opacity: 0.5,
  },
  animContainer: {
    backgroundColor: '#00000080',
  },
  elected: {
    backgroundColor: theme.color.blue,
  },
  buttons: {
    flexDirection: 'row',
    width: '100%',
  },
  button: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 0,
    borderRadius: 8,
    margin: 5,
    marginBottom: 6,
  },
});

export default Option;
