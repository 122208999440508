import React from 'react';
import { View, StyleSheet } from 'react-native';
import { result } from 'lodash';
import { RadioButton } from 'react-native-paper';

import Text from './Text';
import theme from '../theme';

const Options = ({ options = [], label, onChange, initialValue, style, error }) => (
  <View style={[styles.root, result(style, 'root')]}>
    {label && (
      <Text variant="formLabel" style={styles.label}>
        {label}
      </Text>
    )}
    {error && (
      <Text variant="formError" marginBottom={0} customStyles={styles.error}>
        {error}
      </Text>
    )}
    <View style={styles.group}>
      <RadioButton.Group onValueChange={onChange} value={initialValue}>
        {options.map(option => (
          <RadioButton.Item
            key={option.value}
            color="white"
            uncheckedColor="#FFFFFF50"
            style={styles.item}
            label={option.label}
            value={option.value}
            labelStyle={styles.labelItem}
          />
        ))}
      </RadioButton.Group>
    </View>
  </View>
);

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  label: {
    marginBottom: 5,
  },
  labelItem: {
    color: theme.black.text,
  },
  group: {},
  item: {
    backgroundColor: theme.color.red,
    padding: 0,
    marginBottom: 10,
    borderRadius: 10,
    flex: 1,
    flexDirection: 'row',
    marginRight: 5,
  },
  error: {
    marginTop: theme.spacing(0.5),
  },
});

export default Options;
