import React from 'react';
import { Linking, Platform } from 'react-native';

import Text from './Text';

const goTo = (url, fallbackUrl) => {
  Linking.openURL(url).catch(() => {
    if (fallbackUrl) {
      goTo(fallbackUrl);
    }
  });
};

const Link = ({ url, fallbackUrl, component: Component, ...props }) => {
  return Platform.OS === 'web' ? (
    <Text {...props} href={url} accessibilityRole="link" target="_blank">
      <Component />
    </Text>
  ) : (
    <Component {...props} onClick={() => goTo(url, fallbackUrl)} />
  );
};

export default Link;
