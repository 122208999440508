import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { result } from 'lodash';
import { useNavigation } from '@react-navigation/native';

import Text from '../../components/Text';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import theme from '../../theme';
import ContentWrapper from '../../components/ContentWrapper';
import * as analytics from '../../services/analytics';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import { t } from '../../services/i18n';
import { logoutUser, removeAccount } from '../../actions';
import FormSection from '../../components/host/FormSection';
import { openModal } from '../../services/modal';

const Credit = ({ route }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  const { handleBack } = result(route, 'params', {});

  useScreenFocus(() => {
    analytics.screen('credit');
  });

  const handleAccountRemoval = () => {
    openModal('removeAccount', {
      title: t('text_modal_title_account_removal'),
      content: t('text_modal_subtitle_account_removal'),
      buttons: [
        {
          text: t('action_ok'),
          onPress: () => {
            dispatch(removeAccount());
            setLoading(true);
          },
        },
        {
          text: t('action_cancel'),
          onPress: ({ close }) => close(),
          variant: 'secondary',
        },
      ],
    });
  };

  const handleCredit = () => {
    navigation.navigate('Credit');
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <Layout
      title={t('screen_title_account')}
      handleBack={handleBack}
    >
      <ContentWrapper style={styles.root}>
        <FormSection title={t('field_account_credits')}>
          <Button color="tertiary" title={t('action_my_credit')} onPress={handleCredit} />
        </FormSection>
        <FormSection title={t('field_account_logout')}>
          <Button title={t('action_logout')} onPress={handleLogout} />
        </FormSection>
        <FormSection title={t('field_account_removal')}>
          <Text>{t('field_account_removal_warning')}</Text>
          <Button
            loading={loading}
            color="youtube"
            title={t('action_account_removal')}
            onPress={handleAccountRemoval}
          />
        </FormSection>
      </ContentWrapper>
    </Layout>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: theme.spacing(1),
  },
});

export default Credit;
