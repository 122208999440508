import { delay, put, all, takeLatest } from 'redux-saga/effects';

import * as constants from '../constants';

function* handleShowConfetti(action) {
  yield put({
    type: constants.SET_CONFETTI_STATUS,
    payload: {
      on: true,
      origin: action.payload,
    },
  });
  yield delay(3000);
  yield put({
    type: constants.SET_CONFETTI_STATUS,
    payload: {
      on: false,
    },
  });
}

function* handleHideConfetti() {
  yield put({
    type: constants.SET_CONFETTI_STATUS,
    payload: {
      on: false,
    },
  });
}

export default function* watchAll() {
  yield all([takeLatest(constants.SHOW_CONFETTI, handleShowConfetti)]);
  yield all([takeLatest(constants.HIDE_CONFETTI, handleHideConfetti)]);
}
