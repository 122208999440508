import React from 'react';
import { ScrollView, StyleSheet, View, Platform } from 'react-native';

import WebRefreshControl from './web/RefreshControl';
import RefreshControl from './RefreshControl';

const RefreshView = ({ style, onRefresh, refreshing, children, backgroundColor, title }) => {
  return (
    <View style={[styles.root, style, { backgroundColor }]}>
      {Platform.OS === 'web' ? (
        <View style={styles.header}>
          {title}
          <WebRefreshControl
            onRefresh={onRefresh}
            refreshing={refreshing}
            style={title ? { flex: 1 } : null}
          />
        </View>
      ) : (
        title
      )}
      <ScrollView
        contentContainerStyle={styles.container}
        refreshControl={
          Platform.OS !== 'web' && <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
        }
      >
        <View style={styles.inner}>{children}</View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    height: '100%',
  },
  container: {
    flexGrow: 1,
  },
  inner: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
  },
});

export default RefreshView;
