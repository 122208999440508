import * as constants from './constants';

const actionCreator = type => {
  return payload => ({
    type,
    payload,
  });
};

// Global actions
export const loadServerConfig = actionCreator(constants.API_SERVER_CONFIG);
export const handleAccessToken = actionCreator(constants.HANDLE_ACCESS_TOKENS);
export const setUser = actionCreator(constants.SET_USER);
export const setInAppPurchaseProducts = actionCreator(constants.SET_IAP_PRODUCTS);
export const setInAppPurchaseSubscriptions = actionCreator(constants.SET_IAP_SUBSCRIPTIONS);
export const setInAppPurchaseLoadingCheckout = actionCreator(constants.SET_IAP_LOADING_CHECKOUT);
export const inAppPurchaseStartCheckoutProduct = actionCreator(constants.IAP_START_CHECKOUT_PRODUCT);
export const inAppPurchaseFinishCheckoutProduct = actionCreator(constants.IAP_FINISH_CHECKOUT_PRODUCT);
export const inAppPurchaseStartCheckoutSubscription = actionCreator(constants.IAP_START_CHECKOUT_SUBSCRIPTION);
export const setMpLoadingCheckout = actionCreator(constants.SET_MP_LOADING_CHECKOUT);
export const mpStartCheckout = actionCreator(constants.MP_START_CHECKOUT);
export const mpCheckCreditStatus = actionCreator(constants.API_MP_CHECK_CREDIT_STATUS);
export const downloadMerch = actionCreator(constants.DOWNLOAD_MERCH);
export const logoutUser = actionCreator(constants.LOGOUT_USER);
export const removeAccount = actionCreator(constants.REMOVE_ACCOUNT);
export const logoutStream = actionCreator(constants.LOGOUT_STREAM);
export const cleanUser = actionCreator(constants.CLEAN_USER);
export const setNetwork = actionCreator(constants.SET_NETWORK);
export const setOnboarded = actionCreator(constants.SET_ONBOARDED);
export const showConfetti = actionCreator(constants.SHOW_CONFETTI);
export const hideConfetti = actionCreator(constants.HIDE_CONFETTI);
export const setPostLoginCallback = actionCreator(constants.SET_POST_LOGIN_CALLBACK);
export const setNotificationWebView = actionCreator(constants.SET_NOTIFICATION_WEB_VIEW);
export const pushNotificationClick = actionCreator(constants.PUSH_NOTIFICAITON_CLICKED);

export const search = {
  tracks: actionCreator(constants.API_SEARCH_TRACKS),
  playlists: actionCreator(constants.API_SEARCH_PLAYLISTS),
};
export const clean = {
  tracks: actionCreator(constants.CLEAN_SEARCH_TRACKS),
};
export const collapsible = {
  expand: actionCreator(constants.SET_COLLAPSE_STATUS),
};

// Host actions
export const loginWithSpotify = actionCreator(constants.API_LOGIN_WITH_SPOTIFY);
export const saveSettings = actionCreator(constants.API_SAVE_SETTINGS);
export const saveLocation = actionCreator(constants.API_SAVE_CURRENT_LOCATION);
export const getTrackInfo = actionCreator(constants.API_GET_TRACK_INFO);
export const playMusic = actionCreator(constants.API_PLAY_MUSIC);
export const pauseMusic = actionCreator(constants.API_PAUSE_MUSIC);
export const nextSong = actionCreator(constants.API_NEXT_SONG);
export const setVolume = actionCreator(constants.API_SET_VOLUME);
export const getPlayerStatus = actionCreator(constants.API_GET_PLAYER_STATUS);
export const getHost = actionCreator(constants.API_GET_HOST);
export const removeTrackInfo = actionCreator(constants.REMOVE_TRACK_INFO);
export const modalOpen = actionCreator(constants.MODAL_OPEN);
export const modalClose = actionCreator(constants.MODAL_CLOSE);
export const modalUpdate = actionCreator(constants.MODAL_UPDATE);
export const getPlaylists = actionCreator(constants.API_GET_PLAYLISTS);
export const cleanPlaylists = actionCreator(constants.CLEAN_PLAYLISTS);
export const setPlaylist = actionCreator(constants.API_IMPORT_PLAYLIST);
export const getTrackList = actionCreator(constants.API_GET_TRACKLIST);
export const cleanTrackList = actionCreator(constants.API_CLEAN_TRACKLIST);
export const addSongs = actionCreator(constants.API_ADD_SONGS);
export const deleteSong = actionCreator(constants.API_DELETE_SONG);
export const getGuests = actionCreator(constants.API_GET_GUESTS);
export const updateHostMode = actionCreator(constants.API_UPDATE_HOST_MODE);
export const allowSong = actionCreator(constants.API_ALLOW_SONG);
export const getBlacklist = actionCreator(constants.API_GET_BLACKLIST);
export const filterPlaylist = actionCreator(constants.FILTER_PLAYLIST);

// Guest actions
export const loadUserInformation = actionCreator(constants.API_GET_USER_INFORMATION);
export const getClosestHosts = actionCreator(constants.API_GET_CLOSEST_HOSTS);
export const joinHost = actionCreator(constants.API_JOIN_HOST);
export const updateHostData = actionCreator(constants.API_UPDATE_HOST_DATA_SUCCESS);
export const getHostStatus = actionCreator(constants.API_GET_HOST_STATUS);
export const sendVote = actionCreator(constants.API_SEND_VOTE);
export const sendCredit = actionCreator(constants.API_SEND_CREDIT);
export const askToUseCredit = actionCreator(constants.ASK_TO_USE_CREDIT);
export const addSong = actionCreator(constants.API_ADD_SONG);
export const updateVotingStatus = actionCreator(constants.UPDATE_VOTING_STATUS);
export const closeHost = actionCreator(constants.CLOSE_HOST);
