import * as constants from '../constants';

const initialState = {
  expand: null,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_COLLAPSE_STATUS: {
      return {
        expand: state.expand === payload ? null : payload,
      };
    }
    case constants.API_SEND_VOTE_SUCCESS:
    case constants.API_SEND_CREDIT_SUCCESS:
    case constants.API_ADD_SONG_SUCCESS:
    case constants.API_ADD_SONGS_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};
