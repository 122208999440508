import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

export const useScreenFocus = (fn, status = true) => {
  const navigation = useNavigation();

  useEffect(() => {
    if (status) {
      return navigation.addListener('focus', fn);
    }
  }, [navigation, status]);
};
