import * as FileSystem from 'expo-file-system';
import { all, call, put, delay, takeLeading } from 'redux-saga/effects';
import { Platform } from 'react-native';
import moment from 'moment';

import * as merchService from '../../services/merch';
import * as constants from '../../constants';
import { info as toastInfo } from '../../helpers/toast';
import { shareFile } from '../../services/sharing';
import { t } from '../../services/i18n';

function* downloadMerch() {
  yield put({ type: constants.DOWNLOAD_MERCH_REQUEST });
  const closeToast = yield call(toastInfo, t('info_message_generating_merch'), { fixed: true });
  try {
    yield call(merchService.generate);
    let status, result;
    let seconds = 0;
    do {
      yield delay(5000);
      result = yield call(merchService.download);
      status = result.status;
      seconds += 5;
    } while (status === 202 && seconds < 60);

    closeToast();

    if (status === 200) {
      yield put({ type: constants.DOWNLOAD_MERCH_SUCCESS });
      if (Platform.OS !== 'web') {
        yield call(shareFile, FileSystem.documentDirectory + `loudy-merch-${moment().valueOf()}.zip`);
      }
    } else {
      yield put({
        type: constants.DOWNLOAD_MERCH_FAILURE,
        payload: t('error_message_generating_merch'),
      });
    }
  } catch (e) {
    closeToast();
    
    yield put({
      type: constants.DOWNLOAD_MERCH_FAILURE,
      payload: t('error_message_generating_merch'),
    });
  }
}

export default function* watchAll() {
  yield all([takeLeading(constants.DOWNLOAD_MERCH, downloadMerch)]);
}
