import React, { useRef } from 'react';
import ViewShot from 'react-native-view-shot';
import { useSelector } from 'react-redux';
import { StyleSheet, View, Platform, Image } from 'react-native';
import { result } from 'lodash';

import Button from '../Button';
import theme from '../../theme';
import { selectHost } from '../../selectors';
import { shareFile } from '../../services/sharing';
import { t } from '../../services/i18n';
import config from '../../config';

const QrCode = ({ hostId }) => {
  const viewShot = useRef(null);

  const handlePress = () => {
    viewShot.current.capture().then(uri => {
      shareFile(uri);
    });
  };
  const uri = `${config.aws.bucketUrl}/qr/${hostId}.png`;

  return (
    <>
      <View style={styles.root}>
        <View style={styles.inner}>
          <ViewShot
            ref={viewShot}
            options={{ format: 'png', quality: 1.0 }}
            style={styles.viewShot}
          >
            <Image source={{ uri }} style={{ width: 200, height: 200 }} />
          </ViewShot>
        </View>
        {Platform.OS !== 'web' && (
          <Button style={styles.button} title={t('action_share')} onPress={handlePress} />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
  },
  inner: {
    backgroundColor: theme.color.white,
    width: 215,
    height: 215,
    marginVertical: theme.spacing(1),
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewShot: {
    backgroundColor: theme.color.white,
  },
  button: {
    marginBottom: 10,
  },
});

export default QrCode;
