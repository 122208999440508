import React from 'react';
import { View, Platform } from 'react-native';
import { SvgXml } from 'react-native-svg';

export default function Base({ xml, onClick, style }) {
  const sizeProps = {};
  if (style && style.width) {
    sizeProps.width = style.width;
  }
  if (style && style.height) {
    sizeProps.height = style.height;
  }

  if (Platform.OS === 'web') {
    return (
      <View onClick={onClick} style={[style, { textAlign: 'center', lineHeight: 0 }]}>
        <div dangerouslySetInnerHTML={{ __html: xml }} {...sizeProps} />
      </View>
    );
  }

  return <SvgXml xml={xml} onPress={onClick} style={style} {...sizeProps} />;
}
