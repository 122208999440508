import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { useNavigation } from '@react-navigation/core';

import { error as toastError } from '../../helpers/toast';
import { t } from '../../services/i18n';
import ScannerLayer from '../ScannerLayer';
import Loading from '../Loading';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: 'black',
  },
});

export default function Scanner({ scanned, handleBarCodeScanned, handleCancel }) {
  const navigation = useNavigation();
  const [hasPermission, setHasPermission] = useState(null);

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  if (hasPermission === null) {
    return <Loading title={t('text_requesting_camera_permission')} />;
  }
  if (hasPermission === false) {
    toastError(t('error_message_no_camera_access'));
    navigation.goBack();
    return null;
  }

  const onBarCodeScanned = result => {
    if (result) {
      handleBarCodeScanned(result.data);
    }
  };

  return (
    <View style={styles.root}>
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : onBarCodeScanned}
        style={{ flex: 1, flexDirection: 'column' }}
      >
        <ScannerLayer handleCancel={handleCancel} />
      </BarCodeScanner>
    </View>
  );
}
