import React from 'react';
import { Image, View } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

import icons from '../services/icons';
import theme from '../theme';

const Icon = ({ external, icon, size, color, style, ...props }) => {
  const iconColor = theme.color[color] || theme.color.white;
  return external ? (
    <Image
      source={icons[icon]}
      style={[
        {
          width: size,
          height: size,
          tintColor: iconColor,
        },
        style,
      ]}
    />
  ) : (
    <View style={style}>
      <MaterialIcons name={icon} size={size} color={iconColor} {...props} />
    </View>
  );
};

export default Icon;
