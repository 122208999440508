import React from 'react';
import { TouchableOpacity, View, StyleSheet, ImageBackground, Dimensions } from 'react-native';

import Text from './Text';
import IconButton from './IconButton';
import Stepper from './Stepper';
import { t } from '../services/i18n';
import Push from './Push';
import theme from '../theme';

const OnboardingLayout = ({ source, text, step, steps, onNext, onSkip }) => {
  return (
    <View style={styles.root}>
      <ImageBackground
        source={source}
        style={styles.bg}
        imageStyle={styles.bgImage}
        fadeDuration={100}
      >
        <Push />
        <View style={styles.top}>
          <Stepper step={step} steps={steps} style={styles.stepper} />
          {onSkip && (
            <TouchableOpacity onPress={onSkip} style={styles.skip}>
              <Text style={styles.skipText}>{t('action_skip')}</Text>
            </TouchableOpacity>
          )}
        </View>

        <Text variant="title" strong style={styles.title}>
          {text}
        </Text>
        <View style={styles.space} />

        <View style={styles.buttonContainer}>
          <IconButton icon="chevron-right" size={32} style={styles.button} onPress={onNext} />
        </View>
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: 'black',
  },
  bg: {
    width: '100%',
    flex: 1,
  },
  bgImage: {
    width: '100%',
    flex: 1,
    height: Dimensions.get('window').height,
  },
  top: {
    minHeight: 40,
    margin: 15,
    marginTop: 15,
    marginBottom: 0,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  space: {
    flex: 1,
  },
  overlay: {
    overflow: 'hidden',
    padding: 20,
    alignItems: 'center',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    minHeight: 150,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  overlayImage: {
    height: Dimensions.get('window').height,
    top: undefined,
  },
  skip: {
    alignSelf: 'center',
    padding: 5,
    paddingHorizontal: 10,
    backgroundColor: '#00000020',

    borderRadius: 5,
  },
  viewMore: {
    padding: 5,
    margin: 5,
  },
  skipText: {
    fontSize: 16,
  },
  stepper: {},
  viewMoreContainer: {
    maxHeight: 35,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 30,
    lineHeight: 40,
    marginTop: 30,
    paddingHorizontal: theme.spacing(1),
    maxWidth: 600,
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
  button: {
    position: 'absolute',
    backgroundColor: theme.color.white,
    right: 15,
    bottom: 15,
    opacity: 0.5,
  },
});

export default OnboardingLayout;
