import React, { forwardRef } from 'react';
import {
  TextInput as RcTextInput,
  Text as RnText,
  StyleSheet,
  View,
  Keyboard,
  Platform,
} from 'react-native';
import { result } from 'lodash';

import Text from './Text';
import theme from '../theme';

const TextInput = forwardRef(
  (
    { lines, onChange, label, value, suffix, error, style, suffixStyle, readOnly, type, ...props },
    ref,
  ) => {
    const renderSuffix = () => {
      if (suffix) {
        if (typeof suffix === 'string') {
          return <RnText style={[styles.suffix, suffixStyle]}>{suffix}</RnText>;
        }
        return suffix;
      }
      return null;
    };
    
    return (
      <View style={[styles.root, result(style, 'root')]} pointerEvents={readOnly ? 'none' : 'auto'}>
        {label && (
          <Text variant="formLabel" style={styles.label}>
            {label}
          </Text>
        )}
        <View style={styles.inner}>
          <RcTextInput
            ref={ref}
            style={[styles.input, result(style, 'input')]}
            numberOfLines={lines}
            onChangeText={text => onChange(text)}
            value={value}
            returnKeyType="done"
            onSubmitEditing={Keyboard.dismiss}
            keyboardType={type}
            {...props}
          />
          {renderSuffix()}
        </View>
        {error && (
          <Text variant="formError" marginBottom={0} customStyles={styles.error}>
            {error}
          </Text>
        )}
      </View>
    );
  },
);

TextInput.defaultProps = {
  type: 'default',
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  inner: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  input: {
    width: Platform.OS === 'web' ? '100%' : undefined,
    flex: 1,
    borderColor: theme.color.grayDark,
    borderWidth: 1,
    backgroundColor: theme.color.grayDark,
    padding: 10,
    paddingTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    textAlignVertical: 'bottom',
    color: theme.color.white,
    ...theme.font.input,
  },
  suffix: {
    color: theme.color.white,
    paddingLeft: 10,
    paddingRight: 10,
  },
  error: {
    marginTop: theme.spacing(0.5),
  },
  label: {
    marginBottom: 10,
  },
});

export default TextInput;
