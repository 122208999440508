import React from 'react';

import SettingsForm from '../../components/host/SettingsForm';
import * as analytics from '../../services/analytics';
import { useScreenFocus } from '../../hooks/useScreenFocus';

const Settings = () => {
  useScreenFocus(() => {
    analytics.tab('settings');
  });

  return (
    <SettingsForm />
  );
};

export default Settings;
