import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { View, StyleSheet, Animated, Dimensions } from 'react-native';
import { useDispatch } from 'react-redux';

import TitleWithOptions from './TitleWithOptions';
import TextInput from './TextInput';
import IconButton from './IconButton';
import { useToggleAnimation } from '../hooks/useToggleAnimation';
import { filterPlaylist } from '../actions';
import theme from '../theme';
import { t } from '../services/i18n';

const screenWidth = Dimensions.get('window').width;

const SearchBox = ({ title, otherActions = [], data, onFilter }) => {
  const dispatch = useDispatch();
  const [searching, setSearching] = useState(false);
  const left = useToggleAnimation(searching, 0, screenWidth);
  const opacity = useToggleAnimation(searching, 0, 1);
  const [filterData, setFilterData] = useState({
    index: 0,
    allMatches: [],
  });

  const setIndex = value => setFilterData(prevState => ({ ...prevState, index: value }));

  const debounced = useMemo(() => {
    const { index } = filterData;
    const handleOnChange = value => {
      const words = value
        .toLowerCase()
        .trim()
        .split(' ')
        .filter(w => w);
      if (words.length === 0) {
        setFilterData({
          index: 0,
          allMatches: [],
        });
        return;
      }
      const matches = [];
      for (let item of data) {
        let matching = true;
        for (let word of words) {
          if (
            item.name.toLowerCase().indexOf(word) === -1 &&
            item.artist.toLowerCase().indexOf(word) === -1
          ) {
            matching = false;
          }
        }
        if (matching) {
          matches.push(item);
        }
      }
      let updatedIndex = index;
      if (index === 0) {
        updatedIndex = 1;
      }
      if (matches.length === 0) {
        updatedIndex = 0;
      }
      if (index > matches.length) {
        updatedIndex = matches.length;
      }
      setFilterData({
        index: updatedIndex,
        allMatches: matches,
      });
    };
    return debounce(handleOnChange, 1000);
  }, [filterData.index]);

  useEffect(() => {
    const { index, allMatches } = filterData;
    const match = allMatches[index - 1];
    const currentMatchIndex = index === 0 || !match ? -1 : data.findIndex(i => i.uri === match.uri);
    const payload = {
      matches: allMatches,
      currentMatch: index === 0 ? null : match,
      currentMatchIndex,
      matchIndexes: allMatches.map(item => data.findIndex(i => i.uri === item.uri)),
    };
    onFilter(currentMatchIndex);
    dispatch(filterPlaylist(payload));
  }, [data, onFilter, filterData]);

  const handleNext = () => {
    const { index, allMatches } = filterData;
    if (!allMatches.length) {
      setIndex(0);
      return;
    }
    if (index === allMatches.length) {
      setIndex(1);
    } else {
      setIndex(index + 1);
    }
  };

  const handlePrev = () => {
    const { index, allMatches } = filterData;
    if (!allMatches.length) {
      setIndex(0);
      return;
    }
    if (index <= 1) {
      setIndex(allMatches.length);
    } else {
      setIndex(index - 1);
    }
  };

  const handleCancel = () => {
    setFilterData({
      index: 0,
      allMatches: [],
    });
    setSearching(false);
  };

  const suffix = `${filterData.index}/${filterData.allMatches.length}`;
  return (
    <View style={[styles.root, title ? styles.withTitle : null]}>
      <Animated.View style={{ opacity }}>
        <TitleWithOptions
          title={title}
          actions={[{ icon: 'search', onPress: () => setSearching(true) }, ...otherActions]}
        />
      </Animated.View>
      <Animated.View style={[styles.overlay, { left }]}>
        {searching ? (
          <TextInput
            placeholder={t('field_filter_songs')}
            placeholderTextColor={theme.color.grayLight}
            onChange={debounced}
            suffix={suffix}
            suffixStyle={{ paddingVertical: 15 }}
            style={{ input: styles.input }}
            autoFocus
          />
        ) : (
          <View style={styles.search} />
        )}
        <IconButton icon="expand-less" color="white" size={20} onPress={handlePrev} />
        <IconButton icon="expand-more" color="white" size={20} onPress={handleNext} />
        <IconButton icon="close" color="white" size={20} onPress={handleCancel} />
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: 62,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  search: {
    flex: 1,
    marginBottom: 10,
  },
  overlay: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    left: screenWidth,
    position: 'absolute',
  },
  input: {
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 5,
  },
});

export default SearchBox;
