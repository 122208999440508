import Toast from 'react-native-root-toast';

import theme from '../theme';

export const showToast = (message, { backgroundColor, fixed, time = 5000 }) => {
  const toast = Toast.show(message, {
    duration: 0,
    position: Toast.positions.BOTTOM,
    shadow: true,
    animation: true,
    hideOnPress: !fixed,
    delay: 0,
    backgroundColor,
    opacity: 1,
  });

  const callback = () => Toast.hide(toast);
  if (fixed) {
    return callback;
  } else {
    setTimeout(callback, time);
  }
};

export const success = (message, options = {}) =>
  message &&
  showToast(message, {
    backgroundColor: theme.color.success,
    ...options
  });

export const info = (message, options = {}) =>
  message &&
  showToast(message, {
    backgroundColor: theme.color.info,
    ...options
  });

export const error = (message, options = {}) =>
  message &&
  showToast(message, {
    backgroundColor: theme.color.error,
    ...options,
  });

export const warning = (message, options = {}) =>
  message &&
  showToast(message, {
    backgroundColor: theme.color.warning,
    ...options,
  });
