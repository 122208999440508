import { useEffect } from 'react';

export default name => {
  const logger = () => null;
  // const logger = console.log;
  useEffect(() => {
    logger('SCREEN  ON', name);
    return () => logger('SCREEN OFF', name);
  }, []);
};
