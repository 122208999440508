import React from 'react';
import { result } from 'lodash';
import { StyleSheet, Platform, Dimensions } from 'react-native';
import { Dialog as DialogRnp, Portal } from 'react-native-paper';
import { useSelector } from 'react-redux';

import theme from '../theme';
import Text from './Text';
import Button from './Button';
import { selectModal } from '../selectors';
import { t } from '../services/i18n';
import { closeModal } from '../services/modal';

const Modals = () => {
  const modals = useSelector(selectModal);

  return (
    <>
      {modals.map(modal => {
        const handleCloseModal = () => {
          closeModal(modal.id);
        };
        let buttons = [...result(modal, 'buttons', [])];
        if (buttons.length === 0) {
          buttons = [{ text: t('action_ok'), onPress: handleCloseModal }];
        }
        
        return (
          <Portal key={modal.id}>
            <DialogRnp visible dismissable={true} style={styles.modal} onDismiss={handleCloseModal}>
              <DialogRnp.Content>
                {modal.title && (
                  <Text theme="white" variant="title" centered>
                    {modal.title}
                  </Text>
                )}
                {modal.content && (
                  <Text theme="white" centered>
                    {modal.content}
                  </Text>
                )}
                {modal.children}
              </DialogRnp.Content>
              <DialogRnp.Actions style={styles.actions}>
                {buttons.map(button => (
                  <Button
                    leftAligned
                    key={button.text}
                    onPress={() => button.onPress({ close: handleCloseModal })}
                    color={button.variant}
                    disabled={button.disabled}
                    title={button.text}
                    icon={button.icon}
                  />
                ))}
              </DialogRnp.Actions>
            </DialogRnp>
          </Portal>
        );
      })}
    </>
  );
};

const styles = StyleSheet.create({
  modal: {
    maxWidth: Platform.OS === 'web' ? 1000 : undefined,
    marginHorizontal:
      Platform.OS === 'web' && Dimensions.get('window').width > 1000 ? 'auto' : theme.spacing(2),
  },
  actions: {
    flexDirection: 'column',
  },
});

export default Modals;
