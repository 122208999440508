import { takeEvery, call } from 'redux-saga/effects';

import * as constants from '../constants';
import * as iap from '../services/iap';

function* startIap({ payload }) {
  const actualUserId =  payload.user.email;
  console.log('aaaaaa setting IAP user to', actualUserId);
  yield call(iap.start, actualUserId);
}

export default function* watchAll() {
  yield takeEvery(constants.SET_USER, startIap);
}
