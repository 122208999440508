import React, { useEffect } from 'react';
import { LayoutAnimation } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { result } from 'lodash';

import Toggle from '../Toggle';
import theme from '../../theme';
import { t } from '../../services/i18n';
import { updateHostMode } from '../../actions';
import { selectHost } from '../../selectors';
import { openModal } from '../../services/modal';
import { EASE } from '../../services/animation';

const HostMode = () => {
  const dispatch = useDispatch();
  const { data: currentHost, updating } = useSelector(selectHost);
  const hostMode = result(currentHost, 'host.hostMode');

  const onChange = () => {
    const title = hostMode ? t('action_deactivate') : t('action_activate');
    const content = hostMode ? t('text_host_mode_off') : t('text_host_mode_on');
    const textAction = t('action_ok');
    openModal('hostMode', {
      title,
      content,
      buttons: [
        {
          text: textAction,
          onPress: ({ close }) => {
            handleChange();
            close();
          },
        },
        {
          text: t('action_cancel'),
          onPress: ({ close }) => close(),
          variant: 'secondary',
        },
      ],
    });
  };

  const handleChange = () => {
    LayoutAnimation.configureNext(EASE);
    dispatch(updateHostMode(!hostMode));
  };

  return (
    <Toggle
      value={hostMode}
      onChange={onChange}
      disabled={!!updating}
      style={{
        inactive: {
          backgroundColor: theme.color.blackLight,
        },
      }}
    />
  );
};

export default HostMode;
