import React, { useMemo } from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';

import Avatar from '../Avatar';
import HostService from '../../services/host';
import Icon from '../Icon';
import theme from '../../theme';
import { joinHost } from '../../actions';
import { t } from '../../services/i18n';
import host from '../../reducers/host';
import { openModal } from '../../services/modal';

const JoinHostButton = ({
  id,
  name,
  connectedGuests,
  displayPicture,
  allowOnlyJoinQr,
  onHandleQr,
  disabled,
  withinRadius,
  distance,
}) => {
  const dispatch = useDispatch();

  const handlePress = () => {
    if (!withinRadius) {
      openModal('notWithingRadius', {
        title: name,
        content: t('text_host_not_within_radius_message', { name, distance: Math.round(distance) }),
        buttons: [
          {
            text: t('action_ok'),
            onPress: ({ close }) => {
              close();
            },
          },
        ],
      });
    } else if (allowOnlyJoinQr) {
      onHandleQr();
    } else {
      dispatch(
        joinHost({
          id,
          from: 'list',
        }),
      );
    }
  };

  const image = useMemo(() => HostService.getHostImageUrl(displayPicture, true, true), [
    displayPicture,
  ]);

  return (
    <TouchableOpacity disabled={disabled} style={[styles.root, disabled && styles.disabled]} onPress={handlePress}>
      <Avatar image={image} name={name} size={34} style={styles.avatar} />
      <Text style={styles.text}>{name}</Text>
      {!withinRadius && (
        <Text style={styles.hint}>{`${Math.round(distance)}m`}</Text>
      )}
      {withinRadius && connectedGuests > 0 && (
        <Text style={styles.hint}>
          {connectedGuests === 1
            ? t('text_connected_guests_singular')
            : t('text_connected_guests_plural', { number: connectedGuests })}
        </Text>
      )}
      {allowOnlyJoinQr && <Icon icon="qr-code" style={styles.icon} />}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 17,
    paddingVertical: 12,
    backgroundColor: theme.color.blackLight,
    borderRadius: 8,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.6,
  },
  avatar: {
    marginRight: 17,
  },
  text: {
    fontSize: 16,
    color: theme.black.text,
    fontWeight: 'bold',
    flex: 1,
  },
  hint: {
    fontSize: 12,
    marginLeft: 15,
    color: theme.black.text,
  },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
});

export default JoinHostButton;
