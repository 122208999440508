import React from 'react';
import { StyleSheet, View } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import { useSelector } from 'react-redux';

import { selectNetwork } from '../selectors';

const Push = () => {
  const network = useSelector(selectNetwork);
  return <View style={[
    styles.push,
    network.connected
      ? null
      : { height: 0 }
    ]} />;
};

const styles = StyleSheet.create({
  push: {
    height: getStatusBarHeight(),
  },
});

export default Push;
