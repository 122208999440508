import * as constants from '../../constants';
import { error as toastError } from '../../helpers/toast';

const initialState = {
  loading: false,
  error: null,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.DOWNLOAD_MERCH_REQUEST: {
      return {
        loading: true,
        error: null,
      };
    }
    case constants.DOWNLOAD_MERCH_SUCCESS: {
      return {
        loading: false,
        error: null,
      };
    }
    case constants.DOWNLOAD_MERCH_FAILURE: {
      toastError(payload);
      return {
        loading: false,
        error: true,
      };
    }
    default:
      return state;
  }
};
