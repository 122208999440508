import moment from 'moment';
import React from 'react';

import LineChart from './LineChart';
import { t } from '../../services/i18n';

export default ({ report }) => {
  if (!report) {
    return null;
  }

  return (
    <LineChart
      title={t('text_report_guests_in_year')}
      labels={Object.keys(report).map(date => moment(date).month() + 1)}
      values={Object.values(report)}
    />
  );
};
