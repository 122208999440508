import React from 'react';

import Base from './Base';

const xml = `
<svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0186 21H14.9886C14.8957 21 5.59886 20.982 3.25329 20.3451C1.96349 19.9976 0.956213 18.9898 0.609476 17.6998C0.187692 15.3204 -0.0159862 12.9075 0.000978412 10.491C-0.00991888 8.0706 0.19927 5.65422 0.625962 3.2717C0.982229 1.98057 1.98508 0.968094 3.27278 0.599486C5.55389 0 14.5944 0 14.9781 0H15.0096C15.104 0 24.4248 0.0179846 26.7464 0.654939C28.0335 1.00415 29.0387 2.00992 29.3872 3.29717C29.8224 5.68544 30.0267 8.11005 29.9972 10.5375C30.0077 12.9549 29.798 15.3684 29.3707 17.7478C29.0195 19.0361 28.0116 20.0417 26.7224 20.39C24.4443 20.994 15.4023 21 15.0186 21ZM12.0211 6.00236L12.0136 14.9946L19.8251 10.4985L12.0211 6.00236Z" fill="white"/>
</svg>
`;

export default function YoutubeIcon(props) {
  return <Base xml={xml} {...props} />;
}
