import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';

import { t } from './i18n';
import { store } from '../store';
import { updateHostMode } from '../actions';
import { waitFortNavigation } from './navigation';
import { result } from 'lodash';

const LOUDY_HOST_MODE = 'LoudyHostMode1';

let noHostNotificationId = null;

if (Platform.OS !== 'web') {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });

  Notifications.addNotificationReceivedListener(notification => {
    const data = result(notification, 'request.content.data');
    
    console.log('Received notification', notification);
    if (data && data.code === 'host-off') {
      hideHostModeNotification();
    }
  });

  Notifications.setNotificationCategoryAsync(LOUDY_HOST_MODE, [
    {
      identifier: 'action_deactivate',
      buttonTitle: t('action_deactivate'),
    }, {
      identifier: 'action_open',
      buttonTitle: t('action_open'),
    }
  ]);

  Notifications.addNotificationResponseReceivedListener((response) => {
    waitFortNavigation(navigation => {
      switch (response.actionIdentifier) {
        case 'action_deactivate':
          store.dispatch(updateHostMode(false));
          break;
        case 'action_open':
        default:
          break;
      }
      // TODO: prevent calling when screen is not available
      navigation.navigate('HostHome');
    });
  });
}

export const showHostModeNotification = async () => {
  if (Platform.OS === 'web') {
    return;
  }

  await Notifications.dismissAllNotificationsAsync();

  noHostNotificationId = await Notifications.scheduleNotificationAsync({
    content: {
      title: t('text_notification_host_mode_title'),
      subtitle: t('text_notification_host_mode_sub'),
      body: t('text_notification_host_mode_message'),
      autoDismiss: false,
      sticky: false,
      priority: 'high',
      vibrate: false,
      sound: false,
      categoryIdentifier: LOUDY_HOST_MODE,
    },
    trigger: null,
  });
};

export const getPushToken = async () => {
  if (Platform.OS === 'web') {
    return null;
  }

  try {
    const { status } = await Notifications.requestPermissionsAsync();
    if (status === 'granted') {
      const tokenData = await Notifications.getExpoPushTokenAsync();
      const expoPushToken = tokenData.data;
      return expoPushToken;
    }
  } catch (e) {
    console.error('Error getting push token', e);
  }
  return null;
}

export const hideHostModeNotification = async () => {
  if (Platform.OS === 'web') {
    return;
  }

  if (noHostNotificationId) {
    await Notifications.dismissNotificationAsync(noHostNotificationId);
  }
};
