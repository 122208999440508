import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';

import Text from '../../components/Text';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import Space from '../../components/Space';
import * as analytics from '../../services/analytics';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import ContentWrapper from '../../components/ContentWrapper';
import theme from '../../theme';
import { t } from '../../services/i18n';

const SelectPlan = ({ route }) => {
  const { title, description, instructions, options, selector } = route.params;
  const { loadingCheckout } = useSelector(selector);

  useScreenFocus(() => {
    analytics.screen('selectPlan');
  });

  if (options.length === 0) {
    return (
      <Layout title={title}>
        <ContentWrapper style={styles.root}>
          <Text variant="title">{t('error_message_no_product_offerings')}</Text>
        </ContentWrapper>
      </Layout>
    );
  }

  return (
    <Layout title={title}>
      <ContentWrapper style={styles.root}>
        {description && <Text variant="title">{description}</Text>}
        {instructions && (
          <>
            <Text>{instructions}</Text>
            <Space />
          </>
        )}
        {orderBy(options, ['price'], ['asc']).map(option => (
          <Button
            preContent={
              option.icon ? (
                <Image
                  source={option.icon.image}
                  resizeMode="contain"
                  style={{
                    width: option.icon.width,
                    height: option.icon.height,
                  }}
                />
              ) : null
            }
            color="tertiary"
            key={option.title}
            title={option.title}
            onPress={option.onPress}
            hint={option.hint}
            disabled={!!loadingCheckout}
            loadingRight={loadingCheckout === option.identifier}
          />
        ))}
      </ContentWrapper>
    </Layout>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: theme.spacing(1),
  },
});

export default SelectPlan;
