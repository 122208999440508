import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, View, Animated } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';

import Text from '../components/Text';
import Spinner from '../components/Spinner';
import theme from '../theme';
import * as analytics from '../services/analytics';
import { selectNetwork } from '../selectors';
import { t } from '../services/i18n';

const NetworkStatus = () => {
  const heightAnim = useRef(new Animated.Value(0)).current;
  const network = useSelector(selectNetwork);

  useEffect(() => {
    if (!network.connected) {
      analytics.networkError();
    }
  }, [network.connected]);

  useEffect(() => {
    Animated.timing(heightAnim, {
      toValue: network.connected ? 0 : getStatusBarHeight() + 28,
      duration: 400,
      useNativeDriver: false,
    }).start();
  }, [heightAnim, network.connected]);

  return (
    <Animated.View
      style={[
        styles.root,
        {
          height: heightAnim,
        },
      ]}
    >
      <View style={styles.inner}>
        <Text centered style={styles.text}>
          {t('status_offline')}
        </Text>
        <Spinner size={10} color="white" />
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    backgroundColor: theme.color.blue,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  inner: {
    flexDirection: 'row',
    marginBottom: 4,
  },
  text: {
    fontSize: 12,
    marginRight: 10,
  },
});

export default NetworkStatus;
