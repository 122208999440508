import React from 'react';

import Audio from './Audio';
import SongItemLayout from './SongItemLayout';

const SearchLibraryTrackItem = ({
  item: {
    uri,
    name,
    artist,
    available,
    unavailableReason,
    preview,
  },
  index,
  actions,
}) => {
  const props = {
    number: index + 1,
    name,
    artist,
    uri,
    disabled: !available,
    disabledReason: unavailableReason,
    actions: actions,
    actionExtra: <Audio uri={preview} />,
  };

  return <SongItemLayout key={uri} {...props} />;
};

export default SearchLibraryTrackItem;
