import React from 'react';

import IconButton from './IconButton';
import { openModal } from '../services/modal';

const HelpButton = ({ text, iconSize, style, ...props }) => {
  const handleOpenModal = () => {
    openModal('helpModal', { content: text });
  };

  return (
    <IconButton
      icon="help"
      color="white"
      size={iconSize}
      onPress={handleOpenModal}
      style={[{ margin: 0 }, style]}
      {...props}
    />
  );
};

HelpButton.defaultProps = {
  iconSize: 24,
};

export default HelpButton;
