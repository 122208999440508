export default {
  // '00s-music': require('../assets/icons/00s-music.png'),
  // '60s-music': require('../assets/icons/60s-music.png'),
  // '70s-music': require('../assets/icons/70s-music.png'),
  // '80s-music': require('../assets/icons/80s-music.png'),
  // '90s-music': require('../assets/icons/90s-music.png'),
  // accordion: require('../assets/icons/accordion.png'),
  account: require('../assets/icons/account.png'),
  'add-song': require('../assets/icons/add-song.png'),
  'add-playlist': require('../assets/icons/add-playlist.png'),
  'clean-playlist': require('../assets/icons/clean-playlist.png'),
  // 'amazon-echo': require('../assets/icons/amazon-echo.png'),
  applause: require('../assets/icons/applause.png'),
  // 'audio-wave-2': require('../assets/icons/audio-wave-2.png'),
  // 'audio-wave': require('../assets/icons/audio-wave.png'),
  // 'audio-wave2': require('../assets/icons/audio-wave2.png'),
  // autograph: require('../assets/icons/autograph.png'),
  // 'bandmasters-mace': require('../assets/icons/bandmasters-mace.png'),
  // 'bass-clef': require('../assets/icons/bass-clef.png'),
  // 'bass-drum': require('../assets/icons/bass-drum.png'),
  // 'bell-lyre': require('../assets/icons/bell-lyre.png'),
  // 'block-microphone-2': require('../assets/icons/block-microphone-2.png'),
  // 'block-microphone-3': require('../assets/icons/block-microphone-3.png'),
  // 'block-microphone': require('../assets/icons/block-microphone.png'),
  // boombox: require('../assets/icons/boombox.png'),
  // bugle: require('../assets/icons/bugle.png'),
  // cello: require('../assets/icons/cello.png'),
  // choir: require('../assets/icons/choir.png'),
  // clarinet: require('../assets/icons/clarinet.png'),
  // 'classic-music': require('../assets/icons/classic-music.png'),
  coin: require('../assets/icons/coin.png'),
  coins: require('../assets/icons/coins.png'),
  // cornet: require('../assets/icons/cornet.png'),
  // 'country-music': require('../assets/icons/country-music.png'),
  // cymbals: require('../assets/icons/cymbals.png'),
  // dj: require('../assets/icons/dj.png'),
  // 'double-whole-note': require('../assets/icons/double-whole-note.png'),
  // 'drum-set': require('../assets/icons/drum-set.png'),
  // 'earbud-headphones': require('../assets/icons/earbud-headphones.png'),
  // 'eighth-rest': require('../assets/icons/eighth-rest.png'),
  // 'electronic-music': require('../assets/icons/electronic-music.png'),
  // flute: require('../assets/icons/flute.png'),
  // 'folk-music': require('../assets/icons/folk-music.png'),
  // 'french-horn': require('../assets/icons/french-horn.png'),
  google: require('../assets/icons/google.png'),
  // 'guitar-amp': require('../assets/icons/guitar-amp.png'),
  // guitar: require('../assets/icons/guitar.png'),
  // 'half-rest': require('../assets/icons/half-rest.png'),
  // harp: require('../assets/icons/harp.png'),
  // headphones: require('../assets/icons/headphones.png'),
  // 'herald-trumpet': require('../assets/icons/herald-trumpet.png'),
  // 'hip-hop-music': require('../assets/icons/hip-hop-music.png'),
  host: require('../assets/icons/host.png'),
  // 'international-music': require('../assets/icons/international-music.png'),
  // loudspeaker: require('../assets/icons/loudspeaker.png'),
  // lyre: require('../assets/icons/lyre.png'),
  // 'marching-tenor-drums': require('../assets/icons/marching-tenor-drums.png'),
  // 'metal-music': require('../assets/icons/metal-music.png'),
  // micro: require('../assets/icons/micro.png'),
  // 'microphone-2': require('../assets/icons/microphone-2.png'),
  // microphone: require('../assets/icons/microphone.png'),
  // 'music-conductor': require('../assets/icons/music-conductor.png'),
  // 'music-heart': require('../assets/icons/music-heart.png'),
  // 'music-notation': require('../assets/icons/music-notation.png'),
  // 'music-record': require('../assets/icons/music-record.png'),
  // 'musical-notes': require('../assets/icons/musical-notes.png'),
  // musical: require('../assets/icons/musical.png'),
  // 'mute-unmute': require('../assets/icons/mute-unmute.png'),
  // 'philips-hue-go': require('../assets/icons/philips-hue-go.png'),
  // piano: require('../assets/icons/piano.png'),
  // piccolo: require('../assets/icons/piccolo.png'),
  // 'pipe-organ': require('../assets/icons/pipe-organ.png'),
  playlist: require('../assets/icons/playlist.png'),
  // 'portable-speaker': require('../assets/icons/portable-speaker.png'),
  // 'quarter-rest': require('../assets/icons/quarter-rest.png'),
  // 'radio-2': require('../assets/icons/radio-2.png'),
  // 'radio-station': require('../assets/icons/radio-station.png'),
  // radio: require('../assets/icons/radio.png'),
  // rhythm: require('../assets/icons/rhythm.png'),
  // 'rock-music': require('../assets/icons/rock-music.png'),
  // sax: require('../assets/icons/sax.png'),
  // saxophone: require('../assets/icons/saxophone.png'),
  // singing: require('../assets/icons/singing.png'),
  // 'smart-playlist': require('../assets/icons/smart-playlist.png'),
  // 'snare-drum': require('../assets/icons/snare-drum.png'),
  // sonometer: require('../assets/icons/sonometer.png'),
  // 'soprano-saxophone': require('../assets/icons/soprano-saxophone.png'),
  // 'sound-surround': require('../assets/icons/sound-surround.png'),
  spotify: require('../assets/icons/spotify.png'),
  // 'square-wave': require('../assets/icons/square-wave.png'),
  // stage: require('../assets/icons/stage.png'),
  // 'tape-drive': require('../assets/icons/tape-drive.png'),
  // theremin: require('../assets/icons/theremin.png'),
  // timpani: require('../assets/icons/timpani.png'),
  // 'treble-clef': require('../assets/icons/treble-clef.png'),
  // trombone: require('../assets/icons/trombone.png'),
  // trumpet: require('../assets/icons/trumpet.png'),
  // tuba: require('../assets/icons/tuba.png'),
  // violin: require('../assets/icons/violin.png'),
  // violinist: require('../assets/icons/violinist.png'),
  // wallet: require('../assets/icons/wallet.png'),
  // 'whole-rest': require('../assets/icons/whole-rest.png'),
  // xylophone: require('../assets/icons/xylophone.png'),
};
