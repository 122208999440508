import { modalClose, modalOpen } from '../actions';
import { store } from '../store';

export const openModal = (id, { title, content, buttons, icon, children }) => {
  store.dispatch(
    modalOpen({
      id,
      title,
      content,
      buttons,
      icon,
      children,
    }),
  );
};

export const closeModal = id => {
  store.dispatch(
    modalClose({
      id,
    }),
  );
};
