import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { size } from 'lodash';

import AnimatedList from '../AnimatedList';
import Button from '../Button';
import Text from '../Text';
import Track from './Track';
import theme from '../../theme';
import * as refreshService from '../../services/refresh';
import { t } from '../../services/i18n';
import { REFRESH_SERVICES } from '../../constants';

const TrackListItem = ({ item, index }) => <Track index={index} {...item} />;

const Tracklist = ({ title, subTitle, otherActions, tracklist, loadingTracklist, refresh }) => {
  useEffect(() => {
    refreshService.start(REFRESH_SERVICES.GET_TRACKS, refresh, 30000);
    return () => refreshService.stop(REFRESH_SERVICES.GET_TRACKS);
  }, []);

  return size(tracklist) ? (
    <AnimatedList
      title={title}
      subTitle={subTitle}
      otherActions={otherActions}
      onRefresh={() => refresh(true)}
      refreshing={loadingTracklist}
      data={tracklist}
      renderItem={TrackListItem}
      keyExtractor={item => item.uri}
      showBar
    />
  ) : (
    <View>
      <Text variant="title" centered style={styles.title}>
        {t('screen_title_playlist')}
      </Text>
      <Text variant="body" centered>
        {t('text_empty_playlist')}
      </Text>
      <View style={styles.buttonsContainer}>
        {otherActions
          .filter(action => action.text)
          .map(action => {
            return (
              <Button
                key={action.text}
                title={action.text}
                onPress={action.onPress}
                icon={{
                  icon: action.icon,
                  external: action.external,
                }}
              />
            );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    marginTop: theme.spacing(1),
  },
  buttonsContainer: {
    marginTop: theme.spacing(1),
    width: '80%',
    alignSelf: 'center',
  },
});

export default Tracklist;
