import { result } from 'lodash';

import * as constants from '../../constants';
import { error as toastError } from '../../helpers/toast';

const initialState = {
  loading: false,
  loadingTracks: false,
  addingSong: false,
  sendingVote: false,
  options: [],
  data: null,
  currentTrack: null,
  votingStatus: false,
  error: null,
  mode: null,
  winnerTrack: null,
};

export default (state = initialState, { payload, type, meta }) => {
  switch (type) {
    case constants.API_GET_HOST_STATUS_REQUEST: {
      return {
        ...state,
        loadingTracks: payload,
      };
    }
    case constants.API_SEND_VOTE_REQUEST:
    case constants.API_SEND_CREDIT_REQUEST: {
      const uri = result(meta, 'action.payload.uri', true);
      return {
        ...state,
        sendingVote: uri,
      };
    }
    case constants.API_JOIN_HOST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.API_ADD_SONG_REQUEST: {
      return {
        ...state,
        addingSong: true,
      };
    }
    case constants.API_JOIN_HOST_SUCCESS:
    case constants.API_UPDATE_HOST_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: payload,
      };
    }
    case constants.API_GET_CURRENT_TRACK_SUCCESS: {
      return {
        ...state,
        currentTrack: payload,
      };
    }
    case constants.API_GET_HOST_STATUS_SUCCESS: {
      const { options, mode, winnerTrack } = payload;
      return {
        ...state,
        loadingTracks: false,
        options,
        data: {
          ...(state.data || {}),
          mode,
        },
        winnerTrack,
      };
    }
    case constants.API_SEND_VOTE_SUCCESS: {
      return {
        ...state,
        options: payload.data,
        sendingVote: false,
      };
    }
    case constants.API_SEND_CREDIT_SUCCESS: {
      return {
        ...state,
        options: payload.options,
        sendingVote: false,
      };
    }
    case constants.API_ADD_SONG_SUCCESS: {
      return {
        ...state,
        addingSong: false,
      };
    }
    case constants.API_SEARCH_TRACKS_SUCCESS: {
      const newState = { ...state };
      const host = result(payload, 'host');
      if (host) {
        newState.data = host;
      }
      return newState;
    }
    case constants.API_JOIN_HOST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case constants.API_GET_HOST_STATUS_FAILURE: {
      return {
        ...state,
        loadingTracks: false,
        options: [],
        error: payload,
      };
    }
    case constants.API_GET_CURRENT_TRACK_FAILURE: {
      return {
        ...state,
        currentTrack: null,
      };
    }
    case constants.API_SEND_CREDIT_FAILURE:
    case constants.API_SEND_VOTE_FAILURE: {
      return {
        ...state,
        sendingVote: false,
        error: payload,
      };
    }
    case constants.API_ADD_SONG_FAILURE: {
      toastError(payload);
      return {
        ...state,
        addingSong: false,
      };
    }
    case constants.CLEAN_USER:
    case constants.CLOSE_HOST: {
      return initialState;
    }
    case constants.SET_VOTING_STATUS: {
      return {
        ...state,
        votingStatus: payload,
      };
    }
    default:
      return state;
  }
};
