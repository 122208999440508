import { all } from 'redux-saga/effects';

import watchHost from './host';
import watchGuest from './guest';
import watchUser from './user';
import watchCheckout from './checkout';
import watchServerConfig from './serverConfig';
import watchSearchLibrary from './searchLibrary';
import watchConfetti from './confetti';
import watchNotification from './notification';
import watchIap from './iap';

export default function* rootSaga() {
  yield all([
    watchHost(),
    watchGuest(),
    watchUser(),
    watchCheckout(),
    watchServerConfig(),
    watchSearchLibrary(),
    watchConfetti(),
    watchNotification(),
    watchIap(),
  ]);
}
