import React from 'react';
import { View, StyleSheet } from 'react-native';

import IconButton from './IconButton';

const Stepper = ({ style, step, steps }) => (
  <View style={[styles.root, style]}>
    {new Array(steps).fill().map((i, index) => (
      <IconButton
        key={index}
        size={step === index ? 12 : 8}
        color="white"
        style={styles.icon}
        icon={step === index ? 'adjust' : 'circle'}
      />
    ))}
  </View>
);

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Stepper;
