import React from 'react';
import { StyleSheet, View } from 'react-native';

import HelpButton from '../HelpButton';
import Text from '../Text';
import theme from '../../theme';

const FormSection = ({ title, required, children, onLayout, hint }) => {
  return (
    <View style={styles.root} onLayout={onLayout}>
      <View style={styles.header}>
        <Text variant="subtitle" style={styles.title}>
          {title}
          {required ? ' *' : ''}
        </Text>
        {hint && <HelpButton text={hint} />}
      </View>
      <View>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 10,
    backgroundColor: theme.color.blackLight,
    borderRadius: 10,
  },
  header: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginBottom: 10,
  },
  title: {
    flex: 1,
    width: '100%',
  },
});

export default FormSection;
