import moment from 'moment';

//@TODO: check locales for future
export const formatDate = date => moment(date).format('DD/MM/YYYY');

export const formatTime = date => {
  return new Date(date).toLocaleTimeString([], {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatDateTime = date => `${formatDate(date)} ${formatTime(date)}`;

export const formatPrice = (price, currency = '$') => {
  const value = Math.round(Number(price) * 100) / 100;
  return `${currency.toUpperCase()} ${Number(value).toLocaleString()}`;
};
