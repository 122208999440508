import moment from 'moment';
import * as Location from 'expo-location';
import { call, takeEvery, takeLatest, put, all, select } from 'redux-saga/effects';
import { result } from 'lodash';

import watchAnalytics from './analytics';
import watchHost from './host';
import watchUser from './user';
import GuestService from '../../services/guest';
import * as constants from '../../constants';
import { error as toastError } from '../../helpers/toast';
import { getNavigation } from '../../services/navigation';
import { selectGuestAvailableCredit, selectOptions } from '../../selectors';
import { t } from '../../services/i18n';
import { openModal } from '../../services/modal';
import { getCurrentLocation } from '../../services/location';

function* getClosestHostsWorker() {
  try {
    yield put({ type: constants.API_GET_CLOSEST_HOSTS_REQUEST });
    const location = yield call(getCurrentLocation);
    const { latitude, longitude, accuracy } = location;
    if (accuracy > 50) {
      yield put({ type: constants.API_GET_CLOSEST_HOSTS_LOW_ACCURACY, payload: true });
    } else {
      yield put({ type: constants.API_GET_CLOSEST_HOSTS_LOW_ACCURACY, payload: false });
    }
    const response = yield call(GuestService.getClosestHosts, latitude, longitude, accuracy);
    const hosts = result(response, 'data.hosts', []);
    yield put({ type: constants.API_GET_CLOSEST_HOSTS_SUCCESS, payload: hosts });
  } catch (error) {
    console.log('get closest hosts sagas error', error);
    yield put({ type: constants.API_GET_CLOSEST_HOSTS_FAILURE, payload: error });
  }
}

function* joinHostWorker({ payload }) {
  try {
    yield put({ type: constants.API_JOIN_HOST_REQUEST });
    const { data } = yield call(GuestService.joinHost, payload.id);
    yield put({ type: constants.API_GET_CURRENT_TRACK, payload: payload.id });
    yield put({
      type: constants.API_JOIN_HOST_SUCCESS,
      payload: {
        ...data,
        from: payload.from,
      },
    });
    const navigation = getNavigation();
    navigation.navigate('CurrentHost');
  } catch (e) {
    const statusCode = result(e, 'response.status');
    if (statusCode === 412) {
      // free limit exceeded
      getNavigation().goBack();
      openModal('freeLimit', {
        title: t('text_free_limit_exceeded'),
        content: t('text_free_limit_exceeded_description'),
      });
    }
    yield put({ type: constants.API_JOIN_HOST_FAILURE, payload: e });
  }
}

function* askToUseCreditWorker({ payload }) {
  try {
    const { hostId, uri } = payload;
    const credit = yield select(selectGuestAvailableCredit);
    const options = yield select(selectOptions);
    const song = options.find(option => option.uri == uri);
    const navigation = getNavigation();

    if (credit > 0) {
      navigation.navigate('UseCredit', {
        hostId,
        song,
      });
    } else {
      openModal('noCredit', {
        title: t('action_dialog_play_song_title'),
        content: t('text_dialog_play_song_no_credit', { artist: song.artist, name: song.name }),
        buttons: [
          {
            text: t('action_buy_credit'),
            onPress: ({ close }) => {
              navigation.navigate('Credit');
              close();
            },
          },
          { text: t('action_cancel'), onPress: ({ close }) => close(), variant: 'secondary' },
        ],
      });
    }
  } catch (e) {
    console.log('error askign user to use credit', e);
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(constants.API_GET_CLOSEST_HOSTS, getClosestHostsWorker),
    takeLatest(constants.CLOSE_HOST, getClosestHostsWorker),
    takeEvery(constants.API_JOIN_HOST, joinHostWorker),
    takeEvery(constants.ASK_TO_USE_CREDIT, askToUseCreditWorker),
    watchAnalytics(),
    watchHost(),
    watchUser(),
  ]);
}
