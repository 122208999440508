import React from 'react';
import { View } from 'react-native';

import theme from '../theme';

const Space = ({ amount = 1 }) => {
  return (
    <View style={{ width: 100, height: theme.spacing(amount) }} />
  );
};

export default Space;
