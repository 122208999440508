import React from 'react';

import Layout from '../components/Layout';
import SearchLibrary from '../components/SearchLibrary';
import * as analytics from '../services/analytics';
import { useScreenFocus } from '../hooks/useScreenFocus';
import { t } from '../services/i18n';
import { useSelector } from 'react-redux';
import { selectAddingSongStatus } from '../selectors';

const SearchTracks = ({ route }) => {
  const addingSong = useSelector(selectAddingSongStatus);
  
  useScreenFocus(() => {
    analytics.screen('searchTracks');
  });

  const { hostId, allowExplicitContent } = route.params;
  return (
    <Layout
      title={t('screen_title_search_library')}
      hint={!hostId && 'hint_help_search_tracks_screen'}
      disableGoBackButton={addingSong}
    >
      <SearchLibrary type="tracks" hostId={hostId} allowExplicitContent={allowExplicitContent} />
    </Layout>
  );
};

export default SearchTracks;
