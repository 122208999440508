import { fork, select, call, takeEvery, put, all } from 'redux-saga/effects';

import LoginService from '../services/login';
import * as refreshService from '../services/refresh';
import * as constants from '../constants';
import { cleanUser, setPostLoginCallback, setUser } from '../actions';
import { clearAllData, clearData } from '../services/storage';
import { selectUser, selectUserInformation } from '../selectors';
import { getPushToken, hideHostModeNotification } from '../services/notification';

function* handleAccessTokenWorker({ payload }) {
  try {
    const { data } = yield call(LoginService.verifyUser, payload);
    // update push token
    const pushToken = yield call(getPushToken);
    const response = yield call(LoginService.handleAccessToken, { ...payload, pushToken });
    if (response.data) {
      yield put(
        setUser({
          userAuthorization: response.data.userAuthorization,
          user: response.data.user,
        }),
      );
      // handle any post login callback
      const userInformation = yield select(selectUserInformation);
      if (userInformation && userInformation.postLoginCallback) {
        userInformation.postLoginCallback(response.data.user.email);
        yield put(setPostLoginCallback(null));
      }
      return true;
    }
  } catch (e) {
    console.log('Error handling access tokens', e);
  }
  return false;
}

function* clearAsyncTasks() {
  yield call(refreshService.stop, constants.BACKGROUND_TASKS.CHECK_ACTIVE_HOST);
}

function* handleLogoutWorker() {
  const user = yield select(selectUser);
  if (user) {
    yield fork(LoginService.clean);
  }
  yield all([call(clearAsyncTasks), call(clearAllData), call(hideHostModeNotification)]);
  yield put(cleanUser());
}

function* handleLogoutStreamWorker() {
  try {
    yield all([
      call(LoginService.clean),
      call(clearAsyncTasks),
      call(clearData, 'host'),
      call(hideHostModeNotification),
    ]);
  } catch (e) {
    console.log('Error logout', e);
  }
}

export default function* watchAll() {
  yield all([
    takeEvery(constants.HANDLE_ACCESS_TOKENS, handleAccessTokenWorker),
    takeEvery(constants.LOGOUT_USER, handleLogoutWorker),
    takeEvery(constants.LOGOUT_STREAM, handleLogoutStreamWorker),
  ]);
}
