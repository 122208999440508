import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

export const useToggleAnimation = (boolVariable, from, to, duration = 300) => {
  const anim = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.timing(anim, {
      toValue: boolVariable ? from : to,
      duration,
      useNativeDriver: false,
    }).start();
  }, [anim, boolVariable]);
  return anim;
};
