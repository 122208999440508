import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { result } from 'lodash';

import Player from '../Player';
import * as analytics from '../../services/analytics';
import { pauseMusic, playMusic, nextSong, setVolume } from '../../actions';
import { selectPlayer } from '../../selectors';

const HostPlayer = ({ hostId }) => {
  const dispatch = useDispatch();
  const { currentDevice, isPlaying, currentTrack, isPremium, loading } = useSelector(selectPlayer);
  const volume = result(currentDevice, 'volume');
  const showVolume = result(currentDevice, 'showVolume');

  const artist = result(currentTrack, 'artist');
  const name = result(currentTrack, 'name');
  const image = result(currentTrack, 'image');
  const durationMs = result(currentTrack, 'durationMs');
  const progressMs = result(currentTrack, 'progressMs');
  const uri = result(currentTrack, 'uri');

  useEffect(() => {
    if (uri) {
      analytics.songHasChanged({ hostId, uri });
    }
  }, [uri]);

  const handlePlayPause = () => {
    isPlaying ? dispatch(pauseMusic()) : dispatch(playMusic());
  };

  const handleVolume = () => {
    dispatch(setVolume({ volume: volume ? 0 : 100 }));
  };

  const handleNext = () => {
    dispatch(nextSong());
  };

  return (
    <Player
      durationMs={durationMs}
      progressMs={progressMs}
      name={name}
      image={image}
      artist={artist}
      isPlaying={isPlaying}
      volume={volume}
      onPlayPause={handlePlayPause}
      onVolume={showVolume ? handleVolume : null}
      onNext={handleNext}
      currentDevice={currentDevice}
      isPremium={isPremium}
      loading={loading}
    />
  );
};

export default HostPlayer;
