import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import { debounce } from 'lodash';

import TextInput from './TextInput';
import HostSearchLibraryTracks from './host/SearchLibraryTracks';
import GuestSearchLibraryTracks from './guest/SearchLibraryTracks';
import theme from '../theme';
import { clean, search } from '../actions';
import { t } from '../services/i18n';
import ContentWrapper from './ContentWrapper';
import IconButton from './IconButton';

const SearchLibrary = ({ type, hostId, allowExplicitContent }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const inputRef = useRef();

  const debounced = useMemo(() => {
    const handleOnChange = value => {
      const query = value.trim();
      if (query) {
        dispatch(search[type]({ query, hostId, newSearch: true }));
      } else {
        dispatch(clean.tracks());
      }
    };
    return debounce(handleOnChange, 500);
  }, [type, hostId]);

  const onChange = useCallback(value => {
    setText(value);
  });

  useEffect(() => {
    debounced(text);
  }, [text]);

  return (
    <ContentWrapper style={styles.root}>
      <View style={styles.searchBox}>
        <TextInput
          placeholder={t('field_search_songs')}
          placeholderTextColor={theme.color.grayLight}
          onChange={onChange}
          value={text}
          ref={inputRef}
          suffix={
          <IconButton
            style={styles.icon}
            icon={text.length ? 'close' : 'search'}
            color={theme.color.grayLight}
            onPress={() => {
              text.length ? setText('') : inputRef.current?.focus();
            }}
          />
        }
        />
      </View>
      {type === 'tracks' &&
        (hostId ? <GuestSearchLibraryTracks hostId={hostId} /> : <HostSearchLibraryTracks />)}
    </ContentWrapper>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.black.bg1,
    flex: 1,
    paddingTop: theme.spacing(1),
  },
  searchBox: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

export default SearchLibrary;
