import { all, takeEvery, call, put } from 'redux-saga/effects';

import * as constants from '../../constants';
import handleMiddleware from '../middleware';
import GuestService from '../../services/guest';
import { logoutStream, logoutUser } from '../../actions';

function* getUserInformationWorker() {
  return yield call(GuestService.getUserInformation);
}

function* handleRemoveAccountWorker() {
  try {
    yield call(GuestService.removeAccount);
    yield put(logoutStream());
    yield put(logoutUser());
  } catch (e) {
    console.log('Error removing account', e);
  }
}

export default function* watchAll() {
  yield all([
    takeEvery(constants.API_GET_USER_INFORMATION, handleMiddleware, getUserInformationWorker),
    takeEvery(constants.REMOVE_ACCOUNT, handleMiddleware, handleRemoveAccountWorker),
  ]);
}
