import React, { useEffect, useState } from 'react';
import { RefreshControl as RNRefreshControl, Platform } from 'react-native';
import theme from '../theme';

const RefreshControl = ({ onRefresh, refreshing, ...props }) => {
  const [showRefreshIOS, setShowRefreshIOS] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'ios' && !refreshing) {
      setShowRefreshIOS(false);
    }
  }, [refreshing]);

  const handleRefresh = () => {
    setShowRefreshIOS(true);
    onRefresh();
  };

  if (Platform.OS === 'android') {
    return <RNRefreshControl onRefresh={onRefresh} refreshing={refreshing} {...props} />;
  }

  if (Platform.OS === 'ios') {
    return (
      <RNRefreshControl
        tintColor={theme.color.grayLight}
        onRefresh={handleRefresh}
        refreshing={showRefreshIOS}
      />
    );
  }

  return null;
};

export default RefreshControl;
