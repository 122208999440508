import * as constants from '../constants';
import { saveData } from '../services/storage';

const initialState = {
  data: null,
  userAuthorization: null,
  type: 'google',
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_USER: {
      saveData('user', payload);
      return {
        ...state,
        data: payload.user,
        userAuthorization: payload.userAuthorization,
      };
    }
    case constants.STORAGE_LOAD: {
      if (payload.user && payload.user.userAuthorization) {
        return {
          ...state,
          data: payload.user.user,
          userAuthorization: payload.user.userAuthorization,
        };
      }
      return state;
    }
    case constants.CLEAN_USER: {
      return initialState;
    }
    default:
      return state;
  }
};
