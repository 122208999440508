import { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Animated, Image } from 'react-native';

const MAX = 0.9;
const MIN = 0.4;
const DURATION = 4 * 1000;

const AnimatedLogo = () => {
  const opacity = useRef(new Animated.Value(MIN)).current;
  const [to, setTo] = useState(MAX);
  
  useEffect(() => {
    Animated.timing(opacity, {
      toValue: to,
      duration: DURATION,
      useNativeDriver: false,
    }).start(() => {
      if (to === MAX) {
        setTo(MIN);
      } else {
        setTo(MAX);
      }
    });
  }, [opacity, to]);
  
  return (
    <View style={styles.root}>
      <Animated.View style={[styles.animatedView, { opacity }]}>
        <Image source={require('../assets/logo-glow-bg.png')} style={[styles.logoBg]} resizeMode="contain" />
      </Animated.View>
      <Image source={require('../assets/logo-glow-fore.png')} style={[styles.logoFore]} resizeMode="contain" />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    width: '100%',
    height: 200,
  },
  animatedView: {
    height: '100%',
    width: '100%',
  },
  logoFore: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  logoBg: {
    height: '100%',
    width: '100%',
  },
});

export default AnimatedLogo;