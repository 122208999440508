import React from 'react';

import { ImageBackground, StyleSheet } from 'react-native';

import { useBackgroundImage } from '../hooks/useBackgroundImage';
import theme from '../theme';

const BackgroundWrapper = ({ children, backgroundImageIndex = 0 }) => {
  const backgroundUrl = useBackgroundImage(backgroundImageIndex);

  return (
    <ImageBackground source={backgroundUrl} style={styles.root} imageStyle={styles.inner}>
      {children}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  root: { width: '100%', height: '100%', backgroundColor: theme.color.black },
  inner: { opacity: 0.2 },
});

export default BackgroundWrapper;
