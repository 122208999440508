import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, FlatList, StyleSheet } from 'react-native';
import { result, size } from 'lodash';

import Loading from '../Loading';
import SearchLibraryTrackItem from '../SearchLibraryTrackItem';
import { selectBlacklist, selectSearchLibrary } from '../../selectors';
import { allowSong, addSongs, search, clean } from '../../actions';
import { t } from '../../services/i18n';
import { isCloseToBottom } from '../../helpers/scrollView';

const SearchLibraryTracks = () => {
  const dispatch = useDispatch();
  const { updating: updatingBlacklist } = useSelector(selectBlacklist);

  const { results, loading, newSearch, limit, next, offset, lastQuery } = useSelector(
    selectSearchLibrary('tracks'),
  );

  useEffect(() => {
    return () => dispatch(clean.tracks());
  }, []);

  const handleAllowSong = (uri, allowed) => {
    dispatch(
      allowSong({
        uri,
        status: !allowed,
      }),
    );
  };

  const handleAddSongs = selectedTracks => {
    dispatch(addSongs(selectedTracks));
  };

  const handleOnScroll = nativeEvent => {
    if (isCloseToBottom(nativeEvent) && next) {
      const from = offset + limit;
      dispatch(
        search.tracks({
          query: lastQuery,
          from,
        }),
      );
    }
  };

  const getActions = data => {
    const available = result(data, 'item.available');
    const allowed = result(data, 'item.allowed');
    const uri = result(data, 'item.uri');
    if (available) {
      return [
        {
          title: t('action_add'),
          color: 'primary',
          onPress: () => handleAddSongs([uri]),
          disabled: !allowed || updatingBlacklist === uri,
        },
        {
          title: allowed ? t('action_forbid_song') : t('action_allow_song'),
          color: 'secondary',
          onPress: () => handleAllowSong(uri, allowed),
          disabled: updatingBlacklist === uri,
        },
      ];
    }
  };

  return (
    <>
      <View style={styles.root}>
        {loading && newSearch ? (
          <Loading style={styles.loading} />
        ) : (
          size(results) > 0 && (
            <FlatList
              onScroll={({ nativeEvent }) => handleOnScroll(nativeEvent)}
              data={results}
              renderItem={item => (
                <SearchLibraryTrackItem
                  {...item}
                  actions={getActions(item)}
                  isHost
                />
              )}
              keyExtractor={item => item.uri}
            />
          )
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  loading: {
    marginTop: 30,
  },
});

export default SearchLibraryTracks;
