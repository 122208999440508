import api from './api';

export default class {
  static logEvent(args) {
    const [name, ...payload] = args;
    return api.post('/event', {
      name,
      payload
    });
  }
}
