import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View, Animated, Text } from 'react-native';
import { FAB } from 'react-native-paper';
import { useDispatch } from 'react-redux';
import { filter } from 'lodash';

import AnimatedList from '../../AnimatedList';
import FilterBox from '../../FilterBox';
import Option from './Option';
import { showConfetti, hideConfetti } from '../../../actions';
import theme from '../../../theme';

const PlaylistOptions = ({
  hostId,
  options,
  sendingVote,
  votingStatus,
  addAction,
  handlePaidPress,
}) => {
  const [elected, setElected] = useState(false);
  const showFilter = new Animated.Value(0);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [words, setWords] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const tracksWithoutCurrent = filter(options, option => !option.current);
    if (!votingStatus && tracksWithoutCurrent.length > 0) {
      const winner = tracksWithoutCurrent[0];
      if (winner.uri !== elected) {
        setElected(winner.uri);
        if (winner.votes > 0) {
          dispatch(
            showConfetti({
              x: '50%',
              y: 300,
            }),
          );
        }
      }
    } else {
      setElected(null);
      dispatch(hideConfetti());
    }
  }, [votingStatus, options]);

  useEffect(() => {
    if (words.length === 0) {
      setFilteredOptions(options);
      return;
    }
    const filtered = options.filter(item => {
      let matching = true;
      for (let word of words) {
        if (
          item.name.toLowerCase().indexOf(word) === -1 &&
          item.artist.toLowerCase().indexOf(word) === -1
        ) {
          matching = false;
        }
      }
      return matching || item.current || item.uri === elected;
    });
    setFilteredOptions(filtered);
  }, [words, options, elected]);

  const filterHeight = 75;

  return (
    <View style={styles.root}>
      <AnimatedList
        data={filteredOptions}
        renderItem={({ item, index }) => (
          <Option
            {...item}
            position={index + 1}
            hostId={hostId}
            disabled={!!sendingVote || !votingStatus}
            highlighted={item.current}
            elected={item.uri === elected}
            handlePaidPress={handlePaidPress}
          />
        )}
        keyExtractor={item => item.uri}
        contentOffset={{ x: 0, y: filterHeight }}
        snapToOffsets={[filterHeight]}
        snapToEnd={false}
        decelerationRate={showFilter ? 'fast' : 'normal'}
        ListHeaderComponent={
          <Animated.View style={{ opacity: showFilter }}>
            <FilterBox onFilter={setWords} />
          </Animated.View>
        }
        onScroll={e => {
          const offsetY = e.nativeEvent.contentOffset.y;
          let value = offsetY;
          if (offsetY > filterHeight) {
            value = filterHeight;
          } else if (offsetY < 0) {
            value = 0;
          }
          let opacity = Math.abs((value / filterHeight - 1) * -1);
          showFilter.setValue(opacity);
        }}
      />
      {addAction && <FAB style={styles.fab} {...addAction} />}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  fab: {
    position: 'absolute',
    right: 5,
    bottom: 20,
    backgroundColor: theme.color.red,
  },
});

export default PlaylistOptions;
