import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';

import SpotifyIcon from './svg/SpotifyIcon';
import YoutubeIcon from './svg/YoutubeIcon';
import InstagramIcon from './svg/InstagramIcon';
import Link from './Link';
import PinterestIcon from './svg/PinterestIcon';
import TikTokIcon from './svg/TikTokIcon';
import config from '../config';
import FacebookIcon from './svg/FacebookIcon';

const Social = ({ style }) => {
  return (
    <View style={[styles.root, style]}>
      <Link
        url={`https://open.spotify.com/user/${config.spotify.userId}`}
        component={props => <SpotifyIcon {...props} style={styles.icon} />}
      />
      <Link
        url="https://www.youtube.com/c/loudyapp"
        component={props => <YoutubeIcon {...props} style={styles.icon} />}
      />
      {/* @todo: fix youtube link */}
      <Link
        url={
          Platform.OS === 'web' ? 'https://www.facebook.com/loudyapp' : 'fb://page/108187841056104'
        }
        fallbackUrl="https://www.facebook.com/loudyapp"
        component={props => <FacebookIcon {...props} style={styles.icon} />}
      />
      <Link
        url="https://www.instagram.com/loudyapp"
        component={props => <InstagramIcon {...props} style={styles.icon} />}
      />
      <Link
        url="https://www.pinterest.com/loudyapp"
        component={props => <PinterestIcon {...props} style={styles.icon} />}
      />
      <Link
        url="https://www.tiktok.com/@loudyapp"
        component={props => <TikTokIcon {...props} style={styles.icon} />}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginHorizontal: 8,
    width: 20,
    height: 20,
  },
});

export default Social;
