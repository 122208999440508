import { find, remove, some } from 'lodash';

const intervals = [];

export const start = (name, action, miliseconds) => {
  console.log('Starting scheduled task', name, 'every', miliseconds / 1000, 'seconds');
  const id = setInterval(() => {
    action();
  }, miliseconds);
  intervals.push({
    name,
    id,
  });
};

export const stop = name => {
  const interval = find(intervals, interval => interval.name === name);
  if (interval) {
    clearInterval(interval.id);
    remove(intervals, interval => interval.name === name);
  }
};

export const existsTask = name => {
  return some(intervals, interval => interval.name === name);
};
