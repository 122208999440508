import { result } from 'lodash';
import { delay, fork } from 'redux-saga/effects';

const currentSong = {
  guest: null,
  host: null,
};

const checkEvery = 15 * 1000;

export function* checkNextSongWorker(from, data, callback) {
  const currentTrack = result(data, 'currentTrack');
  if (currentTrack) {
    const uri = result(currentTrack, 'uri');
    const durationMs = result(currentTrack, 'durationMs');
    const progressMs = result(currentTrack, 'progressMs');
    const isPlaying = result(currentTrack, 'isPlaying');
    const left = durationMs - progressMs;
    console.log('Left', left);

    if (isPlaying && currentSong[from] !== uri && left <= checkEvery) {
      console.log(`Checking next song for ${from} in ${left}`);
      currentSong[from] = uri;
      yield delay(left - 200);
      console.log('Calling callback');
      yield fork(callback);
    }
  }
}
