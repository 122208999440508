import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import * as AppleAuthentication from 'expo-apple-authentication';

import ContentWrapper from '../components/ContentWrapper';
import BackgroundWrapper from '../components/BackgroundWrapper';
import GoogleLoginButton from '../components/GoogleLoginButton';
import Loading from '../components/Loading';
import Social from '../components/Social';
import AnimatedLogo from '../components/AnimatedLogo';
import useScreenLogger from '../hooks/useScreenLogger';
import config from '../config';
import { t } from '../services/i18n';
import * as analytics from '../services/analytics';
import { handleAccessToken } from '../actions';
import { useDispatch } from 'react-redux';
import { error } from '../helpers/toast';

const loginConfig = {
  platformClientId: config.google.androidClientId,
  androidClientId: config.google.androidClientId,
  expoClientId: config.google.webClientId,
  iosClientId: config.google.androidClientId,
  androidStandaloneAppClientId: config.google.androidClientId,
  webClientId: config.google.webClientId,
};

WebBrowser.maybeCompleteAuthSession();

const Login = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useScreenLogger('LOGIN');

  const [request, response, promptAsync] = Google.useAuthRequest(loginConfig);

  useEffect(() => {
    if (response) {
      if (response.type === 'success') {
        const {
          authentication: { accessToken },
        } = response;
        dispatch(
          handleAccessToken({
            provider: 'google',
            accessToken,
          }),
        );
        return;
      } else if (response.type !== 'cancel') {
        console.log('Error logging in', response);
        error(t('error_message_logging_in_google'));
      }
    }
    setLoading(false);
  }, [response]);
  
  const handleLogin = () => {
    setLoading(true);
    promptAsync({
      showInRecents: true,
    }).then(res => {
      if (['cancel', 'dismiss'].includes(res.type)) {
        setLoading(false);
      }
    });
  };

  const handleAppleLogin = () => {
    setLoading(true);
    AppleAuthentication.signInAsync({
      requestedScopes: [
        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
        AppleAuthentication.AppleAuthenticationScope.EMAIL,
      ],
    })
      .then(credential => {
        dispatch(
          handleAccessToken({
            provider: 'apple',
            accessToken: credential.identityToken,
          }),
        );
      })
      .catch(e => {
        analytics.loginFailed(e);
        setLoading(false);
        if (e.code !== 'ERR_CANCELED') {
          error(t('error_message_logging_in_apple'));
        }
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <View style={styles.root}>
      <BackgroundWrapper>
        <ContentWrapper>
          <View style={styles.section}>
            <AnimatedLogo />
            <View style={styles.buttonContainer}>
              {Platform.OS === 'ios' && (
                <AppleAuthentication.AppleAuthenticationButton
                  buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                  buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
                  cornerRadius={3}
                  style={[styles.button, { width: 250, height: 40 }]}
                  onPress={handleAppleLogin}
                />
              )}
              <GoogleLoginButton style={styles.button} onPress={handleLogin} />
            </View>
          </View>
          <Social style={styles.social} />
        </ContentWrapper>
      </BackgroundWrapper>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  section: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  buttonContainer: {},
  button: {
    transform: [{ scale: 1.1 }],
    marginBottom: 20,
  },
  social: {
    marginBottom: 54,
  },
});

export default Login;
