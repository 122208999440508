// Reducer's constants
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_STREAM = 'LOGOUT_STREAM';
export const CLEAN_USER = 'CLEAN_USER';
export const SET_IAP_PRODUCTS = 'SET_IAP_PRODUCTS';
export const SET_IAP_SUBSCRIPTIONS = 'SET_IAP_SUBSCRIPTIONS';
export const SET_IAP_LOADING_CHECKOUT = 'SET_IAP_LOADING_CHECKOUT';
export const IAP_START_CHECKOUT_PRODUCT = 'IAP_START_CHECKOUT_PRODUCT';
export const IAP_START_CHECKOUT_SUBSCRIPTION = 'IAP_START_CHECKOUT_SUBSCRIPTION';
export const IAP_FINISH_CHECKOUT_PRODUCT = 'IAP_FINISH_CHECKOUT_PRODUCT';
export const SET_MP_LOADING_CHECKOUT = 'SET_MP_LOADING_CHECKOUT';
export const MP_START_CHECKOUT = 'MP_START_CHECKOUT';
export const HANDLE_ACCESS_TOKENS = 'HANDLE_ACCESS_TOKENS';
export const SET_USER = 'SET_USER';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_UPDATE = 'MODAL_UPDATE';
export const REMOVE_TRACK_INFO = 'REMOVE_TRACK_INFO';
export const CLEAN_PLAYLISTS = 'CLEAN_PLAYLISTS';
export const CLEAN_SEARCH_TRACKS = 'CLEAN_SEARCH_TRACKS';
export const HOST_SESSION_EXPIRED = 'HOST_SESSION_EXPIRED';
export const ASK_TO_USE_CREDIT = 'ASK_TO_USE_CREDIT';
export const SET_NETWORK = 'SET_NETWORK';
export const SET_ONBOARDED = 'SET_ONBOARDED';
export const SET_COLLAPSE_STATUS = 'SET_COLLAPSE_STATUS';
export const FILTER_PLAYLIST = 'FILTER_PLAYLIST';
export const SET_POST_LOGIN_CALLBACK = 'SET_POST_LOGIN_CALLBACK';
export const SET_NOTIFICATION_WEB_VIEW = 'SET_NOTIFICATION_WEB_VIEW';
export const PUSH_NOTIFICAITON_CLICKED = 'PUSH_NOTIFICAITON_CLICKED';

export const DOWNLOAD_MERCH = 'DOWNLOAD_MERCH';
export const DOWNLOAD_MERCH_REQUEST = 'DOWNLOAD_MERCH_REQUEST';
export const DOWNLOAD_MERCH_SUCCESS = 'DOWNLOAD_MERCH_SUCCESS';
export const DOWNLOAD_MERCH_FAILURE = 'DOWNLOAD_MERCH_FAILURE';

export const SHOW_CONFETTI = 'SHOW_CONFETTI';
export const HIDE_CONFETTI = 'HIDE_CONFETTI';
export const SET_CONFETTI_STATUS = 'SET_CONFETTI_STATUS';

export const BUY_CREDIT = 'BUY_CREDIT';
export const BUY_CREDIT_REQUEST = 'BUY_CREDIT_REQUEST';
export const BUY_CREDIT_SUCCESS = 'BUY_CREDIT_SUCCESS';
export const BUY_CREDIT_FAILURE = 'BUY_CREDIT_FAILURE';

export const BUY_SUBSCRIPTION = 'BUY_SUBSCRIPTION';
export const BUY_SUBSCRIPTION_REQUEST = 'BUY_SUBSCRIPTION_REQUEST';
export const BUY_SUBSCRIPTION_SUCCESS = 'BUY_SUBSCRIPTION_SUCCESS';
export const BUY_SUBSCRIPTION_FAILURE = 'BUY_SUBSCRIPTION_FAILURE';

export const API_SERVER_CONFIG = 'API_SERVER_CONFIG';
export const API_SERVER_CONFIG_REQUEST = 'API_SERVER_CONFIG_REQUEST';
export const API_SERVER_CONFIG_SUCCESS = 'API_SERVER_CONFIG_SUCCESS';
export const API_SERVER_CONFIG_FAILURE = 'API_SERVER_CONFIG_FAILURE';

export const API_SEARCH_TRACKS = 'API_SEARCH_TRACKS';
export const API_SEARCH_TRACKS_REQUEST = 'API_SEARCH_TRACKS_REQUEST';
export const API_SEARCH_TRACKS_SUCCESS = 'API_SEARCH_TRACKS_SUCCESS';
export const API_SEARCH_TRACKS_FAILURE = 'API_SEARCH_TRACKS_FAILURE';

export const API_SEARCH_PLAYLISTS = 'API_SEARCH_PLAYLISTS';
export const API_SEARCH_PLAYLISTS_REQUEST = 'API_SEARCH_PLAYLISTS_REQUEST';
export const API_SEARCH_PLAYLISTS_SUCCESS = 'API_SEARCH_PLAYLISTS_SUCCESS';
export const API_SEARCH_PLAYLISTS_FAILURE = 'API_SEARCH_PLAYLISTS_FAILURE';

export const API_MP_CHECK_CREDIT_STATUS = 'API_MP_CHECK_CREDIT_STATUS';
export const API_MP_CHECK_CREDIT_STATUS_REQUEST = 'API_MP_CHECK_CREDIT_STATUS_REQUEST';
export const API_MP_CHECK_CREDIT_STATUS_SUCCESS = 'API_MP_CHECK_CREDIT_STATUS_SUCCESS';
export const API_MP_CHECK_CREDIT_STATUS_FAILURE = 'API_MP_CHECK_CREDIT_STATUS_FAILURE';

// Host constants
export const STORAGE_LOAD = 'STORAGE_LOAD';

export const API_LOGIN_WITH_SPOTIFY = 'API_LOGIN_WITH_SPOTIFY';
export const API_LOGIN_WITH_SPOTIFY_REQUEST = 'API_LOGIN_WITH_SPOTIFY_REQUEST';
export const API_LOGIN_WITH_SPOTIFY_SUCCESS = 'API_LOGIN_WITH_SPOTIFY_SUCCESS';
export const API_LOGIN_WITH_SPOTIFY_FAILURE = 'API_LOGIN_WITH_SPOTIFY_FAILURE';

export const API_SAVE_SETTINGS = 'API_SAVE_SETTINGS';
export const API_SAVE_SETTINGS_REQUEST = 'API_SAVE_SETTINGS_REQUEST';
export const API_SAVE_SETTINGS_SUCCESS = 'API_SAVE_SETTINGS_SUCCESS';
export const API_SAVE_SETTINGS_FAILURE = 'API_SAVE_SETTINGS_FAILURE';

export const API_SAVE_CURRENT_LOCATION = 'API_SAVE_CURRENT_LOCATION';
export const API_SAVE_CURRENT_LOCATION_REQUEST = 'API_SAVE_CURRENT_LOCATION_REQUEST';
export const API_SAVE_CURRENT_LOCATION_SUCCESS = 'API_SAVE_CURRENT_LOCATION_SUCCESS';
export const API_SAVE_CURRENT_LOCATION_FAILURE = 'API_SAVE_CURRENT_LOCATION_FAILURE';

export const API_GET_TRACK_INFO = 'API_GET_TRACK_INFO';
export const API_GET_TRACK_INFO_REQUEST = 'API_GET_TRACK_INFO_REQUEST';
export const API_GET_TRACK_INFO_SUCCESS = 'API_GET_TRACK_INFO_SUCCESS';
export const API_GET_TRACK_INFO_FAILURE = 'API_GET_TRACK_INFO_FAILURE';

export const API_PLAY_MUSIC = 'API_PLAY_MUSIC';
export const API_PLAY_MUSIC_REQUEST = 'API_PLAY_MUSIC_REQUEST';
export const API_PLAY_MUSIC_SUCCESS = 'API_PLAY_MUSIC_SUCCESS';
export const API_PLAY_MUSIC_FAILURE = 'API_PLAY_MUSIC_FAILURE';

export const API_PAUSE_MUSIC = 'API_PAUSE_MUSIC';
export const API_PAUSE_MUSIC_REQUEST = 'API_PAUSE_MUSIC_REQUEST';
export const API_PAUSE_MUSIC_SUCCESS = 'API_PAUSE_MUSIC_SUCCESS';
export const API_PAUSE_MUSIC_FAILURE = 'API_PAUSE_MUSIC_FAILURE';

export const API_NEXT_SONG = 'API_NEXT_SONG';
export const API_NEXT_SONG_REQUEST = 'API_NEXT_SONG_REQUEST';
export const API_NEXT_SONG_SUCCESS = 'API_NEXT_SONG_SUCCESS';
export const API_NEXT_SONG_FAILURE = 'API_NEXT_SONG_FAILURE';

export const API_SET_VOLUME = 'API_SET_VOLUME';
export const API_SET_VOLUME_REQUEST = 'API_SET_VOLUME_REQUEST';
export const API_SET_VOLUME_SUCCESS = 'API_SET_VOLUME_SUCCESS';
export const API_SET_VOLUME_FAILURE = 'API_SET_VOLUME_FAILURE';

export const API_GET_PLAYER_STATUS = 'API_GET_PLAYER_STATUS';
export const API_GET_PLAYER_STATUS_SUCCESS = 'API_GET_PLAYER_STATUS_SUCCESS';
export const API_GET_PLAYER_STATUS_FAILURE = 'API_GET_PLAYER_STATUS_FAILURE';

export const API_GET_HOST = 'API_GET_HOST';
export const API_GET_HOST_REQUEST = 'API_GET_HOST_REQUEST';
export const API_GET_HOST_SUCCESS = 'API_GET_HOST_SUCCESS';
export const API_GET_HOST_FAILURE = 'API_GET_HOST_FAILURE';

export const API_GET_PLAYLISTS = 'API_GET_PLAYLISTS';
export const API_GET_PLAYLISTS_REQUEST = 'API_GET_PLAYLISTS_REQUEST';
export const API_GET_PLAYLISTS_SUCCESS = 'API_GET_PLAYLISTS_SUCCESS';
export const API_GET_PLAYLISTS_FAILURE = 'API_GET_PLAYLISTS_FAILURE';

export const API_IMPORT_PLAYLIST = 'API_IMPORT_PLAYLIST';
export const API_IMPORT_PLAYLIST_REQUEST = 'API_IMPORT_PLAYLIST_REQUEST';
export const API_IMPORT_PLAYLIST_SUCCESS = 'API_IMPORT_PLAYLIST_SUCCESS';
export const API_IMPORT_PLAYLIST_FAILURE = 'API_IMPORT_PLAYLIST_FAILURE';

export const API_GET_TRACKLIST = 'API_GET_TRACKLIST';
export const API_GET_TRACKLIST_REQUEST = 'API_GET_TRACKLIST_REQUEST';
export const API_GET_TRACKLIST_SUCCESS = 'API_GET_TRACKLIST_SUCCESS';
export const API_GET_TRACKLIST_FAILURE = 'API_GET_TRACKLIST_FAILURE';

export const API_CLEAN_TRACKLIST = 'API_CLEAN_TRACKLIST';
export const API_CLEAN_TRACKLIST_REQUEST = 'API_CLEAN_TRACKLIST_REQUEST';
export const API_CLEAN_TRACKLIST_SUCCESS = 'API_CLEAN_TRACKLIST_SUCCESS';
export const API_CLEAN_TRACKLIST_FAILURE = 'API_CLEAN_TRACKLIST_FAILURE';

export const API_ADD_SONGS = 'API_ADD_SONGS';
export const API_ADD_SONGS_REQUEST = 'API_ADD_SONGS_REQUEST';
export const API_ADD_SONGS_SUCCESS = 'API_ADD_SONGS_SUCCESS';
export const API_ADD_SONGS_FAILURE = 'API_ADD_SONGS_FAILURE';

export const API_ALLOW_SONG = 'API_ALLOW_SONG';
export const API_ALLOW_SONG_REQUEST = 'API_ALLOW_SONG_REQUEST';
export const API_ALLOW_SONG_SUCCESS = 'API_ALLOW_SONG_SUCCESS';
export const API_ALLOW_SONG_FAILURE = 'API_ALLOW_SONG_FAILURE';

export const API_DELETE_SONG = 'API_DELETE_SONG';
export const API_DELETE_SONG_REQUEST = 'API_DELETE_SONG_REQUEST';
export const API_DELETE_SONG_SUCCESS = 'API_DELETE_SONG_SUCCESS';
export const API_DELETE_SONG_FAILURE = 'API_DELETE_SONG_FAILURE';

export const API_GET_GUESTS = 'API_GET_GUESTS';
export const API_GET_GUESTS_REQUEST = 'API_GET_GUESTS_REQUEST';
export const API_GET_GUESTS_SUCCESS = 'API_GET_GUESTS_SUCCESS';
export const API_GET_GUESTS_FAILURE = 'API_GET_GUESTS_FAILURE';

export const API_UPDATE_HOST_MODE = 'API_UPDATE_HOST_MODE';
export const API_UPDATE_HOST_MODE_REQUEST = 'API_UPDATE_HOST_MODE_REQUEST';
export const API_UPDATE_HOST_MODE_SUCCESS = 'API_UPDATE_HOST_MODE_SUCCESS';
export const API_UPDATE_HOST_MODE_FAILURE = 'API_UPDATE_HOST_MODE_FAILURE';

export const API_GET_BLACKLIST = 'API_GET_BLACKLIST';
export const API_GET_BLACKLIST_REQUEST = 'API_GET_BLACKLIST_REQUEST';
export const API_GET_BLACKLIST_SUCCESS = 'API_GET_BLACKLIST_SUCCESS';
export const API_GET_BLACKLIST_FAILURE = 'API_GET_BLACKLIST_FAILURE';

// Guest constants
export const SET_VOTING_STATUS = 'SET_VOTING_STATUS';
export const UPDATE_VOTING_STATUS = 'UPDATE_VOTING_STATUS';
export const FINISH_VOTING_STATUS = 'FINISH_VOTING_STATUS';
export const CLOSE_HOST = 'CLOSE_HOST';

export const API_GET_USER_INFORMATION = 'API_GET_USER_INFORMATION';
export const API_GET_USER_INFORMATION_REQUEST = 'API_GET_USER_INFORMATION_REQUEST';
export const API_GET_USER_INFORMATION_SUCCESS = 'API_GET_USER_INFORMATION_SUCCESS';
export const API_GET_USER_INFORMATION_FAILURE = 'API_GET_USER_INFORMATION_FAILURE';

export const API_GET_CLOSEST_HOSTS = 'API_GET_CLOSEST_HOSTS';
export const API_GET_CLOSEST_HOSTS_REQUEST = 'API_GET_CLOSEST_HOSTS_REQUEST';
export const API_GET_CLOSEST_HOSTS_SUCCESS = 'API_GET_CLOSEST_HOSTS_SUCCESS';
export const API_GET_CLOSEST_HOSTS_LOW_ACCURACY = 'API_GET_CLOSEST_HOSTS_LOW_ACCURACY';
export const API_GET_CLOSEST_HOSTS_FAILURE = 'API_GET_CLOSEST_HOSTS_FAILURE';

export const API_JOIN_HOST = 'API_JOIN_HOST';
export const API_JOIN_HOST_REQUEST = 'API_JOIN_HOST_REQUEST';
export const API_JOIN_HOST_SUCCESS = 'API_JOIN_HOST_SUCCESS';
export const API_JOIN_HOST_FAILURE = 'API_JOIN_HOST_FAILURE';

export const API_UPDATE_HOST_DATA_SUCCESS = 'API_UPDATE_HOST_DATA_SUCCESS';

export const API_GET_CURRENT_TRACK = 'API_GET_CURRENT_TRACK';
export const API_GET_CURRENT_TRACK_REQUEST = 'API_GET_CURRENT_TRACK_REQUEST';
export const API_GET_CURRENT_TRACK_SUCCESS = 'API_GET_CURRENT_TRACK_SUCCESS';
export const API_GET_CURRENT_TRACK_FAILURE = 'API_GET_CURRENT_TRACK_FAILURE';

export const API_GET_HOST_STATUS = 'API_GET_HOST_STATUS';
export const API_GET_HOST_STATUS_REQUEST = 'API_GET_HOST_STATUS_REQUEST';
export const API_GET_HOST_STATUS_SUCCESS = 'API_GET_HOST_STATUS_SUCCESS';
export const API_GET_HOST_STATUS_FAILURE = 'API_GET_HOST_STATUS_FAILURE';

export const API_SEND_VOTE = 'API_SEND_VOTE';
export const API_SEND_VOTE_REQUEST = 'API_SEND_VOTE_REQUEST';
export const API_SEND_VOTE_SUCCESS = 'API_SEND_VOTE_SUCCESS';
export const API_SEND_VOTE_FAILURE = 'API_SEND_VOTE_FAILURE';

export const API_SEND_CREDIT = 'API_SEND_CREDIT';
export const API_SEND_CREDIT_REQUEST = 'API_SEND_CREDIT_REQUEST';
export const API_SEND_CREDIT_SUCCESS = 'API_SEND_CREDIT_SUCCESS';
export const API_SEND_CREDIT_FAILURE = 'API_SEND_CREDIT_FAILURE';

export const API_SEND_CREDIT_PAYMENT = 'API_SEND_CREDIT_PAYMENT';
export const API_SEND_CREDIT_PAYMENT_REQUEST = 'API_SEND_CREDIT_PAYMENT_REQUEST';
export const API_SEND_CREDIT_PAYMENT_SUCCESS = 'API_SEND_CREDIT_PAYMENT_SUCCESS';
export const API_SEND_CREDIT_PAYMENT_FAILURE = 'API_SEND_CREDIT_PAYMENT_FAILURE';

export const API_ADD_SONG = 'API_ADD_SONG';
export const API_ADD_SONG_REQUEST = 'API_ADD_SONG_REQUEST';
export const API_ADD_SONG_SUCCESS = 'API_ADD_SONG_SUCCESS';
export const API_ADD_SONG_FAILURE = 'API_ADD_SONG_FAILURE';

// Others constants
export const SETTINGS_DEFAULT_VALUES = {
  ALLOW_ADD: true,
  ALLOW_EXPLICIT_CONTENT: false,
  AMOUNT_OF_SONGS: '6',
  DISPLAY_PICTURE: null,
  FEET_VALUES: {
    MIN: 7,
    MAX: 100,
  },
  METERS_VALUES: {
    MIN: 2,
    MAX: 100,
  },
  MODE_TOGGLE: true,
  DISTANCE_UNIT_TOGGLE: true,
  NAME: null,
  NOTIFICATIONS: true,
  RADIUS: 20,
  STREAMING_SERVICE: 'spotify',
};

export const DISTANCE_UNITS = {
  METERS: 'meters',
  FEET: 'feet',
};

export const MODES = {
  INSTANT: 'instant mode',
  PLAYLIST: 'playlist mode',
};

export const LOCATION_MODES = {
  CURRENT: 'current',
  FIXED: 'fixed',
};

export const STREAMING_SERVICES = [
  {
    label: 'Spotify',
    value: 'spotify',
  },
  {
    label: 'Youtube music',
    value: 'youtube',
    disabled: true,
  },
  {
    label: 'Itunes',
    value: 'itunes',
    disabled: true,
  },
];

export const GENRES = {
  categories: {
    super_power: 'field_genre_super_power',
    energy: 'field_genre_energy',
    positive: 'field_genre_positive',
    ambient: 'field_genre_ambient',
    party: 'field_genre_party',
    love: 'field_genre_love',
    fun: 'field_genre_fun',
  },
  subcategories: {
    country: 'field_subgenre_country',
    criollo: 'field_subgenre_criollo',
    cumbia: 'field_subgenre_cumbia',
    disco: 'field_subgenre_disco',
    dubstep: 'field_subgenre_dubstep',
    electronic: 'field_subgenre_electronic',
    flamenco: 'field_subgenre_flamenco',
    folk: 'field_subgenre_folk',
    funk: 'field_subgenre_funk',
    garage: 'field_subgenre_garage',
    rock_gospel: 'field_subgenre_rock_gospel',
    heavy_metal: 'field_subgenre_heavy_metal',
    hip_hop: 'field_subgenre_hip_hop',
    indie: 'field_subgenre_indie',
    jazz: 'field_subgenre_jazz',
    merengue: 'field_subgenre_merengue',
    pop: 'field_subgenre_pop',
    polka: 'field_subgenre_polka',
    punk: 'field_subgenre_punk',
    ranchera: 'field_subgenre_ranchera',
    rap: 'field_subgenre_rap',
    reggae: 'field_subgenre_reggae',
    reggaeton: 'field_subgenre_reggaeton',
    rumba: 'field_subgenre_rumba',
    rhythm_and_blues: 'field_subgenre_rhythm_and_blues',
    rock: 'field_subgenre_rock',
    rock_and_roll: 'field_subgenre_rock_and_roll',
    salsa: 'field_subgenre_salsa',
    samba: 'field_subgenre_samba',
    ska: 'field_subgenre_ska',
    son: 'field_subgenre_son',
    soul: 'field_subgenre_soul',
    swing: 'field_subgenre_swing',
    tango: 'field_subgenre_tango',
    trap: 'field_subgenre_trap',
    trova: 'field_subgenre_trova',
    vals: 'field_subgenre_vals',
    vallenato: 'field_subgenre_vallenato',
  },
};

export const REFRESH_SERVICES = {
  GET_GUESTS: 'GET_GUESTS',
  GET_TRACKS: 'GET_TRACKS',
  GET_HOSTS: 'GET_HOSTS',
  GET_PLAYER_STATUS: 'GET_PLAYER_STATUS',
  GET_HOST_TRACK: 'GET_HOST_TRACK',
  SET_PROGRESS_SONG: 'SET_PROGRESS_SONG',
  SET_PROGRESS_PREVIEW_SONG: 'SET_PROGRESS_PREVIEW_SONG',
};

export const FINISH_VOTING_IN_SECONDS = 20;

export const BACKGROUND_TASKS = {
  CHECK_ACTIVE_HOST: 'CHECK_ACTIVE_HOST',
};
