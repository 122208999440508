import React from 'react';
import { StyleSheet } from 'react-native';
import { ButtonGroup } from 'react-native-elements';

import theme from '../../theme';

const styles = {
  root: {
    borderWidth: 0,
    backgroundColor: theme.color.black,
  },
  button: {
    backgroundColor: theme.color.black,
  },
  selectedButton: {
    backgroundColor: theme.color.red,
  },
  innerBorder: {
    color: theme.color.grayDark,
  },
  text: {
    color: theme.color.white,
  },
};

const CoverageRadius = ({ value, buttons, handlePress }) => {
  return (
    <ButtonGroup
      onPress={index => handlePress(buttons[index])}
      selectedIndex={buttons.indexOf(value)}
      buttons={buttons}
      containerStyle={styles.root}
      buttonStyle={styles.button}
      innerBorderStyle={styles.innerBorder}
      textStyle={styles.text}
      selectedButtonStyle={styles.selectedButton}
    />
  );
};

export default CoverageRadius;
