import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { result } from 'lodash';

import * as refreshService from '../services/refresh';

import { FINISH_VOTING_IN_SECONDS, REFRESH_SERVICES } from '../constants';
import { selectedHost, selectVotingStatus } from '../selectors';
import { updateVotingStatus } from '../actions';

const UPDATE_RATE_IN_SECONDS = 1;

export const useProgress = (isPlaying, progressMs, durationMs) => {
  const dispatch = useDispatch();
  const votingStatus = useSelector(selectVotingStatus);
  const host = useSelector(selectedHost);
  const [progress, setProgress] = useState(0);
  const duration = Math.round(durationMs / 1000);

  useEffect(() => {
    const stopProgress = () => {
      refreshService.stop(REFRESH_SERVICES.SET_PROGRESS_SONG);
    };

    setProgress(Math.round(progressMs / 1000));
    stopProgress();
    if (isPlaying) {
      refreshService.start(
        REFRESH_SERVICES.SET_PROGRESS_SONG,
        () => setProgress(seconds => seconds + UPDATE_RATE_IN_SECONDS),
        UPDATE_RATE_IN_SECONDS * 1000,
      );
    }
    return stopProgress;
  }, [progressMs, isPlaying]);

  useEffect(() => {
    const hostId = result(host, 'id');
    if (hostId) {
      // only for guest section
      if (votingStatus && progress + FINISH_VOTING_IN_SECONDS > duration) {
        dispatch(updateVotingStatus({ hostId, status: false }));
      }
      if (!votingStatus && progress + FINISH_VOTING_IN_SECONDS < duration) {
        dispatch(updateVotingStatus({ hostId, status: true }));
      }
    }
  }, [progress]);

  return [progress, duration];
};
