import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';

import theme from '../theme';

const Avatar = ({ name, image, size = 40, style }) => {
  const letters = name.trim().split(' ').slice(0, 2).map((w) => w[0]).join('').toUpperCase();

  return (
    <View style={[styles.root, { width: size, height: size, borderRadius: size }, style]}>
      <Text style={styles.text}>{letters}</Text>
      <Image source={image} style={[styles.image, { borderRadius: size }]} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.color.pastel,
    position: 'relative',
    justifyContent: 'center',
  },
  text: {
    color: 'black',
    width: '100%',
    textAlign: 'center'
  },
  image: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  }
});

export default Avatar;
