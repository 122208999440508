import { put, call } from 'redux-saga/effects';

import { mapHttpError } from '../helpers/format';

const filteredActionsToLog = [];

export default function* (saga, action) {
  try {
    yield put({ type: `${action.type}_REQUEST`, meta: { action } });
    const { data } = yield call(saga, action);
    if (filteredActionsToLog.includes(action.type)) {
      console.log('Sagas middleware response', data);
    }
    yield put({ type: `${action.type}_SUCCESS`, meta: { action }, payload: data });
  } catch (error) {
    console.error('Sagas middleware error', action.type, saga, error);
    yield put({
      type: `${action.type}_FAILURE`,
      payload: mapHttpError(error),
      meta: { action },
    });
  }
}
