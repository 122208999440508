import React from 'react';

import Base from './Base';

const xml = `
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5005 25C7.44429 25.0066 2.88338 21.9625 0.949529 17.2907C-0.984319 12.6188 0.0908363 7.24173 3.67248 3.67271C6.82432 0.509449 11.4257 -0.72943 15.7396 0.423732C20.0536 1.57689 23.4231 4.94646 24.5763 9.26049C25.7294 13.5745 24.4906 18.1759 21.3273 21.3278C18.9935 23.6815 15.8151 25.0038 12.5005 25ZM10.6463 16.5822C12.9456 16.5342 15.2119 17.1343 17.1861 18.3138C17.3078 18.3954 17.4511 18.439 17.5977 18.4389C17.8648 18.4322 18.1105 18.291 18.2508 18.0635C18.36 17.8528 18.3789 17.6067 18.3033 17.3817C18.2394 17.2106 18.1063 17.0743 17.9367 17.0063C15.7139 15.6841 13.1645 15.0126 10.5788 15.0683C8.97448 15.0806 7.37637 15.2689 5.81318 15.63C5.40334 15.7393 5.15415 16.154 5.25017 16.5672C5.34694 16.9006 5.64303 17.1371 5.98959 17.1577C6.0561 17.1575 6.12224 17.1478 6.18602 17.1289C7.64779 16.7811 9.14384 16.5977 10.6463 16.5822ZM10.4086 12.8587C13.2216 12.8256 15.9925 13.543 18.436 14.9369C18.5752 15.0381 18.743 15.0924 18.9151 15.092C19.2523 15.0789 19.5629 14.9055 19.7509 14.6254C19.8978 14.411 19.9436 14.1431 19.876 13.8922C19.8303 13.6746 19.6937 13.487 19.5007 13.3767C16.7378 11.7811 13.5977 10.9555 10.4074 10.9857C8.72554 10.9754 7.05127 11.2115 5.43784 11.6864C4.94209 11.8496 4.66524 12.3767 4.81227 12.8775C4.935 13.2822 5.31695 13.5518 5.73937 13.5318C5.82916 13.5327 5.91871 13.5222 6.00586 13.5006C7.43189 13.0623 8.91682 12.8458 10.4086 12.8587ZM10.6451 8.99514C13.853 8.90261 17.0309 9.63497 19.8748 11.1221C20.0665 11.2269 20.2819 11.2807 20.5003 11.2785C20.8883 11.2866 21.258 11.1142 21.5013 10.8118C21.6525 10.5351 21.6919 10.2109 21.6114 9.90599C21.536 9.60178 21.3387 9.34204 21.0659 9.18782C17.8589 7.4833 14.2657 6.63682 10.6351 6.73055C8.62478 6.70986 6.62134 6.9684 4.68215 7.49876C4.13745 7.66722 3.79973 8.21083 3.89004 8.7738C3.98036 9.33677 4.47117 9.74746 5.04123 9.73708C5.15222 9.73717 5.26264 9.72116 5.36903 9.68954C7.08469 9.20309 8.86198 8.96876 10.6451 8.99389V8.99514Z" fill="white"/>
</svg>
`;

export default function SpotifyIcon(props) {
  return <Base xml={xml} {...props} />;
}
