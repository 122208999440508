import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View, Platform } from 'react-native';

import IconButton from './IconButton';
import Spinner from './Spinner';
import theme from '../theme';

const Button = ({
  title,
  style,
  innerStyle,
  disabled,
  loading,
  loadingRight,
  preContent,
  postContent,
  color = 'primary',
  variant = 'large',
  icon,
  proceed,
  leftAligned,
  hint,
  ...props
}) => {
  const showLoading = (position = 'left') => (
    <Spinner
      color={styles[`${color}TextColor`].color}
      style={styles[`${position}Spinner`]}
      size="small"
    />
  );

  const showIcon = value => {
    if (typeof value === 'string') {
      return (
        <IconButton
          style={[styles.icon, styles[`${variant}Icon`]]}
          color="white"
          icon={value}
          size={16}
        />
      );
    }
    if (typeof value === 'object' && !value.props) {
      return (
        <IconButton
          color="white"
          size={16}
          {...value}
          style={[styles.icon, styles[`${variant}Icon`], value.style]}
        />
      );
    }

    return value;
  };

  return (
    <TouchableOpacity
      {...props}
      disabled={!!disabled}
      style={[
        styles.root,
        styles[`${color}Color`],
        styles[icon ? `${variant}VariantWithIcon` : `${variant}Variant`],
        disabled ? styles.disabled : {},
        style,
      ]}
    >
      {preContent}
      {icon && showIcon(icon)}
      <View
        style={[
          styles.inner,
          variant === 'tight' ? styles.tightVariantInner : null,
          leftAligned ? styles.innerLeftAlign : null,
          styles.innerStyle,
        ]}
      >
        {loading && showLoading()}
        <View>
          <Text style={[styles[`${color}TextColor`], styles.text, styles[`${variant}VariantText`]]}>
            {typeof title === 'string' ? title : ''}
          </Text>
          {hint ? <Text style={[styles[`${color}TextColor`], styles.hint]}>{hint}</Text> : null}
        </View>
        {postContent}
        {loadingRight && showLoading('right')}
      </View>
      {proceed && <IconButton style={styles.icon} color="white" icon="chevron-right" size={16} />}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    borderWidth: 0,
    marginBottom: 10,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  inner: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  innerLeftAlign: {
    justifyContent: 'flex-start',
    paddingLeft: 10,
  },
  icon: {
    margin: 0,
    padding: 0,
  },
  text: {
    fontSize: theme.font.button.fontSize,
    fontFamily: theme.font.button.fontFamily,
    textAlign: 'center',
  },
  hint: {
    fontSize: 12,
    textAlign: 'center',
  },
  leftSpinner: {
    marginRight: 10,
  },
  rightSpinner: {
    marginLeft: 10,
  },
  disabled: {
    opacity: 0.5,
  },
  largeVariant: {
    paddingVertical: 16,
    paddingHorizontal: 22,
    width: '100%',
  },
  largeVariantWithIcon: {
    paddingVertical: 9.5,
    paddingHorizontal: 22,
    width: '100%',
  },
  smallVariant: {
    paddingVertical: 8,
    paddingHorizontal: 22,
    width: '50%',
  },
  tightVariant: {
    paddingVertical: 6,
    paddingHorizontal: 12,
  },
  tightVariantWithIcon: {
    paddingVertical: 6,
    paddingHorizontal: 12,
  },
  tightIcon: {
    marginRight: 5,
    marginLeft: -5,
  },
  tightVariantText: {
    lineHeight: 24,
  },
  tightVariantInner: {
    flex: Platform.OS === 'web' ? 1 : 0,
  },
  smallVariantText: {
    fontSize: 14,
  },
  fitVariant: {
    paddingVertical: 16,
    paddingHorizontal: 22,
    maxWidth: 250,
    alignSelf: 'center',
  },
  fitVariantWithIcon: {
    paddingVertical: 9.5,
    paddingHorizontal: 22,
    maxWidth: 250,
    alignSelf: 'center',
  },
  primaryColor: {
    backgroundColor: theme.primaryButton.bg1,
  },
  secondaryColor: {
    backgroundColor: theme.secondaryButton.bg1,
  },
  tertiaryColor: {
    backgroundColor: theme.tertiaryButton.bg1,
  },
  quaternaryColor: {
    backgroundColor: 'transparent',
    borderColor: theme.secondaryButton.bg1,
    borderWidth: 1,
  },
  spotifyColor: {
    backgroundColor: theme.spotifyButton.bg1,
  },
  youtubeColor: {
    backgroundColor: theme.youtubeButton.bg1,
  },
  itunesColor: {
    backgroundColor: theme.itunesButton.bg1,
  },
  primaryTextColor: {
    color: theme.primaryButton.text,
  },
  secondaryTextColor: {
    color: theme.secondaryButton.text,
  },
  tertiaryTextColor: {
    color: theme.tertiaryButton.text,
  },
  quaternaryTextColor: {
    color: theme.secondaryButton.text,
  },
  spotifyTextColor: {
    color: theme.spotifyButton.text,
  },
  youtubeTextColor: {
    color: theme.youtubeButton.text,
  },
  itunesTextColor: {
    color: theme.itunesButton.text,
  },
});

export default Button;
