import * as React from 'react';

export const navigationRef = React.createRef(null);

export const getNavigation = () => navigationRef.current;

export const waitFortNavigation = cb => {
  if (navigationRef.current) {
    cb(navigationRef.current);
  } else {
    setTimeout(() => waitFortNavigation(cb), 500);
  }
};
