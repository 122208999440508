import { call, takeEvery, all } from 'redux-saga/effects';

import ServerConfigService from '../services/serverConfig';
import * as constants from '../constants';
import handleMiddleware from './middleware';

function* getServerConfigWorker() {
  return yield call(ServerConfigService.load);
}

export default function* watchAll() {
  yield all([takeEvery(constants.API_SERVER_CONFIG, handleMiddleware, getServerConfigWorker)]);
}
