export const SPRING = {
  duration: 700,
  update: { type: 'spring', springDamping: 0.4 },
};

export const SPRING_SOFT = {
  duration: 500,
  update: { type: 'spring', springDamping: 0.8 },
};

export const EASE = {
  create: { property: 'opacity', type: 'easeInEaseOut' },
  delete: { property: 'opacity', type: 'easeInEaseOut' },
  duration: 300,
  update: { type: 'easeInEaseOut' },
};
