import { useRef } from 'react';
import { Platform } from 'react-native';

const backgroundImages = [require('../assets/bg.png')];
const backgroundImagesWeb = [require('../assets/bg-web.png')];

export const useBackgroundImage = (index = 0, random) => {
  const images = Platform.OS !== 'web' ? backgroundImages : backgroundImagesWeb;
  
  if (random) {
    index = useRef(Math.floor(Math.random() * images.length)).current;
  }
  return images[index];
};
