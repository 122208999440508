import { filter } from 'lodash';

import * as constants from '../../constants';
import { error as toastError, success as toastSuccess } from '../../helpers/toast';
import { t } from '../../services/i18n';

const initialState = {
  loading: false,
  updating: null,
  data: [],
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.API_GET_BLACKLIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.API_ALLOW_SONG_REQUEST: {
      return {
        ...state,
        updating: payload,
      };
    }
    case constants.API_GET_BLACKLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: payload,
      };
    }
    case constants.API_ALLOW_SONG_SUCCESS: {
      const { allowed, uri, message } = payload;
      if (message) {
        toastSuccess(t(message));
      }
      let blacklist = [...state.data];
      if (allowed) {
        blacklist = filter(blacklist, track => track.uri !== uri);
      }

      return {
        ...state,
        updating: null,
        data: blacklist,
      };
    }
    case constants.API_GET_BLACKLIST_FAILURE: {
      toastError(payload);
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case constants.API_ALLOW_SONG_FAILURE: {
      toastError(payload);
      return {
        ...state,
        updating: null,
        error: payload,
      };
    }
    default:
      return state;
  }
};
