import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

import ContentWrapper from '../../components/ContentWrapper';
import DayReport from '../../components/report/DayReport';
import Guest from '../../components/host/Guest';
import HelpButton from '../../components/HelpButton';
import MonthReport from '../../components/report/MonthReport';
import Text from '../../components/Text';
import RefreshView from '../../components/RefreshView';
import SkeletonWrapper from '../../components/skeleton/SkeletonWrapper';
import skeleton from '../../helpers/skeleton';
import theme from '../../theme';
import * as analytics from '../../services/analytics';
import { getGuests } from '../../actions';
import { selectGuests } from '../../selectors';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import { useLoadingStatus } from '../../hooks/useLoadingStatus';
import { t } from '../../services/i18n';

const Guests = () => {
  const { data, loading } = useSelector(selectGuests);
  const loadingStatus = useLoadingStatus(loading);
  const dispatch = useDispatch();

  useScreenFocus(() => {
    analytics.tab('guests');
  });

  const handleGetGuests = () => dispatch(getGuests());

  useEffect(() => {
    handleGetGuests();
  }, []);

  const { report, guests } = data;

  return (
    <ContentWrapper rootStyle={styles.root}>
      <View style={styles.header}>
        <Text variant="title" style={styles.title}>
          {t('screen_title_guests')}
        </Text>
        <HelpButton text={t('hint_help_guests_screen')} />
      </View>
      {loadingStatus.initialLoading ? (
        <SkeletonWrapper {...skeleton.guest} repeat={3} />
      ) : (
        <RefreshView
          onRefresh={handleGetGuests}
          refreshing={loading}
        >
          <View style={styles.inner}>
            <Text variant="subtitle">{t('text_guest_list')}</Text>
            {guests && guests.length ? (
              map(guests, guest => <Guest key={guest.email} {...guest} />)
            ) : (
              <Text spacingBelow variant="body">
                {t('text_guest_list_empty')}
              </Text>
            )}
            {report && <DayReport report={report.dayReport} />}
            {report && <MonthReport report={report.monthReport} />}
          </View>
        </RefreshView>
      )}
    </ContentWrapper>
  );
};

const styles = StyleSheet.create({
  root: {
    height: '100%',
    backgroundColor: theme.black.bg1,
    paddingTop: theme.spacing(1),
  },
  inner: {
    paddingBottom: theme.spacing(1),
  },
  title: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
  },
});

export default Guests;
