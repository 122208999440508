import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Text from '../../components/Text';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import ContentWrapper from '../../components/ContentWrapper';
import * as analytics from '../../services/analytics';
import { t } from '../../services/i18n';
import {
  selectCurrentTrack,
  selectedHost,
  selectGuestAvailableCredit,
  selectPaidSong,
  selectVotingStatus,
} from '../../selectors';
import { sendCredit } from '../../actions';
import { useProgress } from '../../hooks/progress';
import { MODES } from '../../constants';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import Space from '../../components/Space';
import { formatSongTime } from '../../helpers/format';
import theme from '../../theme';

const UseCredit = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const paidSong = useSelector(selectPaidSong);
  const currentTrack = useSelector(selectCurrentTrack);
  const votingStatus = useSelector(selectVotingStatus);
  const host = useSelector(selectedHost) || {};
  const [progress, duration] = useProgress(true, currentTrack.progressMs, currentTrack.durationMs);
  const credit = useSelector(selectGuestAvailableCredit);
  const remaining = duration - progress;
  const { mode } = host;

  const { song, hostId } = route.params;

  useScreenFocus(() => {
    analytics.screen('useCredit');
  });

  useEffect(() => {
    if (!votingStatus) {
      navigation.goBack();
    }
  }, [votingStatus]);

  const handleUse = () => {
    dispatch(
      sendCredit({
        hostId,
        uri: song.uri,
      }),
    );
    navigation.goBack();
  };

  const getContent = () => {
    if (paidSong) {
      if (mode === MODES.INSTANT) {
        return (
          <View style={styles.root}>
            <Text variant="title">{t('text_song_already_paid_title', song)}</Text>
            <Text>{t('text_song_already_paid_instructions_instant', song)}</Text>
          </View>
        );
      }
      if (remaining < 0) {
        return <Loading />;
      }
      return (
        <>
          <View style={styles.root}>
            <Text variant="title">{t('text_song_already_paid_title', song)}</Text>
            <Text>
              {t('text_song_already_paid_instructions', {
                artist: song.artist,
                name: song.name,
                time: formatSongTime(remaining),
              })}
            </Text>
          </View>
        </>
      );
    }
    return (
      <>
        <View style={styles.root}>
          <Text variant="title">{t('text_use_credit_title', song)}</Text>
          <Text>{t('text_use_credit_instructions', song)}</Text>
          <Space />
          <Text>{t('text_use_credit_instructions_2', { now: credit, then: credit - 1 })}</Text>
        </View>
        <Button
          icon={{ icon: 'coin', external: true, size: 25 }}
          color="tertiary"
          title={t('action_use_credit_now')}
          onPress={handleUse}
        />
      </>
    );
  };

  return (
    <Layout title={t('screen_title_use_credit')} includeCredit>
      <ContentWrapper>{getContent()}</ContentWrapper>
    </Layout>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingTop: theme.spacing(1),
  },
});

export default UseCredit;
