import React from 'react';
import { IconButton as RnpIconButton } from 'react-native-paper';

import icons from '../services/icons';

const IconButton = ({ external, icon, color, ...props }) => {
  const uri = external ? icons[icon] : icon;
  return <RnpIconButton margin={0} {...props} icon={uri} iconColor={color}/>;
};

export default IconButton;
