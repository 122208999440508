import React from 'react';
import { StyleSheet, View } from 'react-native';

import Text from '../../components/Text';
import { useServerConfig } from '../../hooks/useServerConfig';
import { t } from '../../services/i18n';
import theme from '../../theme';
import HandlingSubscription from './HandlingSubscription';

const NoSubscription = ({ name, reload }) => {
  const [freeMaxUsersAllowed] = useServerConfig('subscription.freeMaxUsersAllowed');

  return (
    <View style={styles.container}>
      <Text variant="title" centered>
        {t('text_no_subscription', { name })}
      </Text>
      <View style={styles.inner}>
        <Text spacingBelow>
          {t('text_no_subscription_instructions', { maxGuests: freeMaxUsersAllowed })}
        </Text>
        <HandlingSubscription reload={reload} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.black.bg1,
  },
  inner: {
    flex: 1,
  },
});

export default NoSubscription;
