import * as Location from 'expo-location';
import { result } from 'lodash';
import moment from 'moment';

import { error as toastError } from '../helpers/toast';

let lastLocation = null;

export const getCurrentLocation = async () => {
  const { status } = await Location.requestPermissionsAsync();
  if (status === 'granted') {
    let location;
    //@todo: find a better way to solve this logic
    try {
      location = await Location.getCurrentPositionAsync({
        accuracy: Location.Accuracy.High,
      });
    } catch (error) {
      location = await Location.getLastKnownPositionAsync();
      const timestamp = result(location, 'timestamp');
      if (!timestamp || moment().diff(timestamp, 'hours') >= 24) {
        toastError(t('error_message_incorrect_last_known_position'));
      }
    }
    if (location && location.coords) {
      const {
        coords: { latitude, longitude, accuracy },
      } = location;
      lastLocation = location;
      return {
        latitude,
        longitude,
        accuracy,
      };
    }
    return null;
  }
};

export const getLastLocation = () => {
  if (lastLocation) {
    return lastLocation;
  }
  return getCurrentLocation();
};
