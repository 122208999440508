import { useState, useCallback } from 'react';

import config from '../config';

export const useDebug = (debug, onPress) => {
  const [countPress, setCountPress] = useState(0);

  const handlePress = useCallback((e) => {
    if (onPress) {
      onPress(e);
    }

    if (debug) {
      const count = countPress + 1;
      setCountPress(count);

      if (count === 1) {
        setTimeout(() => {
          setCountPress(0);
        }, 5000);
      }

      if (count === 10) {
        alert(JSON.stringify(config));
      }
    }
  }, [debug, onPress, countPress]);

  return { handlePress };
};
