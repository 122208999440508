import React from 'react';
import { StyleSheet, View } from 'react-native';

import Text from './Text';
import Icon from './Icon';
import theme from '../theme';

const TextIcon = ({ text, icon, iconSize=80 }) => (
  <View style={styles.root}>
    <Icon icon={icon} size={iconSize} />
    <Text spacingBelow variant="subtitle" centered>
      {text}
    </Text>
  </View>
);

const styles = StyleSheet.create({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export default TextIcon;
