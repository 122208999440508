export default {
  // Screen titles
  screen_title_default: 'Loudy - Elegí la música',
  screen_title_playlist: 'Playlist',
  screen_title_settings: 'Ajustes',
  screen_title_guests: 'Invitados',
  screen_title_subscription: 'Suscripción',
  screen_title_credit: 'Crédito',
  screen_title_account: 'Mi Cuenta',
  screen_title_import_playlist: 'Importar playlist',
  screen_title_select_host: 'Seleccionar Anfitrión',
  screen_title_search_library: 'Agregar canciones',
  screen_title_sync_with_spotify: 'Hola',
  screen_title_blacklist: 'Lista Negra',
  screen_title_select_card: 'Medios de pago',
  screen_title_credit_select_plan: 'Comprar créditos',
  screen_title_subscription_select_plan: 'Seleccioná tu plan',
  screen_title_use_credit: 'Usar Crédito',
  screen_title_loudy_playlists: 'Playlists de Loudy',
  screen_title_your_playlists: 'Tus Playlists',
  screen_title_spotify_playlists: 'Playlists de Spotify',
  
  // Subtitles
  subtitle_account: 'Cuenta',
  subtitle_last_movements: 'Últimos Movimientos',

  // Actions
  action_onboarding_1: 'Siguiente',
  action_onboarding_2: 'Siguiente',
  action_onboarding_3: 'Empezar',
  action_host_onboarding_1: 'Siguiente',
  action_host_onboarding_2: 'Siguiente',
  action_host_onboarding_3: 'Empezar',
  action_guest_onboarding_1: 'Siguiente',
  action_guest_onboarding_2: 'Siguiente',
  action_guest_onboarding_3: 'Empezar',
  action_cancel: 'Cancelar',
  action_yes: 'Sí',
  action_no: 'No',
  action_vote: 'Votar',
  action_play: 'Play',
  action_play_now: 'Reproducir ahora',
  action_preview: 'Preview',
  action_save: 'Guardar',
  action_close: 'Cerrar',
  action_ok: 'Aceptar',
  action_import: 'Importar',
  action_share: 'Compartir',
  action_skip: 'Saltar',
  action_add: 'Agregar',
  action_add_and_vote: 'Agregar y votar',
  action_view_more: 'Ver más',
  action_view_less: 'Ver menos',
  action_login_as_host: 'Iniciar como anfitrión',
  action_login_as_guest: 'Iniciar como invitado',
  action_login_with_google: 'Iniciar sesión con Google',
  action_login_with_itunes: 'Acceder con iTunes',
  action_login_with_spotify: 'Acceder con Spotify',
  action_login_with_youtube_music: 'Acceder con Youtube Music',
  action_logout: 'Cerrar sesión',
  action_my_credit: 'Mis créditos',
  action_my_account: 'Mi cuenta',
  action_import_playlist: 'Importar playlist',
  action_add_song: 'Agregar canción',
  action_add_songs: 'Agregar canciones',
  action_delete_song: 'Borrar canción',
  action_forbid_song: 'Bloquear canción',
  action_allow_song: 'Permitir canción',
  action_join_with_qr: 'Unirse con código QR',
  action_buy_credit: 'Comprar Crédito',
  action_cancel_confirmation: 'Quieres cancelar tus cambios?',
  action_see_blacklist: 'Ver Lista Negra',
  action_image_picker_from_computer: 'Elegir imagen',
  action_image_picker_from_camera: 'Cámara',
  action_image_picker_from_phone: 'Galería',
  action_select_credit_card: 'Seleccioná una Tarjeta',
  action_add_new_card: 'Agregar nueva tarjeta',
  action_add_new_card_hint: 'Crédito/Débito',
  action_streaming_logout: 'Cerrar sesión de Spotify',
  action_account_logout: 'Cerrar sesión de Loudy',
  action_account_removal: 'Eliminar mi cuenta',
  action_subscribe_now: 'Suscribirme ahora',
  action_delete_card: 'Eliminar',
  action_dialog_play_song_title: 'Forzar reproducción de la canción',
  action_use_credit_now: 'Usar 1 crédito ahora',
  action_crop_image: 'Recortar',
  action_activate: 'Activar',
  action_deactivate: 'Desactivar',
  action_try_again: 'Intentar nuevamente',
  action_scan_again: 'Escanear nuevamente',
  action_download_merch: 'Descargar Merchandising',
  action_open: 'Abrir',
  action_clean_playlist: 'Vaciar',
  action_search_playlists: 'Buscar playlist',

  // Status
  status_offline: 'Sin Conexión',
  status_warning: 'Advertencia',

  // Notification
  notification_hostmode_title: 'Loudy',
  notification_hostmode_body: 'Loudy está activo en modo anfitrión',

  // Messages and text
  text_host_onboarding_1: 'Para empezar con Loudy, sicronizá tu servicio de Spotify',
  text_host_onboarding_2: 'Seleccioná un modo de reproducción entre Instantáneo y Playlist',
  text_host_onboarding_3: 'Cargá tu playlist y disfrutá. Todos tus invitados son DJ’s',
  text_host_onboarding_extra_1:
    'Te pediremos que inicies sesión con Spotify para permitirnos controlar las canciones que se reproducen. \nCuando quieras podrás revocar el permiso cerrando sesión. \nSin este permiso, Loudy no puede funcionar correctamente.',
  text_host_onboarding_extra_2:
    'Modo Instántaneo: \nLos invitados pueden votar la siguiente canción durante la canción actual. Las opciones serán una pequeña lista de canciones entre las de tu playlist. La más votada al terminar la canción actual será la siguiente en sonar. \n\nModo Playlist: \nTu Playlist se irá ordenando en base a los votos emitidos por los invitados. Ellos pueden votar entre todas las canciones de tu Playlist.',
  text_guest_onboarding_1: 'Para empezar busca un anfitrión cerca de tu ubicación',
  text_guest_onboarding_2: 'Elegí tu canción favorita y votala para que suene primero',
  text_guest_onboarding_3: 'Comprá créditos para escuchar tu canción elegida en primer lugar',
  text_clear_playlist: 'Al importar una nueva playlist, la actual sera reemplazada',
  text_import_small_playlist_singular:
    '{name} contiene 1 canción. Te recomendamos que tenga un mínimo de {recommendation} canciones. Confirmás igual este playlist?',
  text_import_small_playlist_plural:
    '{name} contiene {number} canciones. Te recomendamos que tenga un mínimo de {recommendation} canciones. Confirmás igual este playlist?',
  text_no_hosts: 'No hay ningún evento cerca tuyo',
  text_low_accuracy: 'Tu dispositivo está limitando la precisión de tu ubicación GPS. Podés configurar el nivel de precisión en la configuracion de Ubicación, o escanear el código QR del lugar usando el botón más abajo.',
  text_available_hosts: 'Anfitriones Disponibles:',
  text_close_hosts: 'Anfitriones Cercanos:',
  text_host_not_within_radius_message: '{name} se encuentra cerca, pero no lo suficiente. Tenés que ir al lugar para poder unirte! El lugar está a {distance} metros.',
  text_no_song_playing: 'Spotify no está activo',
  text_no_song_playing_instructions: 'Presiona aquí para abrir Spotify',
  text_no_premium_account: 'No tienes una cuenta de Spotify premium',
  text_no_premium_account_instructions: 'Seleccioná un plan de Spotify premium',
  text_host_not_playing: 'El anfitrión no esta reproduciendo música aún.',
  text_sync_with_spotify_title: 'Debes iniciar sesión en tu cuenta de Spotify.',
  text_sync_with_spotify_instructions:
    'Recuerda conceder los permisos necesarios para que podamos sincronizar Loudy con Spotify',
  text_empty_playlist:
    'Actualmente no tienes canciones en tu playlist.\n\nPuedes importar una o agregar las canciones manualmente.',
  text_empty_blacklist: 'Actualmente no tienes ninguna canción prohibida',
  text_loading_playlist: 'Cargando playlist...',
  text_requesting_camera_permission: 'Pidiendo permisos para usar la cámara',
  text_credit_description: 'Seleccioná un plan de créditos',
  text_credit_instructions:
    'Seleccioná el plan de créditos que quieres comprar. Puedes usar cada crédito para forzar la reproducción de las canciones que más te gustan. \n\nLos créditos pertenecen a tu cuenta y podés usarlos en cualquier anfitrión.',
  text_you_have_available: 'Tienes disponible',
  text_credits_unit_plural: 'créditos',
  text_credits_unit_singular: 'crédito',
  text_added: 'Sumaste',
  text_redeemed: 'Canjeaste',
  text_redeemed_song: 'Canjeaste ({name})',
  text_vote: 'Votación',
  text_votes: 'Votos',
  text_unit_meters: 'Metros',
  text_unit_feet: 'Pies',
  text_mode_instant: 'Instantáneo',
  text_mode_playlist: 'Playlist',
  text_location_mode_current: 'Ubicación de este dispositivo',
  text_location_mode_fixed: 'Ubicación personalizada',
  text_connected_at: 'Conectado',
  text_credit_plan_name: '{credits} Créditos - ${price}',
  text_credit_plan_name_short: '{credits} Créditos',
  text_no_subscription: '¡Hola {name}!',
  text_no_subscription_instructions:
    'Aún no tenés ninguna suscripción activa. Eliminá el limite de {maxGuests} invitados subscribiendote a Loudy Premium.',
  text_credit_card_name: 'Tarjeta de Crédito {brand} ${last4}',
  text_subscription_title: 'Suscrito a Loudy Premium',
  text_subscription_active_title: 'Loudy Premium',
  text_subscription_active_monthly: 'Subscripción Mensual',
  text_subscription_active_yearly: 'Subscripción Anual',
  text_subscription_active_per_month: 'por mes',
  text_subscription_active_per_year: 'por año',
  text_subscription_merch: 'Merchandising Imprimible',
  text_subscription_admin: 'Adminsitrar mi suscripción',
  text_subscription_bullet_1: 'Sin límite de invitados',
  text_subscription_instructions_1:
    'Estás suscripto como usuario premium.',
  text_subscription_instructions_1_month:
    'Estás suscripto como usuario premium con el plan mensual.',
  text_subscription_instructions_1_year: 'Estás suscripto como usuario premium con el plan anual.',
  text_subscription_instructions_2:
    'El cobro se renueva de forma automática, pero podés cambiar, pausar o cancelar tu plan cuando quieras.',
  text_subscription_instructions_2b:
    'Estas utilizando una promoción que te permite disfrutar de Loudy Premium completamente gratis.',
  text_subscription_instructions_4:
    'Tu plan es 100% gratuito de por vida. Gracias por confiar en nosotros!',
  text_subscription_canceled_title: 'Cuenta Premium Cancelada',
  text_subscription_current: 'continuar con mi plan actual',
  text_subscription_other: 'cambiar a este plan',
  text_subscription_card_current: 'continuar con mi tarjeta actual',
  text_subscription_card_other: 'cambiar a esta tarjeta',
  text_subscription_canceled_instructions:
    'Tu plan de suscripción continuará activo hasta el {endDate}.\n\nA partir de esa fecha dejarás de ser usuario premium y volverás a tener el límite de {maxGuests} invitados gratis.\n\nPodés volver a suscribirte cuando quieras.',
  text_subscription_instructions:
    'Tener un plan premium te permite eliminar el límite de {maxGuests} invitados del plan gratuito y acceder al material descargable Loudy para tu negocio.\n\nAmbos planes ofrecen el mismo servicio, pero ahorrás 2 cuotas pagando de forma anual.',
  text_subscription_name_monthly: 'Mensual - {price}/mes',
  text_subscription_name_yearly: 'Anual - {price}/año',
  text_subscription_trial_title: 'Suscripción en Período de Prueba',
  text_subscription_trial_instructions_1:
    'Actualmente te encuentras en Período de Prueba. El Período de Prueba terminará el {end}. Una vez termine tendrás un límite de {maxGuests} invitados.',
  text_subscription_trial_instructions_2:
    'Igualmente puedes suscribirte en cualquier momento desde el siguiente botón.',
  text_subscription_trial_expired_title: 'Período de Prueba terminado',
  text_subscription_trial_expired_instructions:
    'El Período de Prueba terminó el dia {end}. Ahora posees un límite de {maxGuests} invitados. Si quieres quitar este límite, puedes suscribirte ahora.',
  text_subscription_web_title: 'Subscripción',
  text_subscription_web_instructions:
    'Únicamente se puede manejar la suscripción desde nuestra App. Conseguila en Apple o Google.',
  text_dialog_play_song_no_credit:
    'Actualmente no posees crédito para forzar la reproducción de la canción. Si deseas que "{name}" sea la próxima canción en sonar, compra un poco de crédito.',
  text_use_credit_title: '{artist} - {name}',
  text_use_credit_instructions:
    'Usa crédito para forzar la reproducción de "{name}" luego de la canción actual. Esto permitirá temporalmente ignorar la votación y escuchar la canción de inmediato. Cada canción cuesta un crédito. ¿Deseas utilizar uno ahora para esta canción?',
  text_use_credit_instructions_2:
    'Actualmente tienes {now} créditos. Luego de pagar esta canción tendrás {then} créditos.',
  text_song_already_paid_title: 'Ya hay otra canción pagada',
  text_song_already_paid_instructions:
    'No puedes forzar la reproducción de "{artist} - {name}" aún porque ya hay otra canción pagada. Espera a que esa comience y podrás hacerlo. La canción termina en {time}.',
  text_song_already_paid_instructions_instant:
    'No puedes forzar la reproducción de "{artist} - {name}" aún porque ya hay otra canción pagada. Espera a que esa termine y podrás hacerlo.',
  text_free_limit_exceeded: 'Límite de invitados alcanzado',
  text_free_limit_exceeded_description:
    'El anfitrión ha alcanzado el límite de invitados de la versión gratuita',
  text_dialog_paid_explanation_title: 'Esta canción fue pagada',
  text_dialog_paid_explanation_instructions:
    'Esta canción ocupó esta posición en el playlist porque un invitado uso crédito para forzar su reproducción. Tu también puedes hacer lo mismo, solo compra crédito y elige la canción que quieres escuchar. Ten en cuenta que no se puede comprar una canción cuando ya hay otra pagada.',
  text_dialog_paid_explanation_title_2: 'Ya pagaste la canción',
  text_dialog_paid_explanation_instructions_2:
    'La canción será reproducida cuando termine la canción actual. Ten en cuenta que no se puede comprar una canción cuando ya hay otra pagada.',
  text_artist: 'Artista',
  text_album: 'Álbum',
  text_duration: 'Duración',
  text_popularity: 'Popularidad',
  text_genres: 'Géneros',
  text_connected_guests_singular: '1 invitado',
  text_connected_guests_plural: '{number} invitados',
  text_count_tracks_singular: '1 canción',
  text_count_tracks_plural: '{number} canciones',
  text_min_tracks_recommendation:
    'Te recomendamos que la playlist tenga un mínimo de {number} canciones',
  text_add_card_title: 'Agregar tarjeta',
  text_host_mode_on:
    'Al estar activo permites que Loudy se sincronice con Spotify para usar la app correctamente.',
  text_host_mode_off:
    'Al estar inactivo Spotify vuelve a su normal funcionamiento. Debes volver a activarlo para usar Loudy.',
  text_report_guests_in_week: 'Invitados en la semana',
  text_report_guests_in_year: 'Invitados en el año',
  text_guest_list: 'Lista de invitados',
  text_guest_list_empty: 'No hay invitados actualmente',
  text_small_sunday: 'Do',
  text_small_monday: 'Lu',
  text_small_tuesday: 'Ma',
  text_small_wednesday: 'Mi',
  text_small_thursday: 'Ju',
  text_small_friday: 'Vi',
  text_small_saturday: 'Sa',
  text_modal_title_filtered_songs: 'Canción no permitida',
  text_modal_subtitle_1_filtered_songs:
    'Esta canción fue excluida para mejorar la experiencia por el siguiente motivo:',
  text_modal_title_account_removal: 'Confirma que desea eliminar su cuenta de Loudy?',
  text_modal_subtitle_account_removal: 'Elimina tu cuenta y toda tu actividad de nuestras bases de datos. Perderas todo lo relacionado con Loudy. Esta acción no es reversible. Deberás crear tu cuenta nuevamente para seguir usando Loudy.',
  text_track_unavailable_reason_no_album: 'Sin álbum',
  text_track_unavailable_reason_minimum: 'Mínimo 1m',
  text_track_unavailable_reason_maximum: 'Máximo 6m',
  text_track_unavailable_reason_not_popular: 'No popular',
  text_track_unavailable_reason_blacklisted: 'En lista negra',
  text_track_unavailable_reason_excluded_genre: 'Género excluído',
  text_track_unavailable_reason_explicit_language: 'Lenguaje explícito',
  text_track_unavailable_reason_already_added: 'Ya agregada',
  text_notification_host_mode_title: 'Loudy está en modo Anfitrión',
  text_notification_host_mode_sub: 'Modo Anfitrión',
  text_notification_host_mode_message:
    'Loudy está controlando tu Spotify App de acuerdo a tus preferencias y la de tus invitados',
  text_clean_playlist_title: 'Vaciar playlist',
  text_clean_playlist_description:
    'Estas a punto de borrar las canciones de la playlist. Estás seguro?',
  text_checking_credits: 'Chequeando tus créditos, aguarda unos segundos 😀',

  // Fields and form sections
  field_name: 'Nombre *',
  field_logo: 'Logo',
  field_location: 'Ubicaciôn',
  field_coverage_radius: 'Radio de cobertura (metros)',
  field_mode: 'Modo',
  field_notifications: 'Notificaciones',
  field_provider: 'Servicio de Streaming de Musica *',
  field_add_songs: 'Agregar nuevas canciones',
  field_allow_add: 'Agregar canciones',
  field_allow_add_all_genres: 'Todos los géneros habilitados',
  field_allow_add_playlist_genres: 'Sólo los géneros de mi playlist',
  field_max_duration: 'Tiempo máximo de duración de canciones (en minutos)',
  field_explicit: 'Contenido explícito',
  field_allowed_genres: 'Lista de generos permitidos',
  field_genre_super_power: 'Super Power',
  field_genre_energy: 'Energía',
  field_genre_positive: 'Positiva',
  field_genre_ambient: 'Ambiente',
  field_genre_party: 'Fiesta',
  field_genre_love: 'Amor',
  field_genre_fun: 'Diversión',
  field_subgenre_country: 'Country',
  field_subgenre_criollo: 'Criollo',
  field_subgenre_cumbia: 'Cumbia',
  field_subgenre_disco: 'Disco',
  field_subgenre_dubstep: 'Dubstep',
  field_subgenre_electronic: 'Electrónica',
  field_subgenre_flamenco: 'Flamenco',
  field_subgenre_folk: 'Folk',
  field_subgenre_funk: 'Funk',
  field_subgenre_garage: 'Garage',
  field_subgenre_rock_gospel: 'Rock Gospel',
  field_subgenre_heavy_metal: 'Heavy Metal',
  field_subgenre_hip_hop: 'Hip Hop',
  field_subgenre_indie: 'Indie',
  field_subgenre_jazz: 'Jazz',
  field_subgenre_merengue: 'Merengue',
  field_subgenre_pop: 'Pop',
  field_subgenre_polka: 'Polka',
  field_subgenre_punk: 'Punk',
  field_subgenre_ranchera: 'Ranchera',
  field_subgenre_rap: 'Rap',
  field_subgenre_reggae: 'Reggae',
  field_subgenre_reggaeton: 'Reggaeton',
  field_subgenre_rumba: 'Rumba',
  field_subgenre_rhythm_and_blues: 'Rhythm and Blues',
  field_subgenre_rock: 'Rock',
  field_subgenre_rock_and_roll: 'Rock and Roll',
  field_subgenre_salsa: 'Salsa',
  field_subgenre_samba: 'Samba',
  field_subgenre_ska: 'Ska',
  field_subgenre_son: 'Son',
  field_subgenre_soul: 'Soul',
  field_subgenre_swing: 'Swing',
  field_subgenre_tango: 'Tango',
  field_subgenre_trap: 'Trap',
  field_subgenre_trova: 'Trova',
  field_subgenre_vals: 'Vals',
  field_subgenre_vallenato: 'Vallenato',
  field_candidates: 'Número de canciones para votar',
  field_search_songs: 'Busca por título, álbum o artista',
  field_host_qr_code: 'Código QR',
  field_only_allow_join_from_qr: 'Ingreso sólo con QR',
  field_black_list: 'Lista Negra',
  field_streaming_session: 'Sesión de Spotify',
  field_account_session: 'Sesión de Loudy',
  field_account_credits: 'Créditos Loudy',
  field_account_logout: 'Sesión de Loudy',
  field_account_removal: 'Eliminar Cuenta de Loudy',
  field_account_removal_warning: 'Elimina tu cuenta y toda tu actividad de nuestras bases de datos. Perderas todo lo relacionado con Loudy. Esta acción no es reversible. Deberás crear tu cuenta nuevamente para seguir usando Loudy.',
  field_allow_receive_notifications: 'Recibir notificaciones',
  field_filter_songs: 'Filtrar canciones',

  // Labels
  label_amount_of_songs: '{amount} canciones',
  label_toggle_host_mode: 'Activo',

  // Hints
  hint_name: 'Escribe el nombre de tu comercio/evento para que tus invitados te reconozcan.',
  hint_logo: 'Carga la imagen que verán tus invitados.',
  hint_coverage_radius:
    'Solo la gente alrededor de esta distancia desde tu dispositivo podrá ingresar como invitado.',
  hint_modes:
    'Instántaneo: \nLe darás a tus invitados de 2 a 8 opciones de canciones para que voten la próxima en sonar. \n\nPlaylist: \nTu Playlist se va ordenando en tiempo real con los votos de tus invitados.',
  hint_allow_add:
    'Elegí si querés que tus invitados agreguen nuevas canciones a tu playlist. \nPodés filtrar el contenido explícito, géneros musicales y tiempo máximo de duración de las canciones que agreguen.',
  hint_candidates:
    'En el modo instántaneo, a los invitados se les mostrará esta cantidad de opciones para votar.',
  hint_host_qr_code:
    'Compartí este código QR con tus clientes/invitados y si querés podés habilitar la opción de que sólo ingresen a la votación escaneando el QR.',
  hint_streaming_session:
    'Esto revoca los permisos de Loudy para acceder a controlar tu cuenta de Spotify y permite cambiar de cuenta si lo deseas.',
  hint_account_session: 'Esto cierra la sesión de Loudy y permite cambiar de cuenta si lo deseas.',
  hint_help_guests_screen:
    'Acá te mostramos tus invitados, su cantidad de votos y su tiempo de conexión.',
  hint_help_blacklist_screen:
    'Acá vas a ver las canciones que decidas excluir de las votaciones porque no te gustan o no son adecuadas para tu comercio/evento. Podés volver a incluirlas cuando quieras.',
  hint_help_credits_screen:
    'Si querés que tu canción favorita sea la próxima en ser escuchada, comprá crédito y forzá su reproducción.',
  hint_help_search_tracks_screen:
    'Seleccioná las canciones que quieres agregar y presiona el botón que aprecerá al final de tu pantalla.',

  // Async messages
  getting_more_songs: 'Buscando más canciones',

  // Success messages
  success_message_add_songs: 'Canciones añadidas correctamente',
  success_message_add_song: 'Canción añadida correctamente',
  success_message_add_song_delayed:
    'Canción añadida correctamente, el voto aplica a la próxima elección',
  success_message_allowed_song: 'Canción eliminada de tu lista negra',
  success_message_forbidden_song: 'Canción añadida a tu lista negra',
  success_message_add_credit: 'Tu crédito fue agregado correctamente',
  success_message_subscribe: 'Suscripción creada correctamente',
  success_message_settings_saved: 'Ajustes guardados con éxito',
  success_message_credit_sent: 'Canción comprada, disfrútala!',

  // Warning messages
  info_message_generating_merch: 'Generando el paquete de Merchandising, por favor espere...',

  // Warning messages
  warning_message_another_host:
    'El QR escaneado pertenece a otro host. Quieres unirte de todas formas?',
  warning_message_join_host_only_with_qr_title: 'Ingreso sólo con QR.',
  warning_message_join_host_only_with_qr_content: 'Para ingresar tenés que escanear el código QR',
  warning_message_disconnected_host: 'El anfitrión ya no encuentra disponible',

  // Error messages
  error_message_default: 'Oops, algo no funcionó',
  error_message_no_server_config: 'Hubo un error inesperado al conectarse con Loudy',
  error_message_no_camera_access: 'Loudy no pudo acceder a la cámara',
  error_message_invalid_qr: 'El código QR escaneado es inválido',
  error_message_maximum_duration_field: 'Valor permitido entre 6 y 15 minutos',
  error_message_required_field: 'Este campo es requerido',
  error_message_genres_required_field: 'Debes seleccionar al menos un género de la lista',
  error_message_no_active_device: 'Primero debes reproducir alguna canción en tu dispositivo',
  error_message_no_loudy_playlist: 'Primero debes cargar una playlist',
  error_message_host_not_found: 'No se encontró al anfitrión',
  error_message_add_vote_when_song_has_finished: 'La canción ha finalizado, intenta nuevamente',
  error_message_voting_has_finished:
    'La votación actual terminó, en la siguiente podrás votar de nuevo',
  error_message_guest_has_already_voted_instant_song:
    'Ya has votado a una canción, espera la siguiente',
  error_message_guest_has_already_voted_playlist_song:
    'Ya has votado a una canción, puedes votar otra canción de la playlist',
  error_message_invalid_vote: 'Voto incorrecto',
  error_message_logging_in_google: 'Error al intentar ingresar con tu cuenta de Google',
  error_message_logging_in_apple: 'Error al intentar ingresar con tu cuenta de Apple',
  error_message_track_not_found: 'No se encontró la canción. Por favor, intenta con otra',
  error_message_forbid_add_blacklisted_song:
    'No puedes agregar esta canción porque ha sido bloqueada por el anfitrión',
  error_message_forbid_add_explicit_song:
    'No puedes agregar una canción explícita, para hacerlo debes modificar la configuración de tu cuenta',
  error_message_forbid_add_explicit_song_from_guest: 'No puedes agregar una canción explícita',
  error_message_host_has_changed_setting_mode:
    'No puedes agregar una canción porque el anfitrión cambió el modo de reproducción',
  error_message_host_has_changed_setting_allow_add_songs:
    'No puedes agregar canciones, el anfitrión deshabilitó esta opción',
  error_message_song_has_already_added_to_playlist:
    'No puedes agregar esta canción porque ya se encuentra en la playlist',
  error_message_add_credit: 'Error al procesar tu pago',
  error_message_subscribe: 'Error al crear la suscripción',
  error_message_adding_card: 'Error al agregar la tarjeta',
  error_message_card_incorrect_cvc: 'CVC incorrecto',
  error_message_card_processing: 'Error al procesar la tarjeta',
  error_message_card_expired: 'Tarjeta expirada',
  error_message_card_declined: 'Tarjeta declinada',
  error_message_card_not_supported:
    'Tarjeta no soportada. Solo se pueden agregar tarjetas Visa y MasterCard',
  error_message_deleting_card: 'Error al elimintar la tarjeta',
  error_message_credit_not_sent_already_paid:
    'La canción no pudo ser comprada porque alguien lo hizo antes',
  error_message_credit_not_sent: 'Error al comprar la canción',
  error_message_card_incomplete_number: 'El número de tarjeta está incompleto',
  error_message_card_invalid_number: 'El número de tarjeta es incorrecto',
  error_message_card_incomplete_expiry: 'La expiración de tu tarjeta está incompleta',
  error_message_card_invalid_expiry_year_past: 'La expiración de tu tarjeta es incorrecto',
  error_message_card_incomplete_cvc: 'El código de seguridad de tu tarjeta está incompleto',
  error_message_card_incomplete_zip: 'El código postal está incompleto',
  error_message_guest_has_already_voted_playlist_song: 'Ya votaste esta canción',
  error_message_guest_has_already_voted_instant_song:
    'Ya votaste, podrás hacerlo de nuevo en la próxima',
  error_message_already_paid_with_two_instant_options:
    'Cuando finalice la canción paga podrás volver a votar',
  error_message_generating_merch: 'Error al generar el paquete de Merchandising',
  error_message_sharing_unavailable: 'La función compartir no esta disponible en este dispositivo',
  error_message_incorrect_last_known_position: 'Oops, no encontramos tu ubicación',
  error_message_no_product_offerings: 'Disculpa, aun no es posible comprar crédito',
  error_message_no_purchase: 'No se realizó la compra',
};
