import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { List } from 'react-native-paper';

import Avatar from '../Avatar';
import { t } from '../../services/i18n';

const Guest = ({ email, votes, connectedAt }) => {
  return (
    <List.Item
      title={email}
      titleStyle={styles.text}
      description={
        <Text style={styles.text}>
          {t('text_votes')}: {votes}
          {'\n'}
          {t('text_connected_at')}: {connectedAt}
        </Text>
      }
      descriptionNumberOfLines={4}
      style={styles.root}
      left={() => <Avatar name={email} style={styles.left} />}
    />
  );
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 10,
  },
  text: {
    color: 'white',
  },
  left: {
    alignSelf: 'center',
    transform: [{ translateX: -10 }],
  },
});

export default Guest;
