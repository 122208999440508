import React from 'react';
import { Text as RnText, StyleSheet, Platform } from 'react-native';

import { useDebug } from '../hooks/useDebug';
import theme from '../theme';

const Text = ({
  variant = 'body',
  theme: themeName = 'black',
  strong,
  centered,
  spacingBelow,
  onPress,
  debug,
  TextComponent = RnText,
  ...props
}) => {
  const { handlePress } = useDebug(debug, onPress);

  return (
    <TextComponent
      {...props}
      onPress={debug && Platform.OS !== 'web' ? handlePress : onPress}
      style={[
        styles.text,
        styles[`${themeName}Theme`],
        styles[`${variant}Variant`],
        centered && styles.centered,
        strong && styles.strong,
        props.style,
        spacingBelow ? { marginBottom: theme.spacing(spacingBelow) } : null,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  text: {
    padding: 0,
    margin: 0,
  },
  blackTheme: {
    color: theme.black.text,
  },
  whiteTheme: {
    color: theme.white.text,
  },
  centered: {
    textAlign: 'center',
  },
  strong: {
    fontFamily: 'Poppins_700Bold',
  },
  bodyVariant: {
    ...theme.font.body,
    textAlign: 'left',
  },
  titleVariant: {
    ...theme.font.title,
    textAlign: 'left',
  },
  subtitleVariant: {
    ...theme.font.subTitle,
    textAlign: 'left',
    fontWeight: 'bold',
  },
  formLabelVariant: {
    ...theme.font.small,
    textAlign: 'left',
    fontWeight: 'bold',
  },
  labelVariant: {
    ...theme.font.small,
    textAlign: 'left',
  },
  formErrorVariant: {
    ...theme.font.small,
    color: '#d83b3b',
    textAlign: 'left',
  },
});

export default Text;
