import React from 'react';
import { Badge } from 'react-native-paper';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import Icon from './Icon';
import theme from '../theme';
import { selectGuestAvailableCredit } from '../selectors';

const CreditIcon = ({ onPress, style }) => {
  const credit = useSelector(selectGuestAvailableCredit);

  return (
    <TouchableOpacity style={[styles.root, style]} onPress={onPress}>
      <Icon icon="coins" size={22} color="white" style={styles.icon} external />
      <Badge style={styles.badge} size={18}>
        {credit}
      </Badge>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge: {
    backgroundColor: 'white',
    color: theme.color.red,
    borderColor: theme.color.red,
    borderWidth: 0.5,
    fontWeight: 'bold',
    alignSelf: 'center',
    marginLeft: -5,
    alignSelf: 'flex-end'
  },
  icon: {
    margin: 0,
  },
});

export default CreditIcon;
