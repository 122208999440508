import { all, takeEvery, call } from 'redux-saga/effects';

import * as constants from '../../constants';
import handleMiddleware from '../middleware';
import HostService from '../../services/host';

function* getGuestsWorker() {
  return yield call(HostService.getGuests);
}

export default function* watchAll() {
  yield all([takeEvery(constants.API_GET_GUESTS, handleMiddleware, getGuestsWorker)]);
}
