import React, { useEffect, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { Video } from 'expo-av';
import {
  BrowserMultiFormatReader,
  NotFoundException,
  DecodeHintType,
  BarcodeFormat,
} from '@zxing/library';

import ScannerLayer from '../ScannerLayer';
import theme from '../../theme';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: theme.color.blackLight,
  },
  video: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoLayerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  }
});

const onScan = async (htmlVideoElement, callbackSuccess) => {
  const hints = new Map();
  hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.QR_CODE]);

  const codeReader = new BrowserMultiFormatReader(hints);
  codeReader.timeBetweenDecodingAttempts = 200;
  codeReader.decodeFromVideoDevice(undefined, htmlVideoElement, (result, err) => {
    if (result) {
      codeReader.stopContinuousDecode();
      callbackSuccess(result);
    }
    if (err && !(err instanceof NotFoundException)) {
      console.error(err);
    }
  });
};

export default function Scanner({ scanned, handleBarCodeScanned, handleCancel }) {
  const ref = useRef();

  useEffect(() => {
    if (!scanned) {
      onScan(ref.current._nativeRef.current._video, result => handleBarCodeScanned(result.text));
    }
  }, [scanned]);

  return (
    <View style={styles.root}>
      <Video style={styles.video} resizeMode="cover" ref={ref} />
      <View style={styles.videoLayerContainer}>
        <ScannerLayer handleCancel={handleCancel} />
      </View>
    </View>
  );
}
