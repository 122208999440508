import React from 'react';
import { Platform, StyleSheet, View, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import ContentWrapper from '../../components/ContentWrapper';
import SpotifyButton from '../../components/SpotifyButton';
import Text from '../../components/Text';
import BackgroundWrapper from '../../components/BackgroundWrapper';
import Push from '../../components/Push';
import Space from '../../components/Space';
import BackButton from '../../components/BackButton';
import HostOnboarding from '../onboarding/host/HostOnboarding';
import useScreenLogger from '../../hooks/useScreenLogger';
import theme from '../../theme';
import * as analytics from '../../services/analytics';
import { t } from '../../services/i18n';
import { useSelector } from 'react-redux';
import { selectOnboarded } from '../../selectors';
import { result } from 'lodash';
import { useScreenFocus } from '../../hooks/useScreenFocus';

const StreamLogIn = () => {
  const navigation = useNavigation();
  useScreenLogger('LOGIN');

  const onboarded = useSelector(selectOnboarded);
  const hostOnboardedNeeded = Platform.OS !== 'web' && !result(onboarded, 'host', false);

  useScreenFocus(() => {
    analytics.screen('streamLogIn');
  });

  if (hostOnboardedNeeded) {
    return <HostOnboarding />;
  }

  return (
    <View style={styles.root}>
      <BackgroundWrapper>
        <Push />
        <BackButton onPress={() => navigation.navigate('Home')} />
        <ContentWrapper>
          <View style={styles.inner}>
            <View style={styles.icon}>
              <Image
                source={require('../../assets/loudy-spotify.png')}
                style={styles.image}
                resizeMode="contain"
              />
            </View>
            <View style={styles.text}>
              <Text centered variant="title">
                {t('text_sync_with_spotify_title')}
              </Text>
              <Text centered spacingBelow>
                {t('text_sync_with_spotify_instructions')}
              </Text>
              <Space amount={1} />
              <SpotifyButton />
            </View>
          </View>
        </ContentWrapper>
      </BackgroundWrapper>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  inner: {
    marginVertical: theme.spacing(1),
    flex: 1,
  },
  text: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    paddingVertical: theme.spacing(3),
    paddingHorizontal: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 300,
  },
  image: {
    flex: 1,
    width: '100%',
    maxHeight: 150,
    margin: 'auto',
  },
});

export default StreamLogIn;
