import { Platform } from 'react-native';
import theme from '../theme';

export default {
  joinHost: {
    style: {
      skeletonContent: {
        paddingHorizontal: 17,
        paddingVertical: 12,
        backgroundColor: theme.color.blackLight,
        borderRadius: 8,
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
      },
    },
    items: [
      {
        style: {
          marginRight: 17,
        },
        circleSize: 34,
      },
      {
        style: {
          flex: 1,
        },
      },
    ],
  },
  hostPlayer: {
    style: {
      row: {
        paddingHorizontal: Platform.OS === 'web' ? theme.spacing(1) : undefined,
      },
      skeletonContent: {
        padding: 10,
        paddingHorizontal: theme.spacing(1),
        maxHeight: 72,
        backgroundColor: theme.color.blackLight,
        alignItems: 'center',
        flexDirection: 'row',
      },
    },
    items: [
      {
        style: {
          flex: 1,
          justifyContent: 'center',
        },
        items: [
          {
            style: {
              height: 10,
              width: '30%',
              marginBottom: 5,
            },
          },
          {
            style: {
              height: 8,
              width: '20%',
              marginBottom: 20,
            },
          },
          {
            style: {
              height: 5,
            },
          },
        ],
      },
      ...new Array(3).fill('').map(() => ({
        style: {
          transform: [{ translateX: 10 }],
          margin: 6,
        },
        circleSize: 30,
      })),
    ],
  },
  guestPlayer: {
    style: {
      row: {
        paddingHorizontal: Platform.OS === 'web' ? theme.spacing(1) : undefined,
      },
      skeletonContent: {
        padding: 10,
        paddingHorizontal: theme.spacing(1),
        height: 72,
        backgroundColor: theme.color.blackLight,
        justifyContent: 'center',
      },
    },
    items: [
      {
        style: {
          height: 10,
          width: '30%',
          marginBottom: 5,
        },
      },
      {
        style: {
          height: 8,
          width: '20%',
          marginBottom: 20,
        },
      },
      {
        style: {
          height: 5,
        },
      },
    ],
  },
  playlist: {
    style: {
      row: {
        paddingHorizontal: theme.spacing(1),
      },
      skeletonContent: {
        maxHeight: 70,
        padding: 10,
        marginTop: 10,
        borderRadius: 10,
        backgroundColor: theme.color.blackLight,
        alignItems: 'center',
        flexDirection: 'row',
      },
    },
    items: [
      {
        circleSize: 38,
      },
      {
        style: {
          marginLeft: 10,
          flex: 1,
          justifyContent: 'center',
        },
        items: [
          {
            style: {
              height: 20,
              width: '100%',
              marginTop: 0,
            },
          },
          {
            style: {
              height: 16,
              width: '60%',
              marginTop: 10,
            },
          },
        ],
      },
    ],
  },
  instant: {
    style: {
      row: {
        flex: 1,
        width: '100%',
        paddingHorizontal: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        marginTop: 10,
        marginBottom: 50,
      },
      column: {
        flex: 1,
      },
      skeletonRoot: {
        marginTop: 10,
        flex: 1,
      },
      skeletonContent: {
        height: '100%',
        padding: 10,
        borderRadius: 10,
        flex: 1,
        overflow: 'hidden',
        borderWidth: 1,
        borderColor: theme.color.blackLight,
      },
    },
    items: [
      {
        circleSize: 25,
        style: {
          alignSelf: 'flex-end',
          marginBottom: theme.spacing(0.5),
        },
      },
      {
        style: {
          flex: 1,
          marginBottom: 10,
        },
      },
      {
        style: {
          height: 12,
          width: '60%',
          marginBottom: 5,
        },
      },
      {
        style: {
          height: 8,
          width: '20%',
        },
      },
    ],
  },
  guest: {
    style: {
      skeletonContent: {
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20
      },
    },
    items: [
      {
        style: {
          marginTop: 17,
          marginRight: 17,
        },
        circleSize: 34,
      },
      {
        style: {
          flex: 1,
          flexDirection: 'column',
          borderRadius: 30,
        },
        items: [
          {
            style: {
              height: 14,
              borderWidth: 1,
            },
          },
          {
            style: {
              height: 10,
              top: 10,
            },
          },
          {
            style: {
              height: 10,
              top: 20,
            },
          },
        ],
      },
    ],
  },
  subscription: {
    style: {
      skeletonContent: {
        marginTop: 10,
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20
      },
    },
    items: [
      {
        style: {
          width: '50%',
          height: 20,
          marginBottom: 20,
        },
      },
      ...new Array(4).fill('').map(() => ({
        style: {
          width: '100%',
          height: 14,
          marginBottom: 10,
        },
      })),
      {
        style: {
          width: '100%',
          height: 50,
          marginTop: 20,
          borderRadius: 10,
        },
      }
    ],
  },
};
