import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import theme from '../theme';
import Button from './Button';

const ProgressButton = ({ progress, color, style, ...props }) => {
  const [totalWidth, setTotalWidth] = useState(0);

  return (
    <View style={[styles.root, styles[`${color}Color`]]}>
      {progress ? (
        <View
          onLayout={event => {
            setTotalWidth(event.nativeEvent.layout.width);
          }}
          style={[
            styles.progress,
            {
              borderLeftWidth: progress * totalWidth,
            },
          ]}
        />
      ) : null}
      <Button {...props} icon="multitrack-audio" color={color} style={[style, styles.button]} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    borderRadius: 8,
    width: '100%',
    marginTop: 10,
  },
  progress: {
    borderLeftColor: theme.color.grayLight,
    borderRightWidth: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderRadius: 8,
  },
  button: {
    backgroundColor: 'transparent',
    marginBottom: 0,
  },
  primaryColor: {
    backgroundColor: theme.primaryButton.bg1,
  },
  secondaryColor: {
    backgroundColor: theme.secondaryButton.bg1,
  },
  tertiaryColor: {
    backgroundColor: theme.tertiaryButton.bg1,
  },
  spotifyColor: {
    backgroundColor: theme.spotifyButton.bg1,
  },
  youtubeColor: {
    backgroundColor: theme.youtubeButton.bg1,
  },
  itunesColor: {
    backgroundColor: theme.itunesButton.bg1,
  },
});

export default ProgressButton;
