import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, Platform } from 'react-native';
import { result } from 'lodash';

import Tracklist from '../../components/host/Tracklist';
import HostPlayer from '../../components/host/HostPlayer';
import ContentWrapper from '../../components/ContentWrapper';
import SkeletonWrapper from '../../components/skeleton/SkeletonWrapper';
import Text from '../../components/Text';
import skeleton from '../../helpers/skeleton';
import theme from '../../theme';
import * as analytics from '../../services/analytics';
import * as refreshService from '../../services/refresh';
import { openModal } from '../../services/modal';
import { REFRESH_SERVICES } from '../../constants';
import { cleanTrackList, getHost, getPlayerStatus, getTrackList } from '../../actions';
import { selectPlaylist, selectHost } from '../../selectors';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import { t } from '../../services/i18n';
import { useLoadingStatus } from '../../hooks/useLoadingStatus';

const getTitle = description => {
  const count = result(description, 'count');
  const durationFormatted = result(description, 'durationFormatted');
  const array = [];
  if (count && durationFormatted) {
    array.push(
      `${
        count === 1
          ? t('text_count_tracks_singular')
          : t('text_count_tracks_plural', {
              number: count,
            })
      }\n${durationFormatted}`,
    );
  }
  return array.join('\n');
};

const Playlist = ({ navigation }) => {
  const dispatch = useDispatch();
  const { tracklist, loadingTracklist, description } = useSelector(selectPlaylist);
  const { data } = useSelector(selectHost);
  const allowExplicitContent = result(data, 'host.settings.allowExplicitContent');
  const hostId = result(data, 'host.id');
  const loadingStatus = useLoadingStatus(loadingTracklist, true);

  useScreenFocus(() => {
    analytics.tab('playlist');
    refreshPlayerStatus();
    getHostData();
  });

  useEffect(() => {
    refreshService.start(REFRESH_SERVICES.GET_PLAYER_STATUS, refreshPlayerStatus, 15000);
    refreshPlayerStatus(true);
    return () => {
      refreshService.stop(REFRESH_SERVICES.GET_PLAYER_STATUS);
    };
  }, []);

  useEffect(() => {
    refresh(true);
  }, [data]);

  const onHandleImport = () => {
    navigation.navigate('PlaylistsImport');
  };

  const onHandleSearch = () => {
    navigation.navigate('SearchTracks', { allowExplicitContent });
  };

  const onHandleClean = () => {
    openModal('cleanPlaylist', {
      title: t('text_clean_playlist_title'),
      content: t('text_clean_playlist_description'),
      buttons: [
        {
          text: t('action_clean_playlist'),
          onPress: ({ close }) => {
            dispatch(cleanTrackList());
            close();
          },
        },
        { text: t('action_cancel'), onPress: ({ close }) => close(), variant: 'secondary' },
      ],
    });
  };

  const refreshPlayerStatus = firstTime => {
    dispatch(getPlayerStatus(firstTime));
  };

  const refresh = showLoading => {
    dispatch(getTrackList(showLoading));
  };

  const getHostData = () => {
    dispatch(getHost());
  };

  const otherActions = [
    {
      icon: 'add-playlist',
      text: t('action_import_playlist'),
      onPress: onHandleImport,
      external: true,
    },
    {
      icon: 'add-song',
      text: t('action_add_songs'),
      onPress: onHandleSearch,
      external: true,
    },
    {
      icon: 'clean-playlist',
      onPress: onHandleClean,
      external: true,
    },
  ];

  const recommendation = result(description, 'recommendation');

  return (
    <View style={styles.root}>
      {loadingStatus.initialLoading ? (
        <>
          <SkeletonWrapper {...skeleton.hostPlayer} repeat={1} />
          <SkeletonWrapper {...skeleton.playlist} repeat={10} />
        </>
      ) : (
        <>
          <HostPlayer hostId={hostId} />
          <View style={styles.wrapper}>
            {recommendation && <Text centered style={styles.recommendation}>{recommendation}</Text>}
            <ContentWrapper style={styles.inner}>
              <Tracklist
                title={getTitle(description)}
                otherActions={otherActions}
                tracklist={tracklist}
                loadingTracklist={loadingTracklist}
                refresh={refresh}
              />
            </ContentWrapper>
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: theme.black.bg1,
  },
  root: {
    backgroundColor: theme.black.bg1,
    flex: 1,
  },
  inner: {
    paddingTop: Platform.OS === 'web' ? 0 : 10,
    flex: 1,
  },
  playlistRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  },
  recommendation: {
    backgroundColor: theme.color.info,
    paddingHorizontal: theme.spacing(1),
    paddingVertical: 10,
  },
});

export default Playlist;
