import React from 'react';
import { StatusBar, View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#000000',
  },
});

const Header = () => {
  return (
    <StatusBar
      translucent
      barStyle="light-content"
      style={styles.root}
      backgroundColor="transparent"
    />
  );
};

export default Header;
