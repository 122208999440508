import { map, result } from 'lodash';

import * as constants from '../constants';
import { info as toastInfo } from '../helpers/toast';
import { t } from '../services/i18n';

const tracksInitialState = {
  results: [],
  loading: false,
  limit: 0,
  next: null,
  offset: 0,
  lastQuery: null,
  newSearch: false,
};

const initialState = {
  tracks: tracksInitialState,
  playlists: {
    results: [],
    loading: false,
  },
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.API_SEARCH_TRACKS_REQUEST: {
      const { query, newSearch } = payload;
      if (!newSearch) {
        toastInfo(t('getting_more_songs'));
      }
      return {
        ...state,
        tracks: {
          ...state.tracks,
          loading: true,
          lastQuery: query,
          newSearch,
        },
      };
    }
    case constants.API_SEARCH_TRACKS_SUCCESS: {
      const { data, limit, next, offset } = payload.tracks;
      const results = [];
      if (offset >= limit) {
        const oldResults = result(state, 'tracks.results', []);
        results.push(...oldResults);
      }
      results.push(...data);
      return {
        ...state,
        tracks: {
          ...state.tracks,
          results,
          loading: false,
          limit,
          next,
          offset,
        },
      };
    }
    case constants.API_SEARCH_TRACKS_FAILURE:
    case constants.CLEAN_SEARCH_TRACKS: {
      return {
        ...state,
        tracks: tracksInitialState,
      };
    }
    case constants.API_ALLOW_SONG_SUCCESS: {
      const { allowed, uri } = payload;
      const tracks = map(result(state, 'tracks.results', []), track => {
        if (track.uri === uri) {
          return { ...track, allowed };
        }
        return track;
      });
      return {
        ...state,
        tracks: {
          ...state.tracks,
          results: tracks,
        },
      };
    }
    case constants.API_ADD_SONGS_SUCCESS: {
      const { uris } = payload;
      const tracks = map(result(state, 'tracks.results', []), track =>
        track.uri === uris[0] ? { ...track, available: false } : track,
      );
      return {
        ...state,
        tracks: {
          ...state.tracks,
          results: tracks,
        },
      };
    }
    default:
      return state;
  }
};
