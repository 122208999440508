import { all, takeEvery, call, takeLatest, put } from 'redux-saga/effects';

import handleMiddleware from '../middleware';
import * as blacklistService from '../../services/blacklist';
import * as constants from '../../constants';
import { mapHttpError } from '../../helpers/format';
import { getHostId } from '.';

function* getBlacklistWorker() {
  return yield call(blacklistService.getBlacklist);
}

function* allowSongWorker({ payload }) {
  try {
    yield put({ type: constants.API_ALLOW_SONG_REQUEST, payload: payload.uri });
    const response = yield call(blacklistService.allowSong, payload);
    yield put({ type: constants.API_GET_TRACKLIST });
    const hostId = yield call(getHostId);
    yield put({ type: constants.API_ALLOW_SONG_SUCCESS, payload: { ...response.data, hostId } });
  } catch (error) {
    yield put({
      type: constants.API_ALLOW_SONG_FAILURE,
      payload: mapHttpError(error),
    });
  }
}

export default function* watchAll() {
  yield all([
    takeEvery(constants.API_GET_BLACKLIST, handleMiddleware, getBlacklistWorker),
    takeLatest(constants.API_ALLOW_SONG, allowSongWorker),
  ]);
}
