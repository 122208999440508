import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, StyleSheet, View, Vibration } from 'react-native';
import ConfettiCannon from 'react-native-confetti-cannon';
import { useSelector } from 'react-redux';

import { selectConfettiStatus } from '../selectors';
import theme from '../theme';

const Confetti = () => {
  const ref = useRef();
  const confettiStatus = useSelector(selectConfettiStatus);
  const [playing, setPlaying] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (confettiStatus && confettiStatus.on) {
      setPlaying(true);
      clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(() => {
          if (playing) {
            Vibration.vibrate(1000);
          }
        }, 1000),
      );
    }
    if (confettiStatus && !confettiStatus.on) {
      clearTimeout(timeoutId);
      setPlaying(false);
    }
  }, [confettiStatus, playing]);

  if (!playing) {
    return null;
  }

  const width = Dimensions.get('window').width;
  const height = Dimensions.get('window').height;

  const origin = { x: width / 2, y: height };

  if (confettiStatus && confettiStatus.origin) {
    const { x, y } = confettiStatus.origin;
    if (x && String(x).endsWith('%')) {
      origin.x = width * Number(x.substr(0, x.length - 1));
    } else {
      origin.x = x;
    }
    if (y && String(y).endsWith('%')) {
      origin.y = height * Number(y.substr(0, y.length - 1));
    } else {
      origin.y = y;
    }
  }

  return (
    <View style={styles.root}>
      <ConfettiCannon
        count={70}
        origin={origin}
        autoStart
        // explosionSpeed={350}
        // fallSpeed={3000}
        fadeOut
        colors={theme.confetti}
        ref={ref}
        onAnimationEnd={() => setPlaying(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    zIndex: 1000,
    position: 'absolute',
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    top: 0,
    left: 0,
  },
});

export default Confetti;
