import { useEffect, useState } from 'react';
import { Image, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { result } from 'lodash';
import { selectOnboarded } from '../selectors';

const spinnerImages = [
  require('../assets/loading/loudy_0012_auriculares-min.png'),
  require('../assets/loading/loudy_0011_hamburguesa-min.png'),
  require('../assets/loading/loudy_0010_stereo-min.png'),
  require('../assets/loading/loudy_0001_celular-min.png'),
  require('../assets/loading/loudy_0003_notas-musicales-min.png'),
  require('../assets/loading/loudy_0009_cerveza-min.png'),
  require('../assets/loading/loudy_0008_boca-min.png'),
  require('../assets/loading/loudy_0006_guitarra-min.png'),
  require('../assets/loading/loudy_0007_pizza-min.png'),
  require('../assets/loading/loudy_0005_tambor-min.png'),
  require('../assets/loading/loudy_0000_cassette-min.png'),
  require('../assets/loading/loudy_0004_microfono-min.png'),
  require('../assets/loading/loudy_0002_notas-musicales-2-min.png'),
];
const backgroundImages = [require('../assets/home-bg2.jpg'), require('../assets/home-bg3.jpg')];
const guestOnboardingImages = [
  require('../assets/guestonboarding1.png'),
  require('../assets/guestonboarding2.png'),
  require('../assets/guestonboarding3.png'),
];

export const usePrefetchImages = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onboarded = useSelector(selectOnboarded);
  const mainOnboardedNeeded = Platform.OS !== 'web' && !result(onboarded, 'main', false);

  const loadImages = images =>
    Promise.all(
      images.map(image => {
        const source = Image.resolveAssetSource(image);
        return Image.prefetch(source.uri);
      }),
    );

  useEffect(() => {
    if (Platform.OS === 'web') {
      setLoading(false);
      return;
    }
    setLoading(true);

    let imagesToPrefetch = [
      ...backgroundImages,
      ...spinnerImages,
    ];
    if (mainOnboardedNeeded) {
      imagesToPrefetch.push(...guestOnboardingImages);
    }

    loadImages(imagesToPrefetch)
      .then(() => setLoading(false))
      .catch(reason => {
        setLoading(false);
        setError(reason);
      });
  }, []);

  return { loading, error };
};
