import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import { useScreenFocus } from './useScreenFocus';
import * as refreshService from '../services/refresh';

export const useRefresh = (id, fn, delay) => {
  useScreenFocus(fn);
  useFocusEffect(
    useCallback(() => {
      refreshService.start(id, fn, delay);
      const unsubscribe = () => refreshService.stop(id);
      return unsubscribe;
    }, [id]),
  );
};
