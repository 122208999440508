import { combineReducers } from 'redux';

import hostReducers from './host';
import guestReducers from './guest';
import collapsible from './collapsible';
import user from './user';
import serverConfig from './serverConfig';
import network from './network';
import onboarded from './onboarded';
import searchLibrary from './searchLibrary';
import confetti from './confetti';
import iap from './iap';
import subscriptions from './subscriptions';
import mercadopago from './mercadopago';
import notification from './notification';

const rootReducer = combineReducers({
  ...hostReducers,
  ...guestReducers,
  collapsible,
  user,
  serverConfig,
  network,
  onboarded,
  searchLibrary,
  confetti,
  iap,
  subscriptions,
  mercadopago,
  notification,
});

export default rootReducer;
