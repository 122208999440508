import React from 'react';
import { StyleSheet, TouchableOpacity, Text, Image } from 'react-native';

import { t } from '../services/i18n';

const GoogleLoginButton = ({ onPress, disabled, style }) => {
  return (
    <TouchableOpacity disabled={!!disabled} onPress={onPress} style={[styles.root, style]}>
      <Image source={require('../assets/google-icon.png')} style={styles.icon} />
      <Text style={styles.text}>{t('Sign in with Google')}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent:'center',
    padding: 11,
    paddingHorizontal: 20,
    borderRadius: 3,
  },
  icon: {
    backgroundColor: 'white',
    width: 18,
    height: 18,
    marginRight: 10,
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 18,
  },
});


export default GoogleLoginButton;
