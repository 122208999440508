import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy, result } from 'lodash';

import Text from '../../components/Text';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Space from '../../components/Space';
import theme from '../../theme';
import RefreshView from '../../components/RefreshView';
import ContentWrapper from '../../components/ContentWrapper';
import * as analytics from '../../services/analytics';
import { selectUserInformation } from '../../selectors';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import { t } from '../../services/i18n';
import { formatDateTime } from '../../services/format';
import { inAppPurchaseStartCheckoutProduct, loadUserInformation, mpStartCheckout } from '../../actions';

const Credit = ({ route }) => {
  const dispatch = useDispatch();
  const { data: userInformation, loading } = useSelector(selectUserInformation);
  const { handleBack } = result(route, 'params', {});

  useScreenFocus(() => {
    analytics.screen('credit');
  });

  const getSongName = historyItem => {
    try {
      return historyItem.details.match('"(.*)"')[1];
    } catch (e) {
      return null;
    }
  };

  const handleBuy = () => {
    if (Platform.OS === 'web') {
      dispatch(mpStartCheckout());
    } else {
      dispatch(inAppPurchaseStartCheckoutProduct());
    }
  };

  const handleRefresh = () => {
    dispatch(loadUserInformation());
  };

  return (
    <Layout
      title={t('screen_title_credit')}
      hint="hint_help_credits_screen"
      handleBack={handleBack}
    >
      <ContentWrapper>
        <View style={styles.root}>
          <RefreshView onRefresh={handleRefresh} refreshing={loading} style={styles.scroll}>
            <View style={styles.scrollInner}>
              <View style={styles.box}>
                <View style={styles.boxInner}>
                  <View style={styles.boxLeft}>
                    <Text>{t('text_you_have_available')}</Text>
                    <View style={styles.creditTextContainer}>
                      <Text style={styles.credit}>{userInformation.credit}</Text>
                      <Text style={styles.creditUnit}>
                        {Math.abs(userInformation.credit) === 1
                          ? t('text_credits_unit_singular')
                          : t('text_credits_unit_plural')}
                      </Text>
                    </View>
                    <View>
                      <Text>{t('subtitle_account')}</Text>
                    </View>
                  </View>
                  <View style={styles.boxRight}>
                    <Icon icon="coins" size={50} color="white" style={styles.icon} external />
                  </View>
                </View>
                <Text strong>{userInformation.email}</Text>
              </View>
              <Text variant="title">{t('subtitle_last_movements')}</Text>
              <View>
                {orderBy(userInformation.creditHistory || [], 'date', 'desc').map(
                  (historyItem, index) => {
                    const songName = getSongName(historyItem);
                    const prefixAmount = historyItem.amount > 0 ? '+' : '-';
                    const absAmount = Math.abs(historyItem.amount);
                    return (
                      <View key={index} style={styles.creditRow}>
                        <View style={styles.creditRowLeft}>
                          <Text strong>
                            {historyItem.amount > 0
                              ? t('text_added')
                              : songName
                              ? t('text_redeemed_song', { name: songName })
                              : t('text_redeemed')}
                          </Text>
                          <Text>{formatDateTime(historyItem.date)}</Text>
                        </View>
                        <Text
                          style={
                            historyItem.amount > 0 ? styles.creditPositive : styles.creditNegative
                          }
                        >
                          {prefixAmount}
                          {absAmount}{' '}
                          {absAmount === 1
                            ? t('text_credits_unit_singular')
                            : t('text_credits_unit_plural')}
                        </Text>
                      </View>
                    );
                  },
                )}
              </View>
            </View>
          </RefreshView>
          <Button
            title={t('action_buy_credit')}
            color="tertiary"
            icon={{ icon: 'shopping-cart', size: 25 }}
            proceed
            onPress={handleBuy}
          />
          <Space amount={1} />
        </View>
      </ContentWrapper>
    </Layout>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  icon: {
    marginTop: 5,
  },
  scroll: {
    marginBottom: 10,
  },
  scrollInner: {
    paddingTop: theme.spacing(1),
  },
  box: {
    backgroundColor: theme.color.blue,
    borderRadius: 10,
    padding: 20,
    marginBottom: 20,
  },
  boxInner: {
    flexDirection: 'row',
  },
  boxLeft: {
    flexDirection: 'column',
    flex: 1,
  },
  boxRight: {
    paddingHorizontal: 10,
  },
  creditTextContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: 20,
  },
  credit: {
    fontSize: 30,
    lineHeight: 40,
    marginRight: 5,
    alignSelf: 'flex-end',
  },
  creditUnit: {
    fontSize: 25,
    lineHeight: 30,
    alignSelf: 'flex-end',
    marginBottom: 2,
  },
  creditRow: {
    borderTopColor: theme.color.grayDark,
    borderTopWidth: 1,
    paddingVertical: 10,
    flexDirection: 'row',
  },
  creditRowLeft: {
    flexDirection: 'column',
    flex: 1,
    paddingRight: 10,
  },
  creditPositive: {
    color: theme.color.blue,
  },
  creditNegative: {
    color: theme.color.red,
  },
});

export default Credit;
