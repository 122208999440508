import api from './api';
import { setMpLoadingCheckout } from '../actions';
import { store } from '../store';

export const purchase = async payload => {
  store.dispatch(setMpLoadingCheckout(payload.planId));
  const { data } = await api.post('/guest/credit/mp', payload);
  store.dispatch(setMpLoadingCheckout(false));
  return data.url;
};

export const checkPayment = async paymentId => {
  return api.post('/guest/credit/check-mp', { paymentId });
};
