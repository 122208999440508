import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, Platform, View, Easing } from 'react-native';
import { result, size } from 'lodash';

import Skeleton from './Skeleton';
import theme from '../../theme';

const SkeletonWrapper = ({ repeat, columns, style, backgroundColor, items }) => {
  const widthAnim = useRef(new Animated.Value(0)).current;
  const rows = new Array(repeat).fill(0);

  const animate = () => {
    widthAnim.setValue(0);
    Animated.timing(widthAnim, {
      toValue: 1,
      duration: 350,
      useNativeDriver: false,
      easing: Easing.inOut(Easing.quad),
    }).start(() => {
      setTimeout(() => {
        animate();
      }, 1000);
    });
  };

  useEffect(() => {
    animate();
  }, []);

  const styles = columns === 1 ? stylesVertical : stylesHorizontal;

  const renderItems = items => {
    return items.map((item, index) => {
      if (size(item.items)) {
        return <View key={index} style={item.style}>{renderItems(item.items)}</View>;
      } else {
        return (
          <Skeleton key={index} {...item} backgroundColor={backgroundColor} widthAnim={widthAnim} />
        );
      }
    });
  };

  return (
    <View style={[stylesWrapper.root, styles.root, result(style, 'row')]}>
      {new Array(columns).fill(0).map((_, index) => {
        return (
          <View
            key={index}
            style={[
              columns > 1 ? (index === 0 ? styles.leftColumn : styles.rightColumn) : styles.root,
              result(style, 'column'),
            ]}
          >
            {rows.map((_, index) => {
              const opacity = 100 / (index + 3) / 100;
              return (
                <View style={[styles.root, result(style, 'skeletonRoot')]} key={index}>
                  <View
                    tint="dark"
                    style={[result(style, 'skeletonContent'), { width: '100%', opacity }]}
                  >
                    {renderItems(items)}
                  </View>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

SkeletonWrapper.defaultProps = {
  columns: 1,
  repeat: 3,
  backgroundColor: theme.color.grayDark,
};

const stylesWrapper = StyleSheet.create({
  root: {
    width: '100%',
    maxWidth: Platform.OS === 'web' ? 1000 : undefined,
    marginHorizontal: 'auto',
  },
});

const stylesVertical = StyleSheet.create({
  root: {
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  },
});

const stylesHorizontal = StyleSheet.create({
  root: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  leftColumn: {
    flex: 1,
    paddingRight: 5,
    flexDirection: 'column',
    height: '100%',
  },
  rightColumn: {
    flex: 1,
    paddingLeft: 5,
    flexDirection: 'column',
    height: '100%',
  },
});

export default SkeletonWrapper;
