import moment from 'moment';
import React from 'react';

import LineChart from './LineChart';
import { t } from '../../services/i18n';

const days = [
  t('text_small_sunday'),
  t('text_small_monday'),
  t('text_small_tuesday'),
  t('text_small_wednesday'),
  t('text_small_thursday'),
  t('text_small_friday'),
  t('text_small_saturday'),
];

export default ({ report }) => {
  if (!report) {
    return null;
  }

  return (
    <LineChart
      title={t('text_report_guests_in_week')}
      labels={Object.keys(report).map(date => days[moment(date).day()])}
      values={Object.values(report)}
    />
  );
};
