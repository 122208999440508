import api, { getEndpoint } from './api';

import config from '../config';
import { base64toFile } from '../helpers/format';

export default class {
  static get() {
    return api.get('/host');
  }

  static saveSettings(data) {
    const fd = new FormData();
    if (data.displayPicture) {
      const uri = data.displayPicture.uri;
      if (uri.includes('base64')) {
        const file = base64toFile(uri, 'logo.png');
        fd.append('image', file);
      } else {
        fd.append('image', {
          uri,
          name: 'logo.png',
          filename: 'logo.png',
          type: 'image/png',
        });
      }
      delete data.displayPicture;
    }
    fd.append('settings', JSON.stringify(data));
    fd.append('_method', 'PATCH');
    return api.post('/host/settings', fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static saveLocation(data) {
    return api.patch('/host/location', data);
  }

  static updateMode(status) {
    return api.patch('/host/mode', { status });
  }

  static getGuests() {
    return api.get('/host/guests');
  }

  static getHostImageUrl(displayPicture, forSource = false, isThumb = false) {
    // 1 minute cache
    const time = new Date().toISOString().substr(0, 16);
    const imageUrl = displayPicture
      ? `${config.aws.bucketUrl}/images/${isThumb ? 'thumb_' : ''}${displayPicture}?time=` + time
      : null;
    if (forSource && imageUrl) {
      return { uri: imageUrl };
    }
    return imageUrl;
  }
}
