import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import GoogleMapPicker from 'react-google-map-picker';

import config from '../config';

const MapPicker = ({ height, lat, lng, onChange, style, ...props }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <View style={[styles.root, style]}>
      {show && (
        <GoogleMapPicker
          defaultLocation={{ lat, lng }}
          zoom={20}
          mapTypeId="roadmap"
          style={{ height }}
          onChangeLocation={(lat, lng) => onChange({ lat, lng })}
          apiKey={config.google.apiKey}
          {...props}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
});

export default MapPicker;
