import React, { useState } from 'react';
import { View, Dimensions } from 'react-native';
import { Menu } from 'react-native-paper';
import { useNavigation } from '@react-navigation/core';
import { useDispatch } from 'react-redux';
import { getStatusBarHeight } from 'react-native-status-bar-height';

import IconButton from './IconButton';
import { t } from '../services/i18n';
import { logoutUser } from '../actions';
import theme from '../theme';

const AccountMenu = ({ style }) => {
  const icon = { external: true, icon: 'account', size: 35 };
  const [visible, setVisible] = useState(false);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  return (
    <View style={style}>
      <IconButton color="#FFFFFF90" {...icon} onPress={() => setVisible(true)} />
      <Menu
        visible={visible}
        onDismiss={() => setVisible(false)}
        anchor={{
          x: Dimensions.get('window').width - theme.spacing(1),
          y: getStatusBarHeight() + 50,
        }}
      >
        <Menu.Item
          onPress={() => {
            navigation.navigate('Credit');
            setVisible(false);
          }}
          title={t('action_my_credit')}
        />
        <Menu.Item
          onPress={() => {
            navigation.navigate('Account');
            setVisible(false);
          }}
          title={t('action_my_account')}
        />
        <Menu.Item
          onPress={() => {
            dispatch(logoutUser());
            setVisible(false);
          }}
          title={t('action_logout')}
        />
      </Menu>
    </View>
  );
};

export default AccountMenu;
