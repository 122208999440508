import AsyncStorage from '@react-native-async-storage/async-storage';
import { result } from 'lodash';
import { setPostLoginCallback, setUser } from '../actions';

import { STORAGE_LOAD } from '../constants';
import { selectUserInformation } from '../selectors';
import { store } from '../store';

const storage = {};

const keys = ['login', 'host', 'user', 'onboarded'];

export const initStorage = async dispatch => {
  for (let key of keys) {
    try {
      const jsonValue = await AsyncStorage.getItem(key);
      storage[key] = jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      // error reading value
    }
  }
  dispatch({
    type: STORAGE_LOAD,
    payload: storage,
  });
  const storedUser = result(storage, 'user');
  const storedUserEmail = result(storage, 'user.user.email');
  if (storedUser) {
    // handle any post login callback
    dispatch(setUser(storedUser));
    const userInformation = selectUserInformation(store.getState());
    if (userInformation && userInformation.postLoginCallback) {
      userInformation.postLoginCallback(storedUserEmail);
      dispatch(setPostLoginCallback(null));
    }
  }
};

export const clearData = key => {
  AsyncStorage.removeItem(key);
  storage[key] = undefined;
};

export const saveData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    storage[key] = value;
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    // saving error
  }
};

export const clearAllData = async () => {
  for (let key of keys) {
    try {
      clearData(key);
    } catch (e) {
      // error clearing value
    }
  }
};

export const getData = (key, defaultValue) => {
  try {
    const jsonValue = storage[key];
    return jsonValue != null ? JSON.parse(jsonValue) : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};
