import React, { useEffect } from 'react';
import Button from '../components/Button';
import { View, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import BackgroundWrapper from '../components/BackgroundWrapper';
import Social from '../components/Social';
import Text from '../components/Text';
import ContentWrapper from '../components/ContentWrapper';
import GuestOnboarding from '../screens/onboarding/guest/GuestOnboarding';
import JoinHostButton from '../components/guest/JoinHostButton';
import AccountMenu from '../components/AccountMenu';
import Push from '../components/Push';
import Space from '../components/Space';
import RefreshView from '../components/RefreshView';
import SkeletonWrapper from '../components/skeleton/SkeletonWrapper';
import theme from '../theme';
import skeleton from '../helpers/skeleton';
import * as analytics from '../services/analytics';
import { t } from '../services/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { selectHost, loadingHosts, selectHosts, selectOnboarded, selectUser, selectedHostJoining, selectLowAccuracy } from '../selectors';
import { getClosestHosts, loadUserInformation } from '../actions';
import { map, result, size } from 'lodash';
import { openModal } from '../services/modal';
import { useRefresh } from '../hooks/useRefresh';
import { REFRESH_SERVICES } from '../constants';
import AnimatedLogo from '../components/AnimatedLogo';

const Home = () => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingHosts);
  const hosts = useSelector(selectHosts);
  const lowAccuracy = useSelector(selectLowAccuracy);
  const onboarded = useSelector(selectOnboarded);
  const navigation = useNavigation();
  const user = useSelector(selectUser);
  const joining = useSelector(selectedHostJoining);
  const guestOnboardedNeeded = Platform.OS !== 'web' && !result(onboarded, 'guest', false);
  const { data: currentHost, loadedFromStorage } = useSelector(selectHost);

  const withinRadius = hosts.filter(h => h.withinRadius);
  const otherHosts = hosts.filter(h => !h.withinRadius);

  const handleRefresh = () => {
    dispatch(getClosestHosts());
  };

  useRefresh(REFRESH_SERVICES.GET_HOSTS, handleRefresh, 60000);

  const handleHost = () => {
    if (currentHost) {
      navigation.navigate('HostHome');
    } else {
      navigation.navigate('StreamLogIn');
    }
  };

  useEffect(() => {
    dispatch(loadUserInformation());
    analytics.setUser(user.email);
    analytics.screen('home');
  }, []);

  const handleQR = hostId => {
    if (hostId) {
      openModal('joinWithQR', {
        title: t('warning_message_join_host_only_with_qr_title'),
        content: t('warning_message_join_host_only_with_qr_content'),
        buttons: [
          {
            text: t('action_ok'),
            onPress: ({ close }) => {
              navigation.navigate('Scanner', { preselectedHost: hostId });
              close();
            },
          },
          {
            text: t('action_cancel'),
            onPress: ({ close }) => {
              close();
            },
          },
        ],
      });
    } else {
      navigation.navigate('Scanner');
    }
  };

  // when currentHost becomes available after login, automatically go to Host screen
  useEffect(() => {
    if (currentHost && !loadedFromStorage) {
      navigation.navigate('HostHome');
    }
  }, [currentHost, loadedFromStorage]);

  if (guestOnboardedNeeded) {
    return <GuestOnboarding />;
  }

  return (
    <View style={styles.root}>
      <BackgroundWrapper>
        <Push />
        <View style={styles.logoContainer}>
          <AnimatedLogo />
          <AccountMenu style={styles.accountMenu} />
        </View>
        <ContentWrapper style={styles.container}>
          <RefreshView
            style={styles.scrollView}
            onRefresh={handleRefresh}
            refreshing={false}
          >
            {size(hosts) ? (
              <>
                {size(withinRadius) ? (
                  <>  
                    {size(otherHosts) ? (
                      <Text style={styles.sectionTitle}>
                        {t('text_available_hosts')}
                      </Text>
                    ) : null}
                    {map(withinRadius, host => (
                      <JoinHostButton
                        key={host.id}
                        {...host}
                        disabled={joining}
                        onHandleQr={() => handleQR(host.id)}
                      />
                    ))}
                    <Space amount={1} />
                  </>
                ) : null}
                {size(otherHosts) ? (
                  <>
                    <Text style={styles.sectionTitle}>
                      {t('text_close_hosts')}
                    </Text>
                    {map(otherHosts, host => (
                      <JoinHostButton
                        key={host.id}
                        {...host}
                      />
                    ))}
                  </>
                ) : null}
              </>
            ) : !loading && (
              <Text style={styles.noHostsText} centered>
                {t('text_no_hosts')}
              </Text>
            )}
            {!loading && lowAccuracy ? (
              <Text style={styles.lowAccuracyText} centered>
                {t('text_low_accuracy')}
              </Text>
            ) : null}
            {loading ? (
              <SkeletonWrapper {...skeleton.joinHost} />
            ) : null}
          </RefreshView>
          <Button
            title={t('action_join_with_qr')}
            onPress={() => handleQR()}
            icon={{ icon: 'qr-code', size: 25 }}
            style={styles.button}
            proceed
          />
          <TouchableOpacity style={styles.linkButton} onPress={handleHost}>
            <Text>{t('action_login_as_host')}</Text>
          </TouchableOpacity>
          <Social style={styles.social} />
        </ContentWrapper>
      </BackgroundWrapper>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  accountMenu: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  scrollView: {
    flex: 1,
    marginBottom: theme.spacing(0.5),
    width: '100%',
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
  },
  container: {
    flex: 1,
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
  },
  footer: {
    flex: 0.1,
  },
  button: {},
  linkButton: {
    paddingVertical: 12,
  },
  sectionTitle: {
    opacity: 0.8,
  },
  noHostsText: {
    backgroundColor: theme.secondaryButton.bg1,
    opacity: 0.8,
    padding: theme.spacing(0.9),
    borderRadius: 10,
  },
  lowAccuracyText: {
    backgroundColor: theme.secondaryButton.bg1,
    opacity: 0.8,
    padding: theme.spacing(0.9),
    borderRadius: 10,
    marginTop: theme.spacing(1),
  },
  social: {
    paddingVertical: 14,
    opacity: 0.6,
  },
});

export default Home;
