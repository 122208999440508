import { result } from 'lodash';

import api from './api';
import { selectServerConfigData } from '../selectors';
import { store } from '../store';

export default class {
  static load() {
    return api.get('/config');
  }

  static getLoadedConfig(key) {
    const config = selectServerConfigData(store.getState());
    return result(config, key);
  }
}
