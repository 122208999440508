import { filter, findIndex } from 'lodash';

export const waitUntil = (fn, condition) => {
  if (condition()) {
    fn();
  } else {
    setTimeout(() => {
      waitUntil(fn, condition);
    }, 500);
  }
};

export const filterDuplicatedObjects = (array, param) => {
  return filter(array, (obj, originalIndex) => {
    const index = findIndex(array, obj2 => obj2[param] === obj[param]);
    return index === originalIndex;
  });
};
