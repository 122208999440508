import { filter, map } from 'lodash';

import * as constants from '../../constants';
import { error as toastError, success as toastSuccess } from '../../helpers/toast';
import { t } from '../../services/i18n';

const initialState = {
  loading: false,
  playlists: [],
  tracklist: [],
  description: {},
  currentPlaylist: null,
  error: null,
  loadingPlaylist: null,
  loadingTracklist: false,
  deletingTrack: null,
  addingSongs: null,
  filterData: null,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.API_GET_PLAYLISTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.API_IMPORT_PLAYLIST_REQUEST: {
      return {
        ...state,
        loadingPlaylist: payload,
      };
    }
    case constants.API_GET_TRACKLIST_REQUEST: {
      return {
        ...state,
        loadingTracklist: true,
      };
    }
    case constants.API_ADD_SONGS_REQUEST: {
      return {
        ...state,
        addingSongs: true,
      };
    }
    case constants.API_DELETE_SONG_REQUEST: {
      return {
        ...state,
        deletingTrack: payload,
      };
    }
    case constants.API_GET_PLAYLISTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        playlists: payload,
      };
    }
    case constants.API_IMPORT_PLAYLIST_SUCCESS: {
      return {
        ...state,
        loadingPlaylist: null,
        playlists: [],
        currentPlaylist: payload,
      };
    }
    case constants.API_GET_TRACKLIST_SUCCESS: {
      const { tracks, count, durationFormatted, recommendation } = payload;
      return {
        ...state,
        loadingTracklist: false,
        tracklist: tracks,
        description: {
          count,
          durationFormatted,
          recommendation: recommendation
            ? t('text_min_tracks_recommendation', {
                number: recommendation,
              })
            : undefined,
        },
      };
    }
    case constants.API_ADD_SONGS_SUCCESS: {
      toastSuccess(t('success_message_add_song'));
      return {
        ...state,
        addingSongs: false,
      };
    }
    case constants.API_DELETE_SONG_SUCCESS: {
      return {
        ...state,
        deletingTrack: null,
        tracklist: filter(state.tracklist, ({ uri }) => uri !== payload.uri),
      };
    }
    case constants.API_GET_PLAYLISTS_FAILURE: {
      toastError(payload);
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case constants.API_IMPORT_PLAYLIST_FAILURE: {
      toastError(payload);
      return {
        ...state,
        loadingPlaylist: null,
        error: payload,
      };
    }
    case constants.API_GET_TRACKLIST_FAILURE: {
      toastError(payload);
      return {
        ...state,
        loadingTracklist: false,
        error: payload,
      };
    }
    case constants.API_ADD_SONGS_FAILURE: {
      toastError(payload);
      return {
        ...state,
        addingSongs: false,
      };
    }
    case constants.CLEAN_PLAYLISTS: {
      return {
        ...state,
        playlists: [],
      };
    }
    case constants.CLEAN_USER: {
      return initialState;
    }
    case constants.API_CLEAN_TRACKLIST_REQUEST: {
      return {
        ...state,
        loadingTracklist: true,
      };
    }
    case constants.API_CLEAN_TRACKLIST_SUCCESS: {
      return {
        ...state,
        tracklist: [],
        loadingTracklist: false,
      };
    }
    case constants.API_CLEAN_TRACKLIST_FAILURE: {
      return {
        ...state,
        loadingTracklist: false,
      };
    }
    case constants.FILTER_PLAYLIST: {
      return {
        ...state,
        filterData: payload,
      };
    }
    default:
      return state;
  }
};
