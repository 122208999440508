import * as constants from '../../constants';
import { error as toastError } from '../../helpers/toast';

const initialState = {
  loading: false,
  isPlaying: false,
  error: null,
  currentDevice: null,
  currentTrack: null,
  showMoreInfo: null,
  visible: false,
  isPremium: true,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.API_GET_TRACK_INFO_REQUEST:
    case constants.API_PLAY_MUSIC_REQUEST:
    case constants.API_PAUSE_MUSIC_REQUEST:
    case constants.API_NEXT_SONG_REQUEST:
    case constants.API_SET_VOLUME_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.API_GET_TRACK_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentTrack: payload,
      };
    }
    case constants.API_PLAY_MUSIC_SUCCESS: {
      return {
        ...state,
        loading: false,
        isPlaying: true,
      };
    }
    case constants.API_PAUSE_MUSIC_SUCCESS: {
      return {
        ...state,
        loading: false,
        isPlaying: false,
      };
    }
    case constants.API_NEXT_SONG_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.API_SET_VOLUME_SUCCESS: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          volume: payload.volume,
        },
        loading: false,
      };
    }
    case constants.API_GET_PLAYER_STATUS_SUCCESS: {
      return {
        ...state,
        ...payload,
        visible: true,
      };
    }
    case constants.API_GET_PLAYER_STATUS_FAILURE: {
      const newState = { ...initialState };
      if (payload === 'no_user_premium') {
        newState.isPremium = false;
      }
      return newState;
    }
    case constants.API_GET_TRACK_INFO_FAILURE:
    case constants.API_PLAY_MUSIC_FAILURE:
    case constants.API_PAUSE_MUSIC_FAILURE:
    case constants.API_NEXT_SONG_FAILURE:
    case constants.API_SET_VOLUME_FAILURE: {
      toastError(payload);
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case constants.REMOVE_TRACK_INFO: {
      return {
        ...state,
        currentTrack: null,
      };
    }
    case constants.CLEAN_USER: {
      return initialState;
    }
    default:
      return state;
  }
};
