import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Button from './Button';
import ContentWrapper from './ContentWrapper';
import Loading from './Loading';
import Text from './Text';
import { loadServerConfig } from '../actions';
import { selectServerConfig } from '../selectors';
import { t } from '../services/i18n';
import theme from '../theme';

const ServerConfigProvider = ({ children }) => {
  const serverConfig = useSelector(selectServerConfig);
  const dispatch = useDispatch();

  const fetch = () => {
    dispatch(loadServerConfig());
  };

  useEffect(() => {
    fetch();
  }, []);

  if (serverConfig.loading) {
    return <Loading />;
  }

  if (serverConfig.error) {
    return (
      <View style={styles.root}>
        <ContentWrapper>
          <View style={styles.textContainer}>
            <Text variant="title" theme={theme} style={styles.text} centered debug>
              {t('error_message_default')}
            </Text>
            <Text theme={theme} style={styles.text} centered>
              {t('error_message_no_server_config')}
            </Text>
          </View>
          <Button title={t('action_try_again')} onPress={() => fetch()} />
        </ContentWrapper>
      </View>
    );
  }

  return children;
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: theme.black.bg1,
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  text: {
    color: theme.black.text,
  },
});

export default ServerConfigProvider;
