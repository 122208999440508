import React from 'react';
import { View, StyleSheet, Platform, TouchableOpacity } from 'react-native';

import SongItemRightSideLayout from './SongItemRightSideLayout';
import Text from './Text';
import theme from '../theme';
import { openModal } from '../services/modal';

const SongItemLayout = ({
  highlighted,
  disabled,
  number,
  name,
  artist,
  actions,
  actionExtra,
  includeVotes,
  votes,
  listened,
  uri,
  paid,
}) => {
  const handlePress = () => {
    openModal('SongItemLayout', {
      title: `${name}\n${artist}`,
      content: 'Qué deseas hacer con esta canción?',
      children: actionExtra,
      buttons: actions.map((action) => {
        return {
          icon: action.icon,
          text: action.title,
          onPress: ({ close }) => {
            action.onPress();
            close();
          },
          variant: action.color,
          disabled: action.disabled,
        }
      }),
    });
  };

  return (
    <TouchableOpacity onPress={!disabled && !highlighted && !listened ? handlePress : undefined}>
      <View
        style={[
          styles.root,
          highlighted ? styles.highlighted : null,
          disabled ? styles.disabled : null,
        ]}
      >
        <View style={styles.numberContainer}>
          <Text style={styles.numberText}>{number}</Text>
        </View>
        <View style={styles.info}>
          <Text style={[styles.bold, styles.trackName]}>{name}</Text>
          <Text>{artist}</Text>
        </View>
        {includeVotes && (
          <SongItemRightSideLayout
            includeVotes={includeVotes}
            votes={votes}
            listened={listened}
            uri={uri}
            paid={paid}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    backgroundColor: theme.color.blackLight,
    padding: 10,
    borderRadius: 10,
  },
  highlighted: {
    backgroundColor: theme.color.red,
  },
  disabled: {
    opacity: 0.5,
  },
  numberContainer: {
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberText: {
    color: theme.color.white,
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: Platform.OS === 'ios' ? 30 : undefined,
  },
  info: {
    flex: 1,
    marginLeft: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  trackName: {
    fontSize: 16,
  },
  explicitLabel: {
    fontSize: 14,
  },
});

export default SongItemLayout;
