import api from './api';

export default class {
  static getClosestHosts(lat, lng, accuracy) {
    return api.get('/guest/find', {
      params: {
        lat,
        lng,
        accuracy,
      },
    });
  }

  static joinHost(hostId) {
    return api.post('/guest/join', {
      hostId,
    });
  }

  static getHostStatus(hostId) {
    return api.get(`/guest/host/${hostId}/status`);
  }

  static sendVote({ hostId, uri }) {
    return api.post(`/guest/host/${hostId}/vote`, {
      uri,
    });
  }

  static addSong({ hostId, uri }) {
    return api.post(`/guest/host/${hostId}/track`, {
      uri,
    });
  }

  static sendCredit({ hostId, uri }) {
    return api.post(`/guest/host/${hostId}/credit`, {
      uri,
    });
  }

  static sendCreditPayment(priceId, cardId) {
    return api.post(`/guest/credit`, {
      priceId,
      cardId,
    });
  }

  static sendSubscriptionPayment(priceId, cardId) {
    return api.post(`/host/subscribe`, {
      priceId,
      cardId,
    });
  }

  static sendCreditInAppPurchase(platform, purchase) {
    return api.post(`/guest/credit/in-app-purchase`, {
      platform,
      purchase,
    });
  }

  static getUserInformation() {
    return api.get(`/guest/user-information`);
  }

  static removeAccount() {
    return api.delete('/guest');
  }
}
