import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollView, StyleSheet } from 'react-native';
import { debounce, result } from 'lodash';

import SearchService from '../../services/search';
import ContentWrapper from '../../components/ContentWrapper';
import PlaylistsWrapper from '../../components/host/PlaylistsWrapper';
import theme from '../../theme';
import Layout from '../../components/Layout';
import Loading from '../../components/Loading';
import * as analytics from '../../services/analytics';
import { useScreenFocus } from '../../hooks/useScreenFocus';
import { getPlaylists } from '../../actions';
import { selectHost, selectPlaylist } from '../../selectors';
import { t } from '../../services/i18n';
import Text from '../../components/Text';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';

const PlaylistsImport = () => {
  const dispatch = useDispatch();
  const [searchPlaylists, setSearchPlaylists] = useState([]);
  const [searching, setSearching] = useState(false);
  const { playlists, loading } = useSelector(selectPlaylist);
  const scrollViewRef = useRef();

  const { data } = useSelector(selectHost);
  const hostId = result(data, 'host.id');

  useScreenFocus(() => {
    analytics.screen('playlistImport');
  });

  useEffect(() => {
    dispatch(getPlaylists());
  }, []);

  const handleOtherPlaylists = useCallback(
    debounce(q => {
      if (!q || q.length < 3) {
        return;
      }
      setSearching(true);
      SearchService.search(hostId, {
        query: q,
        types: 'playlist',
        isGuest: false,
      }).then(response => {
        setSearching(false);
        const list = result(response, 'data.playlists.data.items', []).map(item => ({
          id: item.id,
          name: item.name,
          image: item.images[item.images.length - 1],
          countTracks: result(item, 'tracks.total'),
          recommendation: 1000,
          smallPlaylist: false,
        }));
        setSearchPlaylists(list);
      });
    }, 1000),
    [hostId],
  );

  const handleFocus = (e) => {
    if (!searchPlaylists.length) {
      setTimeout(() => {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }, 500);
    }
  };

  return (
    <Layout title={t('screen_title_import_playlist')}>
      <ContentWrapper>
        {loading ? (
          <Loading theme="transparent" />
        ) : (
          <ScrollView style={styles.root} contentContainerStyle={styles.inner} ref={scrollViewRef}>
            <Text variant="title">{t('screen_title_loudy_playlists')}</Text>
            <PlaylistsWrapper playlists={playlists.filter(playlist => playlist.isLoudy)} />
            <Space />
            <Text variant="title">{t('screen_title_your_playlists')}</Text>
            <PlaylistsWrapper playlists={playlists.filter(playlist => !playlist.isLoudy)} />
            <Space />
            <Text variant="title">{t('screen_title_spotify_playlists')}</Text>
            <TextInput
              placeholder={t('action_search_playlists')}
              placeholderTextColor={theme.color.grayLight}
              onChange={handleOtherPlaylists}
              onFocus={handleFocus}
            />
            <Space />
            {searching ? (
              <Loading />
            ) : searchPlaylists.length ? (
              <PlaylistsWrapper playlists={searchPlaylists} />
            ) : (
              <Space amount={10} />
            )}
          </ScrollView>
        )}
      </ContentWrapper>
    </Layout>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.black.bg1,
    flex: 1,
  },
  inner: {
    paddingVertical: theme.spacing(1),
  },
});

export default PlaylistsImport;
