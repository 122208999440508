import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Audio as ExpoAudio } from 'expo-av';

import ProgressButton from './ProgressButton';
import { t } from '../services/i18n';

const audio = new ExpoAudio.Sound();

const Audio = ({ uri }) => {
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  const loadAudio = async () => {
    await audio.loadAsync({ uri }, { shouldPlay: false });
    await audio.setStatusAsync({ progressUpdateIntervalMillis: 1000 });
    audio.setOnPlaybackStatusUpdate(updateStatus);
  };

  const updateStatus = status => {
    if (status.isLoaded) {
      const { playableDurationMillis, durationMillis, positionMillis, isPlaying } = status;
      const durationMs = durationMillis || playableDurationMillis;
      if (positionMillis > 0 && durationMs === positionMillis && !isPlaying) {
        stop();
        setProgress(0);
      } else {
        setProgress(durationMs ? positionMillis / durationMs : 0);
      }
    }
  };

  useEffect(() => {
    if (uri) {
      loadAudio();
    }
    return () => {
      audio.unloadAsync();
    };
  }, []);

  const handlePlay = async () => {
    if (audio) {
      if (playing) {
        stop();
      } else {
        play();
      }
    }
  };

  const stop = async () => {
    await audio.stopAsync();
    setPlaying(false);
  };

  const play = async () => {
    await audio.playAsync();
    setPlaying(true);
  };

  return uri ? (
    <View style={styles.root}>
      <ProgressButton
        title={t('action_preview')}
        onPress={handlePlay}
        color="secondary"
        progress={progress}
        leftAligned
      />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wave: {
    height: 34,
    width: '100%',
    paddingRight: 20,
    paddingTop: 2,
  },
});

export default Audio;
