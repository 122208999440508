import { takeEvery } from 'redux-saga/effects';

import api from '../services/api';
import LoginService from '../services/login';
import { store } from '../store';
import * as constants from '../constants';
import { setNotificationWebView } from '../actions';

function* pushNotificationClickedWorker({ payload: notification }) {
  LoginService.runEnsuredLogin(async userId => {
    const res = await api.post(`/push`, {
      ...notification,
      userId,
    });
    store.dispatch(setNotificationWebView(res.data));
  });
}

export default function* watchAll() {
  yield takeEvery(constants.PUSH_NOTIFICAITON_CLICKED, pushNotificationClickedWorker);
}
