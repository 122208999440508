import { takeEvery, fork } from 'redux-saga/effects';

import * as constants from '../../constants';
import * as analytics from '../../services/analytics';

function* joinHostWorker({ payload }) {
  const hostId = payload.id;
  const from = payload.from;
  yield fork(analytics.join, hostId, from);
}

function* sendVoteWorker({ payload }) {
  yield fork(analytics.sendVote, payload.hostId, payload.mode);
}

function* addSongWorker({ payload }) {
  const { hostId, uri } = payload;
  yield fork(analytics.addSongs, { hostId, uris: [uri], from: 'guest' });
}

function* errorWorker({ type, payload: errorMessage }) {
  yield fork(analytics.error, type, errorMessage);
}

function* purchasedCreditWorker({ meta: { charge } }) {
  if (charge) {
    yield fork(analytics.purchasedCredit, charge.id, charge.amount / 1000, charge.currency, [{
      item_name: charge.description,
    }]);
  }
}

export default function* watchAll() {
  // actions
  yield takeEvery(constants.API_JOIN_HOST_SUCCESS, joinHostWorker);
  yield takeEvery(constants.API_SEND_VOTE_SUCCESS, sendVoteWorker);
  yield takeEvery(constants.API_ADD_SONG, addSongWorker);
  yield takeEvery(constants.BUY_CREDIT_SUCCESS, purchasedCreditWorker);
  // errors
  yield takeEvery(
    [
      constants.API_GET_CLOSEST_HOSTS_FAILURE,
      constants.API_JOIN_HOST_FAILURE,
      constants.API_GET_CURRENT_TRACK_FAILURE,
    ],
    errorWorker,
  );
}
