import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent as fbLogEvent, setUserId } from "firebase/analytics";

import EventService from './event';

const app = initializeApp({
  "apiKey": "AIzaSyBdzy0kAG071N938-krmEi1tMcXefCYlX0",
  "authDomain": "music-e239c.firebaseapp.com",
  "databaseURL": "https://music-e239c.firebaseio.com",
  "projectId": "music-e239c",
  "storageBucket": "music-e239c.appspot.com",
  "messagingSenderId": "59091977536",
  "appId": "1:59091977536:web:9ef1bd789dad06ae48a6a5",
  "measurementId": "G-TGP914CQZ1"
});

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);
console.log('aaaa2', Object.keys(analytics));

const yesNoEventFormatter = value => (value ? 'Yes' : 'No');

const logEvent = (...args) => {
  console.log('Logging event', args);
  fbLogEvent(analytics, ...args);
  EventService.logEvent(args).catch(e => console.error('Error logging event', e));
};

export const setUser = userId => {
  setUserId(analytics, userId);
};

export const loggedIn = streamingService => {
  logEvent('login', { method: streamingService });
  logEvent('action_logged_in', { streamingService });
};

export const clear = () => {
  analytics().resetAnalyticsData();
};

export const settingsChanged = settings => {
  logEvent('action_change_settings', {
    allowAdd: yesNoEventFormatter(settings.allowAdd),
    allowExplicitContent: yesNoEventFormatter(settings.allowExplicitContent),
    allowOnlyJoinQr: yesNoEventFormatter(settings.allowOnlyJoinQr),
    amountOfSongs: settings.amountOfSongs,
    displayPicture: yesNoEventFormatter(settings.displayPicture),
    genres: settings.genres,
    mode: settings.mode,
    name: settings.name,
    notifications: yesNoEventFormatter(settings.notifications),
    radius: settings.radius,
  });
};

export const appOpened = () => {
  logEvent('action_app_opened');
};

export const networkError = () => {
  logEvent('error_network');
};

export const tab = tabName => {
  const tabEventNames = {
    playlist: 'tab_playlist',
    guests: 'tab_guests',
    settings: 'tab_settings',
    subscription: 'tab_subscription',
  };
  logEvent(tabEventNames[tabName] || tabName);
};

export const screen = screenName => {
  const screenEventNames = {
    // Host
    logIn: 'screen_host_stream_login',
    blacklist: 'screen_host_blacklist',
    playlistImport: 'screen_host_playlist_import',
    hostOnboarding: 'screen_host_onboarding',
    streamLogIn: 'screen_host_stream_login',
    // Guest
    hostPicker: 'screen_guest_host_picker',
    currentHost: 'screen_guest_home',
    scanner: 'screen_guest_scanner',
    credit: 'screen_guest_credit',
    useCredit: 'screen_guest_use_credit',
    guestOnboarding: 'screen_guest_onboarding',
    // Shared
    home: 'screen_home',
    searchTracks: 'screen_search_track',
    selectPlan: 'screen_select_plan',
  };
  logEvent(screenEventNames[screenName] || screenName);
};

export const playerPlay = ({ hostId }) => {
  logEvent('action_player_play', { hostId });
};

export const playerPause = ({ hostId }) => {
  logEvent('action_player_pause', { hostId });
};

export const playerNext = ({ hostId }) => {
  logEvent('action_player_next', { hostId });
};

export const playerVolume = ({ volume, hostId }) => {
  logEvent('action_player_volume', { volume, hostId });
};

export const playlistImported = () => {
  logEvent('action_import_playlist');
};

export const playlistSongAllowed = ({ hostId, allowed, uri }) => {
  if (allowed) {
    logEvent('action_allowed_song', { hostId, uri });
  } else {
    logEvent('action_denied_song', { hostId, uri });
  }
};

export const playlistSongDeleted = ({ hostId, uri }) => {
  logEvent('action_deleted_song', { hostId, uri });
};

export const addSongs = ({ hostId, uris, from }) => {
  logEvent('action_added_song', { hostId, uris, from });
};

export const songHasChanged = ({ hostId, uri }) => {
  logEvent('action_song_changed', { hostId, uri });
};

export const error = (type, rawMessage) => {
  let message = '';
  if (rawMessage instanceof Error) {
    message = `${rawMessage.message} ${rawMessage.stack}`;
  } else if (typeof rawMessage === 'object') {
    try {
      message = JSON.stringify(rawMessage);
    } catch (error) {
      message = 'Unexpected error';
    }
  } else {
    message = String(rawMessage);
  }
  logEvent('custom_error', { type, message });
};

// Guest events
export const join = (hostId, from) => {
  logEvent('action_joined', { hostId, from });
};

export const sendVote = (hostId, mode) => {
  logEvent('action_sent_vote', { hostId, mode });
};

export const purchasedCredit = (transaction, price, currency, items) => {
  const info = { transaction_id: transaction, value: price, currency, items };
  logEvent('purchase', info);
  logEvent('action_purchased_credit', info);
};

export const purchasedSubscription = () => {

  logEvent('action_subscribed');
};

export const loginFailed = error => {
  logEvent('login_failed', error);
};
