import axios from 'axios';
import { result } from 'lodash';

import config from '../config';
import {
  selectHost,
  selectedHost,
  selectUserAuthorization,
  selectUser,
} from '../selectors';
import { store } from '../store';
import { clearData } from './storage';
import { getNavigation } from './navigation';
import { logoutStream, logoutUser, closeHost } from '../actions';
import { warning as toastWarning } from '../helpers/toast';
import { t } from './i18n';

const { endpoint } = config.server;

export const getEndpoint = () => {
  return endpoint;
};

const api = axios.create({
  baseURL: endpoint,
  timeout: 30 * 1000,
});

export const getHeaders = () => {
  const state = store.getState();
  const userAuthorization = selectUserAuthorization(state);
  const { data: host } = selectHost(state);
  const currentlySelectedHost = selectedHost(state);
  const user = selectUser(state);

  const headers = {};
  if (host && host.token) {
    headers.Authorization = host.token;
  }
  if (userAuthorization) {
    headers.UserAuthorization = userAuthorization;
  }
  headers.User = result(user, 'email', '');
  headers.SelectedHost = result(currentlySelectedHost, 'id', '');
  return headers;
};

api.interceptors.request.use(config => {
  const headers = getHeaders();  
  for (let header in headers) {
    config.headers[header] = headers[header];
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    const state = store.getState();
    const currentlySelectedHost = selectedHost(state);
    if (result(error, 'response.status') === 401) {
      const data = error.response.data;
      const navigation = getNavigation();
      if (data === 'Invalid user') {
        console.log('Lost client session');
        clearData('user');
        store.dispatch(logoutUser());
        if (navigation && navigation.popToTop) {
          navigation.popToTop();
        }
      } else if (data === 'Invalid Host') {
        console.log('Lost sync between host and guest');
        store.dispatch(closeHost());
        if (navigation) {
          navigation.navigate('Home');
        }
        toastWarning(t('warning_message_disconnected_host'));
      } else {
        console.log('Lost spotify session');
        store.dispatch(logoutStream());
        if (navigation && !currentlySelectedHost) {
          navigation.navigate('StreamLogIn');
        }
      }
    }
    return Promise.reject(error);
  },
);

export default api;
