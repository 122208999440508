import { all, call, select, put, takeLatest } from 'redux-saga/effects';
import { result } from 'lodash';

import SearchService from '../services/search';
import * as constants from '../constants';
import { selectHost } from '../selectors';
import { mapHttpError } from '../helpers/format';

function* searchTracksWorker({ payload }) {
  let hostId = payload.hostId;
  const isGuest = Boolean(hostId);
  if (!hostId) {
    const { data } = yield select(selectHost);
    hostId = result(data, 'host.id');
  }
  try {
    yield put({ type: constants.API_SEARCH_TRACKS_REQUEST, payload });
    const response = yield call(SearchService.search, hostId, {
      ...payload,
      types: 'track',
      isGuest,
    });
    yield put({ type: constants.API_SEARCH_TRACKS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: constants.API_SEARCH_TRACKS_FAILURE,
      payload: mapHttpError(error),
    });
  }
}

export default function* watchSearchLibrary() {
  yield all([takeLatest(constants.API_SEARCH_TRACKS, searchTracksWorker)]);
}
