import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { t } from '../services/i18n';
import Push from './Push';
import { Dimensions } from 'react-native';
import { IconButton } from 'react-native-paper';
import theme from '../theme';
import { Platform } from 'react-native';

const styles = StyleSheet.create({
  instructions: {
    color: 'white',
    fontSize: 20,
    textAlign: 'center',
    backgroundColor: 'black',
    padding: 20,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export default function ScannerLayer({ handleCancel }) {
  const boxSize = Math.min(Dimensions.get('window').width * 0.75, 400);
  const arrowSize = boxSize * 0.2;

  return (
    <>
      <Push />
      <Text style={styles.instructions}>{t('Escanea un codigo QR de Loudy')}</Text>
      <View style={{ justifyContent: 'center', flex: 1 }}>
        <IconButton
          icon={'close'}
          style={styles.closeButton}
          onPress={handleCancel}
          color={theme.color.white}
        />
        <View style={{ alignItems: 'center', justifyContent: 'space-between', height: boxSize, marginBottom: Platform.OS === 'web' ? 60 : 0 }}>
          <View
            style={{
              width: boxSize,
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: arrowSize,
            }}
          >
            <View
              style={{
                width: arrowSize,
                borderColor: 'white',
                borderLeftWidth: 5,
                borderTopWidth: 5,
              }}
            />
            <View
              style={{
                width: arrowSize,
                borderColor: 'white',
                borderRightWidth: 5,
                borderTopWidth: 5,
              }}
            />
          </View>
          <View
            style={{
              width: boxSize,
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: arrowSize,
            }}
          >
            <View
              style={{
                width: arrowSize,
                borderColor: 'white',
                borderLeftWidth: 5,
                borderBottomWidth: 5,
              }}
            />
            <View
              style={{
                width: arrowSize,
                borderColor: 'white',
                borderRightWidth: 5,
                borderBottomWidth: 5,
              }}
            />
          </View>
        </View>
      </View>
    </>
  );
}
