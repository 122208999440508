import Constants from 'expo-constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Platform } from 'react-native';
import { makeRedirectUri, useAuthRequest, Prompt } from 'expo-auth-session';

import Button from './Button';
import config from '../config';
import { loginWithSpotify } from '../actions';
import { t } from '../services/i18n';

const SpotifyButton = () => {
  const [url, setUrl] = useState(null);
  const { clientId, scopes, authorizationEndpoint } = config.spotify;

  const dispatch = useDispatch();
  const uri = Platform.OS === 'web' ? '' : '/host';
  const redirectUrl = `${Constants.manifest.scheme}://host`;
  const convertedRedirectUrl = makeRedirectUri({
    native: redirectUrl,
    path: uri,
  }).replace('///', '//');
  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId,
      scopes,
      prompt: Prompt.Consent,
      usePKCE: false,
      redirectUri: convertedRedirectUrl,
    },
    {
      authorizationEndpoint,
    },
  );

  useEffect(() => {
    if (request) {
      request
        .makeAuthUrlAsync({ authorizationEndpoint })
        .then(newUrl => setUrl(`${newUrl}&show_dialog=true`));
    }
  }, [request]);

  useEffect(() => {
    if (response?.type === 'success') {
      const { code } = response.params;
      dispatch(
        loginWithSpotify({
          code,
          redirectUrl: convertedRedirectUrl,
        }),
      );
    }
  }, [response]);

  return (
    url && (
      <Button
        title={t('action_login_with_spotify')}
        color="spotify"
        onPress={() => promptAsync({ url })}
        variant="fit"
      />
    )
  );
};

export default SpotifyButton;
