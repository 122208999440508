export const required = value => {
  return value && value.trim() !== '';
};

export const min = (value, min) => {
  return value >= min;
};

export const max = (value, max) => {
  return value <= max;
};
