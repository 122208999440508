import React from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { View, StyleSheet, Platform } from 'react-native';

import Spinner from './Spinner';
import Text from './Text';
import { useSelector } from 'react-redux';
import { selectSendingVoteStatus } from '../selectors';
import { t } from '../services/i18n';

const Clock = () => {
  return (
    <View style={styles.votes}>
      <FontAwesome5 style={styles.paidIcon} name="clock" size={20} color="gray" />
    </View>
  );
};

const SongItemRightSideLayout = ({ includeVotes, votes, listened, uri, paid }) => {
  const sendingVoteUri = useSelector(selectSendingVoteStatus);
  if (includeVotes) {
    if (sendingVoteUri === uri) {
      return (
        <View style={styles.votes}>
          <Spinner size={20} color="white" style={styles.spinner} />
        </View>
      );
    }
    if (listened) {
      return <Clock />;
    }
    if (paid) {
      return (
        <View style={styles.votes}>
          <FontAwesome5 style={styles.paidIcon} name="dollar-sign" size={20} color="white" />
        </View>
      );
    } else {
      return (
        <View style={styles.votes}>
          <Text style={styles.votesNumber}>{votes || '0'}</Text>
          <Text style={styles.votesLabel}>{t('text_votes')}</Text>
        </View>
      );
    }
  } else {
    if (listened) {
      return <Clock />;
    }
    return null;
  }
};

const styles = StyleSheet.create({
  paidIcon: {
    paddingVertical: 7,
    paddingHorizontal: 11,
  },
  votes: {
    borderLeftColor: '#FFFFFF80',
    borderLeftWidth: 1,
    paddingHorizontal: 10,
    marginLeft: 5,
  },
  votesNumber: {
    fontSize: 24,
    lineHeight: Platform.OS === 'ios' ? 34 : 28,
    textAlign: 'center',
  },
  votesLabel: {
    fontSize: 12,
    lineHeight: 12,
    textAlign: 'center',
  },
  spinner: {
    padding: 7,
  },
});

export default SongItemRightSideLayout;
