import React from 'react';
import { useDispatch } from 'react-redux';

import { setOnboarded } from '../../../actions';
import OnboardingLayout from '../../../components/OnboardingLayout';
import { t } from '../../../services/i18n';

const Screen1 = ({ navigation }) => {
  const dispatch = useDispatch();

  const handleSkip = () => {
    dispatch(
      setOnboarded({
        host: true,
      }),
    );
  };

  return (
    <OnboardingLayout
      source={require('../../../assets/hostonboarding2.png')}
      text={t('text_host_onboarding_2')}
      extraText={t('text_host_onboarding_extra_2')}
      step={1}
      steps={3}
      buttonText={t('action_host_onboarding_2')}
      onNext={() => navigation.navigate('Screen2')}
      onSkip={handleSkip}
    />
  );
};

export default Screen1;
