import React from 'react';
import {
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Text from './Text';
import theme from '../theme';
import CreditIcon from './CreditIcon';
import HostMode from './host/HostMode';
import HelpButton from './HelpButton';
import IconButton from './IconButton';
import { t } from '../services/i18n';
import Push from './Push';
import BackButton from './BackButton';

const Layout = ({
  children,
  title,
  image,
  includeCredit,
  includeHostMode,
  hint,
  noBackButton,
  disableGoBackButton,
  handleBack,
}) => {
  const navigation = useNavigation();
  const canGoBack = !noBackButton && navigation.canGoBack();

  const handleCredit = () => {
    navigation.navigate('Credit');
  };

  const backButton = canGoBack && (
    <BackButton
      style={styles.backButton}
      disabled={disableGoBackButton}
      onPress={() => (handleBack ? handleBack() : navigation.goBack())}
    />
  );

  return (
    <View style={styles.root}>
      <View style={styles.top}>
        {image ? (
          <ImageBackground source={image} style={styles.image} imageStyle={styles.imageOpacity}>
            <Push />
            <View style={styles.imageInner}>
              {backButton}
              <Text variant="title" centered={true} style={styles.imageText} debug>
                {title}
              </Text>
              <View style={styles.iconRight}>
                {includeCredit && <CreditIcon onPress={handleCredit} />}
                {includeHostMode && <HostMode />}
              </View>
            </View>
          </ImageBackground>
        ) : (
          <View style={styles.noImage}>
            <Push />
            <View style={styles.noImageInner}>
              {backButton}
              <View style={styles.title}>
                <Text style={styles.imageTextNoImage} numberOfLines={2} debug>
                  {title}
                </Text>
              </View>
              <View style={styles.iconRight}>
                {includeCredit && <CreditIcon onPress={handleCredit} />}
                {includeHostMode && <HostMode />}
              </View>
              {hint && <HelpButton text={t(hint)} style={styles.iconRight} />}
            </View>
          </View>
        )}
      </View>
      <KeyboardAvoidingView
        style={styles.keyboardAvoiding}
        behavior={Platform.OS === 'ios' ? 'padding' : null}
      >
        <View style={styles.bottom}>{children}</View>
      </KeyboardAvoidingView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    height: Dimensions.get('window').height,
    backgroundColor: theme.primaryButton.bg1,
  },
  top: {
    backgroundColor: theme.primaryButton.bg1,
    maxWidth: Platform.OS === 'web' ? 1000 : undefined,
    marginLeft: Platform.OS === 'web' ? 'auto' : undefined,
    marginRight: Platform.OS === 'web' ? 'auto' : undefined,
    width: Platform.OS === 'web' ? '100%' : undefined,
  },
  imageText: {
    marginBottom: 0,
    marginTop: Platform.OS === 'ios' ? 2 : 6,
    flex: 1,
    textAlignVertical: 'bottom',
    marginHorizontal: 50,
    textAlign: 'center',
    fontFamily: 'Poppins_600SemiBold',
  },
  title: {
    marginHorizontal: 50,
  },
  imageTextNoImage: {
    marginBottom: 0,
    marginTop: Platform.OS === 'ios' ? 0 : 4,
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 26,
  },
  bottom: {
    flex: 1,
    backgroundColor: theme.black.bg1,
  },
  bottomNoSpacing: {},
  image: {
    flex: 1,
    flexDirection: 'column',
  },
  imageInner: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-end',
    textAlignVertical: 'center',
    height: '100%',
    flexDirection: 'row',
  },
  noImage: {
  },
  noImageInner: {
    alignSelf: 'stretch',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 20,
    paddingVertical: 0,
    height: 70,
    flexDirection: 'row',
  },
  backButton: {
    marginLeft: 10,
    left: 0,
    position: 'absolute',
  },
  iconRight: {
    position: 'absolute',
    right: 0,
    marginRight: 20,
  },
  imageOpacity: { opacity: 0.3 },
  keyboardAvoiding: {
    flex: 1,
  },
});

export default Layout;
