import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';

import api, { getEndpoint, getHeaders } from './api';

export const generate = () => {
  return api.get('/host/merch');
};

export const download = () => {
  if (Platform.OS === 'web') {
    return downloadPortal();
  }
  const headers = getHeaders();
  return FileSystem.downloadAsync(
    `${getEndpoint()}/host/merch/status`,
    FileSystem.documentDirectory + 'loudy-merch.zip',
    {
      headers,
    },
  );
};

const downloadPortal = () => {
  return api
    .get('/host/merch/status', {
      responseType: 'blob',
    })
    .then(response => {
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'loudy-merch.zip');
        document.body.appendChild(link);
        link.click();
      }
      return response;
    });
};
