import { useEffect, useMemo, useState } from 'react';

export const useLoadingStatus = (loading, skeleton) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingDetected, setLoadingDetected] = useState(false);

  useEffect(() => {
    if (loading) {
      setLoadingDetected(true);
    }
    if (!loading && loadingDetected) {
      setTimeout(
        () => {
          setInitialLoading(false);
        },
        skeleton ? 1000 : 0,
      );
    }
  }, [loading]);

  return useMemo(
    () => ({
      initialLoading,
    }),
    [initialLoading],
  );
};
