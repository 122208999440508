import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

import appTheme from '../theme';
import SpinnerLoudy from './SpinnerLoudy';

const Loading = ({ style, title = 'Cargando...', titleStyle, theme = 'black', ...props }) => {
  return (
    <View style={[styles.root, styles[`${theme}Theme`], style]}>
      <SpinnerLoudy {...props} />
      {title && (
        <Text theme={theme} style={[styles.text, styles[`${theme}TextTheme`], titleStyle]}>
          {title}
        </Text>
      )}
    </View>
  );
};

Loading.defaultProps = {
  size: 'large',
  color: appTheme.color.red,
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 10,
  },
  text: {
    marginTop: 10,
    textAlign: 'center',
  },
  transparentTheme: {
    backgroundColor: 'transparent',
  },
  transparentTextTheme: {
    color: appTheme.black.text,
  },
  blackTheme: {
    backgroundColor: appTheme.black.bg1,
  },
  blackTextTheme: {
    color: appTheme.black.text,
    opacity: 0.8,
  },
  whiteTheme: {
    backgroundColor: appTheme.white.bg1,
  },
  whiteTextTheme: {
    color: appTheme.white.text,
    opacity: 0.8,
  },
});

export default Loading;
