import { takeEvery, fork } from 'redux-saga/effects';

import * as constants from '../../constants';
import * as analytics from '../../services/analytics';

function* saveSettingsWorker({ payload: settings }) {
  yield fork(analytics.settingsChanged, settings);
}

function* loginWithSpotifyWorker() {
  yield fork(analytics.loggedIn, 'spotify');
}

function* playWorker({ payload }) {
  yield fork(analytics.playerPlay, payload);
}

function* pauseWorker({ payload }) {
  yield fork(analytics.playerPause, payload);
}

function* nextWorker({ payload }) {
  yield fork(analytics.playerNext, payload);
}

function* setVolumeWorker({ payload }) {
  yield fork(analytics.playerVolume, payload);
}

function* importPlaylistWorker() {
  yield fork(analytics.playlistImported);
}

function* allowSongWorker({ payload }) {
  yield fork(analytics.playlistSongAllowed, payload);
}

function* deleteSongWorker({ payload }) {
  yield fork(analytics.playlistSongDeleted, payload);
}

function* addSongsWorker({ payload }) {
  yield fork(analytics.addSongs, payload);
}

function* errorWorker({ type, payload: errorMessage }) {
  yield fork(analytics.error, type, errorMessage);
}

function* purchasedSubscriptionWorker() {
  yield fork(analytics.purchasedSubscription);
}

export default function* watchAll() {
  // actions
  yield takeEvery(constants.API_SAVE_SETTINGS, saveSettingsWorker);
  yield takeEvery(constants.API_LOGIN_WITH_SPOTIFY, loginWithSpotifyWorker);
  yield takeEvery(constants.API_PLAY_MUSIC_SUCCESS, playWorker);
  yield takeEvery(constants.API_PAUSE_MUSIC_SUCCESS, pauseWorker);
  yield takeEvery(constants.API_NEXT_SONG_SUCCESS, nextWorker);
  yield takeEvery(constants.API_SET_VOLUME, setVolumeWorker);
  yield takeEvery(constants.API_IMPORT_PLAYLIST_SUCCESS, importPlaylistWorker);
  yield takeEvery(constants.API_ALLOW_SONG_SUCCESS, allowSongWorker);
  yield takeEvery(constants.API_DELETE_SONG_SUCCESS, deleteSongWorker);
  yield takeEvery(constants.API_ADD_SONGS_SUCCESS, addSongsWorker);
  yield takeEvery(constants.BUY_SUBSCRIPTION_SUCCESS, purchasedSubscriptionWorker);
  // errors
  yield takeEvery(
    [
      constants.API_LOGIN_WITH_SPOTIFY_FAILURE,
      constants.API_SAVE_SETTINGS_FAILURE,
      constants.API_SAVE_CURRENT_LOCATION_FAILURE,
      constants.API_GET_TRACK_INFO_FAILURE,
      constants.API_PLAY_MUSIC_FAILURE,
      constants.API_PAUSE_MUSIC_FAILURE,
      constants.API_NEXT_SONG_FAILURE,
      constants.API_SET_VOLUME_FAILURE,
      constants.API_GET_PLAYLISTS_FAILURE,
      constants.API_IMPORT_PLAYLIST_FAILURE,
      constants.API_GET_TRACKLIST_FAILURE,
      constants.API_ALLOW_SONG_FAILURE,
      constants.API_DELETE_SONG_FAILURE,
      constants.API_GET_GUESTS_FAILURE,
      constants.API_ADD_SONGS_FAILURE,
    ],
    errorWorker,
  );
}
