import React from 'react';

import SongItemLayout from '../SongItemLayout';
import { t } from '../../services/i18n';

const BannedTrack = ({ item: { uri, name, artist }, index, onAllowSong, updatingBlacklist }) => {
  const loading = updatingBlacklist === uri;

  const getCollapsibleActions = () => {
    return [
      {
        title: t('action_allow_song'),
        onPress: () => onAllowSong(uri),
        icon: { icon: 'clear' },
        color: 'primary',
      },
    ];
  };

  return (
    <SongItemLayout
      key={uri}
      number={index + 1}
      name={name}
      artist={artist}
      loading={loading}
      actions={getCollapsibleActions()}
    />
  );
};

export default BannedTrack;
