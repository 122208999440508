import React from 'react';
import { StyleSheet, View } from 'react-native';

import Text from '../../components/Text';
import theme from '../../theme';
import { t } from '../../services/i18n';
import ContentWrapper from '../ContentWrapper';

// TODO: add support for subscription on web
// TODO: change apple button when published
const WebSubscription = () => {  
  return (
    <View style={styles.container}>
      <ContentWrapper>
        <Text variant="title" centered>
          {t('text_subscription_web_title')}
        </Text>
        <View style={styles.inner}>
          <Text centered>{t('text_subscription_web_instructions')}</Text>
          <div style={{ textAlign: 'center' }}>
            <a href="https://play.google.com/store/apps/details?id=com.sidins.loudy" target="_blank">
              <img style={{ width: 'auto', height: '55px', margin: 10 }} src="https://loudy.app/images/google-play.webp" alt="Google Play" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.sidins.loudy" target="_blank">
              <img style={{ width: 'auto', height: '55px', margin: 10 }} src="https://loudy.app//images/app-store.webp" alt="App Store" />
            </a>
          </div>
        </View>
      </ContentWrapper>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.black.bg1,
    justifyContent: 'center',
  },
  inner: {
    flex: 1,
  },
});

export default WebSubscription;
