import * as constants from '../constants';

const initialState = {
  on: false,
  origin: { x: 0, y: 0 },
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_CONFETTI_STATUS: {
      return {
        on: payload.on,
        origin: payload.origin,
      };
    }
    default:
      return state;
  }
};
