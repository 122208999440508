import { result } from 'lodash';
import { setPostLoginCallback } from '../actions';
import { selectUser } from '../selectors';
import { store } from '../store';
import api from './api';

export default class {
  static loginWithSpotify(data) {
    return api.post('/login/spotify', data);
  }

  static verifyUser(data) {
    return api.post('/login/verify', data);
  }

  static handleAccessToken(data) {
    return api.post('/login/token', data);
  }

  static clean() {
    return api.post('/login/clean');
  }

  static runEnsuredLogin(cb) {
    const state = store.getState();
    const user = selectUser(state);
    const userId = result(user, 'email');
    if (userId) {
      cb(userId);
    } else {
      // if not logged in, store it in redux for later execution
      store.dispatch(setPostLoginCallback(cb));
    }
  }
}
