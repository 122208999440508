import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, View, StyleSheet, Easing } from 'react-native';

const images = [
  require('../assets/loading/loudy_0012_auriculares-min.png'),
  require('../assets/loading/loudy_0011_hamburguesa-min.png'),
  require('../assets/loading/loudy_0010_stereo-min.png'),
  require('../assets/loading/loudy_0001_celular-min.png'),
  require('../assets/loading/loudy_0003_notas-musicales-min.png'),
  require('../assets/loading/loudy_0009_cerveza-min.png'),
  require('../assets/loading/loudy_0008_boca-min.png'),
  require('../assets/loading/loudy_0006_guitarra-min.png'),
  require('../assets/loading/loudy_0007_pizza-min.png'),
  require('../assets/loading/loudy_0005_tambor-min.png'),
  require('../assets/loading/loudy_0000_cassette-min.png'),
  require('../assets/loading/loudy_0004_microfono-min.png'),
  require('../assets/loading/loudy_0002_notas-musicales-2-min.png'),
];

const sizes = {
  large: 84,
  medium: 64,
  small: 32,
};

const TIME_SHOWING = 350;
const TIME_TRANSITIONING = 100;
const TIME_SCALING = 50;
const FINAL_OPACITY = 0.8;
const INITIAL_SCALE = 0;

const SpinnerLoudy = ({ size = 'large', ...props }) => {
  const initialImage = Math.floor(Math.random() * images.length);
  const opacity = useRef(new Animated.Value(FINAL_OPACITY)).current;
  const scale = useRef(new Animated.Value(1)).current;
  const [active, setActive] = useState(initialImage);

  const animateNext = useCallback(() => {
    let next = active + 1;
    if (next === images.length) {
      next = 0;
    }

    // show
    Animated.parallel([
      Animated.timing(opacity, {
        toValue: FINAL_OPACITY,
        duration: TIME_TRANSITIONING,
        useNativeDriver: false,
        easing: Easing.ease,
      }).start(),
      Animated.timing(scale, {
        toValue: 1,
        duration: TIME_SCALING,
        useNativeDriver: false,
        easing: Easing.bezier(0.175, 0.885, 0.32, 1.275),
      }).start(),
    ]);

    setTimeout(() => {
      // hide
      Animated.parallel([
        Animated.timing(opacity, {
          toValue: 0,
          duration: TIME_TRANSITIONING,
          useNativeDriver: false,
          delay: TIME_SCALING - TIME_TRANSITIONING,
          easing: Easing.ease,
        }),
        Animated.timing(scale, {
          toValue: INITIAL_SCALE,
          duration: TIME_SCALING,
          useNativeDriver: false,
          // easing: Easing.bezier(0.68, -0.55, 0.265, 1.55),
        }),
      ]).start(() => {
        // change image
        setActive(next);
      });
    }, TIME_SHOWING);
  }, [active, opacity, scale]);

  useEffect(() => {
    animateNext();
  }, [active]);

  return (
    <View {...props} style={styles.root}>
      <Animated.Image
        source={images[active]}
        resizeMode="contain"
        fadeDuration={0}
        style={{
          opacity: 1,
          width: sizes[size],
          height: sizes[size],
          transform: [{ scale }],
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default SpinnerLoudy;
