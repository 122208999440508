import React, { useMemo, useState } from 'react';
import { Platform, View, StyleSheet, ImageBackground, TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { result } from 'lodash';

import ImagePickerMobile from './mobile/ImagePicker';
import ImagePickerWeb from './web/ImagePicker';
import Text from './Text';
import Spinner from './Spinner';
import theme from '../theme';

const ImagePicker = ({ error, label, style, image, setImage }) => {
  const [cropActive, setCropActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const time = useMemo(() => new Date().valueOf(), [image && image.uri]);

  const getNoCachedImage = () => {
    if (image.uri) {
      if (image.uri.includes('base64')) {
        return image.uri;
      }
      return `${image.uri}?time=` + time;
    }
    return null;
  };

  return (
    <View style={[styles.root, result(style, 'root')]}>
      {label}
      {Platform.OS === 'web' ? (
        <ImagePickerWeb setImage={setImage} handleCropActive={setCropActive} />
      ) : (
        <ImagePickerMobile setImage={setImage} />
      )}
      {!cropActive && image && (
        <View style={styles.imageRoot}>
          <ImageBackground
            source={{ uri: getNoCachedImage() }}
            style={styles.imagePreview}
            borderRadius={5}
            onLoadStart={() => setLoading(true)}
            onLoadEnd={() => setLoading(false)}
          >
            {loading ? (
              <Spinner style={styles.spinner} size={15} color="white" />
            ) : (
              <TouchableOpacity onPress={() => setImage(null)} style={styles.removeButton}>
                <FontAwesome name="remove" size={18} color={theme.color.white} />
              </TouchableOpacity>
            )}
          </ImageBackground>
        </View>
      )}
      {error && (
        <Text variant="formError" marginBottom={0} customStyles={styles.error}>
          {error}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  imageRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  imagePreview: { width: 200, height: 200 },
  removeButton: {
    marginLeft: 'auto',
    marginTop: 10,
    marginRight: 10,
    backgroundColor: theme.color.red,
    elevation: 1,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    borderRadius: 15,
    minWidth: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    marginTop: theme.spacing(0.5),
  },
  spinner: {
    height: 200,
  },
});

export default ImagePicker;
