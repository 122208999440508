import * as constants from '../../constants';
import { hideHostModeNotification, showHostModeNotification } from '../../services/notification';
import { clearData, saveData } from '../../services/storage';

const initialState = {
  loading: false,
  loadingSettings: false,
  loadingSubscription: false,
  updating: false,
  data: null,
  error: null,
  loadedFromStorage: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.API_GET_HOST_SUCCESS: {
      const newData = {
        ...state.data,
        host: payload.host,
      };
      saveData('host', newData);
      if (newData.host.hostMode) {
        showHostModeNotification();
      } else {
        hideHostModeNotification();
      }
      return {
        ...state,
        data: newData,
      };
    }
    case constants.API_LOGIN_WITH_SPOTIFY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.API_SAVE_SETTINGS_REQUEST: {
      return {
        ...state,
        loadingSettings: true,
      };
    }
    case constants.API_UPDATE_HOST_MODE_REQUEST: {
      const newData = {
        ...state.data,
        host: {
          ...state.data.host,
          hostMode: payload,
        },
      };
      console.log('bbbbbbbbb2');
      return {
        ...state,
        updating: true,
        data: newData,
      };
    }
    case constants.API_LOGIN_WITH_SPOTIFY_SUCCESS: {
      saveData('host', payload);
      console.log('bbbbbbbbb3');
      return {
        loading: false,
        data: payload,
        error: null,
      };
    }
    case constants.API_SAVE_SETTINGS_SUCCESS: {
      const { settings } = payload;
      const newData = {
        ...state.data,
        host: {
          ...state.data.host,
          settings,
        },
      };
      saveData('host', newData);
      console.log('bbbbbbbbb4');
      return {
        ...state,
        loadingSettings: false,
        data: newData,
      };
    }
    case constants.BUY_SUBSCRIPTION_SUCCESS: {
      const newData = {
        ...state.data,
        host: {
          ...state.data.host,
          subscription: payload.subscription,
        },
      };
      console.log('bbbbbbbbb5');
      saveData('host', newData);
      return {
        ...state,
        loadingSubscription: false,
        data: newData,
      };
    }
    case constants.API_UPDATE_HOST_MODE_SUCCESS: {
      const newData = {
        ...state.data,
        host: {
          ...state.data.host,
          hostMode: payload.hostMode,
        },
      };
      console.log('bbbbbbbbb6');

      saveData('host', newData);
      return {
        ...state,
        data: newData,
        updating: false,
      };
    }
    case constants.API_LOGIN_WITH_SPOTIFY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case constants.API_SAVE_SETTINGS_FAILURE: {
      return {
        ...state,
        loadingSettings: false,
        error: payload,
      };
    }
    case constants.API_UPDATE_HOST_MODE_FAILURE: {
      const newData = {
        ...state.data,
        host: {
          ...state.data.host,
          hostMode: payload,
        },
      };
      console.log('bbbbbbbbb7');
      return {
        ...state,
        data: newData,
        updating: false,
      };
    }
    case constants.STORAGE_LOAD: {
      if (payload.host && payload.host.token) {
        console.log('bbbbbbbbb8');
        return {
          ...state,
          loadedFromStorage: true,
          data: payload.host,
        };
      }
      return state;
    }
    case constants.CLEAN_USER: {
      console.log('bbbbbbbbb9');
      return initialState;
    }
    case constants.LOGOUT_STREAM: {
      clearData('login');
      console.log('bbbbbbbbb10');
      return initialState;
    }
    default:
      return state;
  }
};
