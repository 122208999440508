import { useCallback, useEffect, useState } from 'react';

import { getSubscription } from '../services/iap';
import { useAppStateOn } from './useAppStateOn';

export const useSubscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  const load = useCallback(() => {
    setLoading(true);
    getSubscription(true).then(sub => {
      setSubscription(sub);
      setLoading(false);
    }).catch(e => {
      setSubscription(null);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    load();
  }, []);

  useAppStateOn(() => {
    load(true);
  });

  return {
    loading,
    subscription,
    reload: load,
  };
};
