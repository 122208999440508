import * as constants from '../../constants';

const initialState = {
  loading: false,
  data: {
    credit: 0,
    creditHistory: [],
  },
  error: null,
  postLoginCallback: null,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.API_GET_USER_INFORMATION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.API_GET_USER_INFORMATION_SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    }
    case constants.BUY_CREDIT_SUCCESS: {
      return {
        ...state,
        data: payload,
      };
    }
    case constants.API_SEND_CREDIT_SUCCESS: {
      if (!payload.guest) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          credit: payload.guest.credit,
          creditHistory: payload.guest.creditHistory,
        },
      };
    }
    case constants.API_GET_USER_INFORMATION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case constants.SET_POST_LOGIN_CALLBACK: {
      return {
        ...state,
        postLoginCallback: payload
      };
    }
    case constants.CLEAN_USER: {
      return initialState;
    }
    default:
      return state;
  }
};
