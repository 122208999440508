import * as Localization from 'expo-localization';
import { I18n } from "i18n-js";

import en from '../i18n/es';
import es from '../i18n/es';

const i18n = new I18n({
  en,
  es,
});

i18n.locale = Localization.locale;
i18n.enableFallback = true;

export const t = (key, params) => {
  if (!key) {
    return ' ';
  }
  if (key.indexOf(' ') > -1) {
    return key;
  }
  let translated = i18n.t(key, { defaultValue: key });

  return Object.keys(params || {}).reduce(
    (acc, key) => acc.replace(`{${key}}`, params[key]),
    translated,
  );
};

export default {
  t,
};
