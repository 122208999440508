import React from 'react';
import { StyleSheet, View } from 'react-native';

import Button from './Button';

const Buttons = ({ buttons, onPress, style, ...props }) => {
  return (
    <View style={[styles.root, style]}>
      {buttons.map((button, index) => (
        <Button
          key={button}
          title={button}
          onPress={() => onPress(index)}
          variant="small"
          {...props}
          style={[
            styles.button,
            index === buttons.length - 1 ? styles.lastButton : {},
          ]}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    marginRight: 10,
  },
  lastButton: {
    marginRight: 0,
  },
});

export default Buttons;
