import React from 'react';
import { StyleSheet } from 'react-native';

import IconButton from './IconButton';

const BackButton = ({
  style,
  onPress,
  disabled,
}) => {
  return (
    <IconButton
      icon="arrow-back"
      color="white"
      disabled={disabled}
      size={28}
      style={[style, styles.icon]}
      onPress={onPress}
    />
  );
}

const styles = StyleSheet.create({
  icon: {}
});

export default BackButton;
