import api from './api';

export const getBlacklist = () => {
  return api.get('/host/blacklist');
};

export const allowSong = ({ uri, status }) => {
  return api.put(`/host/blacklist/${uri}`, {
    status,
  });
};
