import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { WebView } from 'react-native-webview';

import { selectNotificationWebView } from '../selectors';
import theme from '../theme';
import { setNotificationWebView } from '../actions';

const NotificationWebView = () => {
  const dispatch = useDispatch();
  const webView = useSelector(selectNotificationWebView);
  if (webView && webView.valid) {
    return (
      <View style={styles.root}>
        <View style={styles.inner}>
          <WebView
            originWhitelist={['*']}
            source={{ html: webView.content }}
            onMessage={event => {
              const action = JSON.parse(event.nativeEvent.data);
              dispatch(setNotificationWebView(null));
            }}
            scalesPageToFit={false}
          />
        </View>
      </View>
    );
  }
  return null;
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2000,
  },
  inner: {
    width: '90%',
    height: '80%',
    backgroundColor: 'transparent',
    borderColor: theme.color.red,
    borderWidth: 2,
    borderRadius: 10,
    padding: 10,
  },
});

export default NotificationWebView;
