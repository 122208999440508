import React from 'react';
import { useDispatch } from 'react-redux';

import OnboardingLayout from '../../../components/OnboardingLayout';
import { setOnboarded } from '../../../actions';
import { t } from '../../../services/i18n';

const Screen0 = ({ navigation }) => {
  const dispatch = useDispatch();

  const handleSkip = () => {
    dispatch(
      setOnboarded({
        host: true,
      }),
    );
  };

  return (
    <OnboardingLayout
      source={require('../../../assets/hostonboarding1.png')}
      text={t('text_host_onboarding_1')}
      extraText={t('text_host_onboarding_extra_1')}
      step={0}
      steps={3}
      buttonText={t('action_host_onboarding_1')}
      onNext={() => navigation.navigate('Screen1')}
      onSkip={handleSkip}
    />
  );
};

export default Screen0;
